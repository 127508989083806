export const CREATE_BILLING_SESSION_REQUEST = 'CREATE_BILLING_SESSION_REQUEST';
export const CREATE_BILLING_SESSION_SUCCESS = 'CREATE_BILLING_SESSION_SUCCESS';
export const CREATE_BILLING_SESSION_FAILURE = 'CREATE_BILLING_SESSION_FAILURE';

export const RENEW_SUBSCRIPTION_REQUEST = 'RENEW_SUBSCRIPTION_REQUEST';
export const RENEW_SUBSCRIPTION_SUCCESS = 'RENEW_SUBSCRIPTION_SUCCESS';
export const RENEW_SUBSCRIPTION_FAILURE = 'RENEW_SUBSCRIPTION_FAILURE';

export const APPLY_PROMOTION_CODE_REQUEST = 'APPLY_PROMOTION_CODE_REQUEST';
export const APPLY_PROMOTION_CODE_SUCCESS = 'APPLY_PROMOTION_CODE_SUCCESS';
export const APPLY_PROMOTION_CODE_FAILURE = 'APPLY_PROMOTION_CODE_FAILURE';
