import { LABEL_FACTEUR_SOLAIRE, LABEL_COEFFICIANT_DE_TRANSMISSION } from '../../product-constants';

export default [
    {
        property: LABEL_FACTEUR_SOLAIRE,
        label: 'Facteur solaire',
        type: 'string',
        required: true,
    },
    {
        property: LABEL_COEFFICIANT_DE_TRANSMISSION,
        label: 'Coefficient de transmission surfacique',
        type: 'string',
        required: true,
    },
];
