function formatQuotationForSaving(quotation) {
    const formattedQuotation = {
        ...quotation,
        operations: quotation.operations.map(filterNonValidProductsInOperation).filter(({ blockValidity }) => blockValidity.valid),
    };

    return formattedQuotation;
}

function filterNonValidProductsInOperation(operation) {
    return {
        ...operation,
        products: operation?.products ? operation.products.filter(({ blockValidity }) => blockValidity.valid) : [],
    };
}

export default { formatQuotationForSaving };
