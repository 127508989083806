import {
    GET_SUB_CONTRACTORS_SUCCESS,
    GET_SUB_CONTRACTORS_FAILURE,
    CREATE_SUB_CONTRACTOR_SUCCESS,
    GET_SUB_CONTRACTOR_SUCCESS,
    GET_SUB_CONTRACTOR_FAILURE,
    UPDATE_SUB_CONTRACTOR_SUCCESS,
    PATCH_SUB_CONTRACTOR_SUCCESS,
    GET_SUB_CONTRACTORS_ANALYTICS_SUCCESS,
    GET_SUB_CONTRACTOR_ANALYTICS_SUCCESS,
    GET_SUB_CONTRACTORS_ANALYTICS_FAILURE,
    GET_SUB_CONTRACTOR_ANALYTICS_FAILURE,
    RESET_SUB_CONTRACTORS_ANALYTICS,
} from '../types/sub-contractors.types';

const initState = () => ({
    subContractors: null,
    analytics: {
        subContractors: {},
        subContractor: {},
    },
});

export default (state = initState(), action) => {
    switch (action.type) {
        case GET_SUB_CONTRACTORS_SUCCESS:
            return {
                ...state,
                subContractors: action.payload,
            };
        case GET_SUB_CONTRACTORS_FAILURE:
            return {
                ...state,
                subContractors: null,
            };
        case CREATE_SUB_CONTRACTOR_SUCCESS:
            return {
                ...state,
                subContractor: action.payload,
            };
        case GET_SUB_CONTRACTOR_SUCCESS:
            return {
                ...state,
                subContractor: action.payload,
            };
        case GET_SUB_CONTRACTOR_FAILURE:
            return {
                ...state,
                subContractor: null,
            };
        case UPDATE_SUB_CONTRACTOR_SUCCESS:
            return {
                ...state,
                subContractor: action.payload,
            };
        case PATCH_SUB_CONTRACTOR_SUCCESS:
            return {
                ...state,
                subContractor: action.payload,
            };
        case GET_SUB_CONTRACTORS_ANALYTICS_SUCCESS:
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    subContractors: action.payload,
                },
            };
        case GET_SUB_CONTRACTOR_ANALYTICS_SUCCESS:
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    subContractor: action.payload,
                },
            };
        case GET_SUB_CONTRACTORS_ANALYTICS_FAILURE:
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    subContractors: {
                        numberOfOperationFinalizedBySubContractors: 0,
                        totalVolumeRealizedBySubContractors: 0,
                    },
                },
            };
        case RESET_SUB_CONTRACTORS_ANALYTICS:
        case GET_SUB_CONTRACTOR_ANALYTICS_FAILURE:
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    subContractor: {
                        numberOfOperationFinalizedBySubContractor: 0,
                        totalVolumeRealizedBySubContractor: 0,
                    },
                },
            };
        default:
            return state;
    }
};
