export const OPTIONS_NATURE_FLUID_TRANSLATION = Object.freeze({
    EAU: 'Eau',
    EAU_GLYCOLEE: 'Eau glycolée',
    AIR: 'Air',
});

export const PAYMENT_DELAY_TYPE_TRANSLATION = Object.freeze({
    AT_INVOICE_RECEPTION: 'A réception de facture',
    AT_DEADLINE: 'A échéance (précisez le nombre de jours)',
});
