export const MA_PRIME_RENOV_LABEL = 'Profil du client selon les références fiscales fournies';
export const MA_PRIME_RENOV_NO_PROFILE = 'Aucun';
export const MA_PRIME_RENOV_NO_CUSTOMER = 'Client non renseigné';

export const ELIGIBLE_OPERATION = 'Opération éligible';
export const ELIGIBLE_OPERATIONS = 'Opérations éligibles';
export const NON_ELIGIBLE_OPERATION = 'Opération non éligible';
export const NON_ELIGIBLE_OPERATIONS = 'Opérations non éligibles';

export const APPOINT_RENOLIB_AS_ADMIN_REPRESENTATIVE_EXPLANATION = "Qu'est-ce que ça veut dire ?";
export const ABOUT_THE_OFFER = 'En savoir plus sur cette offre';
export const ABOUT = 'En savoir plus';
export const FINANCIAL_AND_ADMINISTRATIVE_REPRESENTATIVE = 'Mandataire administratif et financier';
export const FINANCIAL_AND_ADMINISTRATIVE_REPRESENTATIVE_TO_COME = 'Mandataire administratif et financier (à venir)';
export const ADMINISTRATIVE_REPRESENTATIVE = 'Mandataire administratif';
export const ADMINISTRATIVE_OR_REPRESENTATIVE = 'Mandataire administratif ou mandataire administratif et financier';

export const PENDING_MANDATE = 'Le mandat de votre client est toujours en cours de traitement.';
export const REJECTED_MANDATE = 'Le mandat de votre client a été rejeté.';

export const TABLE_COLUMN_INSTALLATION_TYPE = "Type d'installation";
export const TABLE_COLUMN_QUANTITY = 'Quantité';
export const TABLE_COLUMN_UNIT = 'Unité';
export const TABLE_COLUMN_ESTIMATED_PREMIUM = 'Prime estimative';
