import { LABEL_INPUT_TAX_NOTICE_NUMBER_SHORT, LABEL_INPUT_TAX_NOTICE_REFERENCE_SHORT } from '../form_labels';

export default [
    {
        property: 'taxNoticeNumber',
        label: LABEL_INPUT_TAX_NOTICE_NUMBER_SHORT,
        type: 'string',
        required: true,
    },
    {
        property: 'taxNoticeReference',
        label: LABEL_INPUT_TAX_NOTICE_REFERENCE_SHORT,
        type: 'string',
        required: true,
    },
];
