export const FISCAL_INFORMATION_FINDER_ROUTE = '/services/fiscal-information';

export const ADDRESS_FINDER_AUTOCOMPLETE_ROUTE = '/services/address-processing/autocomplete';
export const ADDRESS_FINDER_PLACE_DETAILS_ROUTE = '/services/address-processing/place-details';

export const EXPORTS_ROUTE = '/exports';
export const GENERATE_EXPORT_ROUTE = '/generate';
export const GET_EXPORTS_AVAILABILITIES_ROUTE = '/availabilities';

export const BILLING_SESSION_ROUTE = '/billing-session';
export const SUBSCRIPTION_RENEWAL_ROUTE = '/subscription-renewal';
export const APPLY_PROMOTION_CODE_ROUTE = '/apply-promotion-code';

export const SIGN_IN_ROUTE = '/connexion';

export const GET_OPERATION_SHEETS_ROUTE = '/operation-sheets';

export const SEARCH_INVOICEABLE_DELEGATEES_ROUTE = '/search-invoiceable-delegatees';
export const SEARCH_INVOICEABLE_OPERATIONS_ROUTE = '/search-invoiceable-operations';
export const UPLOAD_BILLING_NOTE_ROUTE = '/billing-note';
export const ADD_DELEGATEE_INVOICE_OPERATIONS_ROUTE = '/add-operations';
export const REMOVE_DELEGATEE_INVOICE_OPERATIONS_ROUTE = '/remove-operations';
export const SAVE_DELEGATEE_INVOICE_AS_DRAFT_ROUTE = '/save-as-draft';
export const FINALIZE_DELEGATEE_INVOICE_ROUTE = '/finalize';
export const SEND_DELEGATEE_INVOICE_FOR_VERIFICATION_ROUTE = '/send-for-verification';

export const REFRESH_ROUTE = '/refresh';
