export default [
    {
        property: 'quantity',
        label: 'Quantité',
        type: 'number',
        required: true,
        min: 0,
    },
    {
        property: 'unit',
        label: 'Unité',
        type: 'string',
        required: true,
    },
    {
        property: 'price',
        label: 'Prix unitaire',
        type: 'number',
        required: true,
        min: 1,
    },
    {
        property: 'vatRate',
        label: 'TVA',
        type: 'number',
        required: true,
    },
];
