import { LABEL_FACTEUR_SOLAIRE, LABEL_ROOF_ONLY_CONSTITUTION_TYPE_VALUE, LABEL_TYPE_DE_CONSTITUTION } from '../../product-constants';

export default [
    {
        property: LABEL_FACTEUR_SOLAIRE,
        label: 'Facteur solaire',
        type: 'string',
        requiredIf: (formState = {}) => formState[LABEL_TYPE_DE_CONSTITUTION] === LABEL_ROOF_ONLY_CONSTITUTION_TYPE_VALUE,
    },
    {
        property: LABEL_TYPE_DE_CONSTITUTION,
        label: 'Type de constitution',
        type: 'string',
        required: true,
    },
];
