import { LABEL_INPUT_FIRST_NAME, LABEL_INPUT_GENDER, LABEL_INPUT_LAST_NAME } from '../../form_labels';

export default function ({ required = true } = {}) {
    return [
        {
            property: 'gender',
            label: LABEL_INPUT_GENDER,
            type: 'string',
            required,
        },
        {
            property: 'lastName',
            label: LABEL_INPUT_LAST_NAME,
            type: 'string',
            required,
        },
        {
            property: 'firstName',
            label: LABEL_INPUT_FIRST_NAME,
            type: 'string',
            required,
        },
    ];
}
