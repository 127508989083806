import { endpointV2, authHeaders, getAuthHeadersWithJSONContentType } from '../../api';
import storage from '../../utils/storage';

function getBaseUrl() {
    return `/organizations/${storage.getOrganizationUUID()}/waste-depot-collections`;
}

export const create = async (wasteCollectionData) => {
    return endpointV2.post(getBaseUrl(), wasteCollectionData, {
        headers: getAuthHeadersWithJSONContentType(),
    });
};

export const search = async (searchString) => {
    return endpointV2.get(`${getBaseUrl()}/search`, {
        headers: authHeaders(),
        params: { searchString },
    });
};

const wasteCollectionDepotService = () => {
    return Object.assign({}, { search, create });
};

export const wasteCollectionDepotServiceImpl = wasteCollectionDepotService();
