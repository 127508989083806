export const CREATE_INVOICE_REQUEST = 'CREATE_INVOICE_REQUEST';
export const CREATE_INVOICE_SUCCESS = 'CREATE_INVOICE_SUCCESS';
export const CREATE_INVOICE_FAILURE = 'CREATE_INVOICE_FAILURE';

export const GET_INVOICE_REQUEST = 'GET_INVOICE_REQUEST';
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';
export const GET_INVOICE_FAILURE = 'GET_INVOICE_FAILURE';

export const SAVE_INVOICE_REQUEST = 'SAVE_INVOICE_REQUEST';
export const SAVE_INVOICE_SUCCESS = 'SAVE_INVOICE_SUCCESS';
export const SAVE_INVOICE_FAILURE = 'SAVE_INVOICE_FAILURE';

export const GET_INVOICES_REQUEST = 'GET_INVOICES_REQUEST';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAILURE = 'GET_INVOICES_FAILURE';

export const DELETE_INVOICE_REQUEST = 'DELETE_INVOICE_REQUEST';
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS';
export const DELETE_INVOICE_FAILURE = 'DELETE_INVOICE_FAILURE';

export const CANCEL_INVOICE_REQUEST = 'CANCEL_INVOICE_REQUEST';
export const CANCEL_INVOICE_SUCCESS = 'CANCEL_INVOICE_SUCCESS';
export const CANCEL_INVOICE_FAILURE = 'CANCEL_INVOICE_FAILURE';

export const UPDATE_INVOICE_REQUEST = 'UPDATE_INVOICE_REQUEST';
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS';
export const UPDATE_INVOICE_FAILURE = 'UPDATE_INVOICE_FAILURE';

export const SEND_INVOICE_BY_EMAIL_REQUEST = 'SEND_INVOICE_BY_EMAIL_REQUEST';
export const SEND_INVOICE_BY_EMAIL_SUCCESS = 'SEND_INVOICE_BY_EMAIL_SUCCESS';
export const SEND_INVOICE_BY_EMAIL_FAILURE = 'SEND_INVOICE_BY_EMAIL_FAILURE';

export const UPDATE_INVOICE_OPERATION_INSTALLATION_DATE = 'UPDATE_INVOICE_OPERATION_INSTALLATION_DATE';

export const UPDATE_OPERATION_INSTALLATION_END_DATE = 'UPDATE_OPERATION_INSTALLATION_END_DATE';

export const UPDATE_OPERATION_PRODUCT_QUANTITY = 'UPDATE_OPERATION_PRODUCT_QUANTITY';

export const UPDATE_INVOICE_OPERATIONS = 'UPDATE_INVOICE_OPERATIONS';

export const UPDATE_OPERATION_OTHER_PRODUCT_QUANTITY = 'UPDATE_OPERATION_OTHER_PRODUCT_QUANTITY';

export const UPDATE_INVOICE_ISSUE_DATE_REQUEST = 'UPDATE_INVOICE_ISSUE_DATE_REQUEST';

export const UPDATE_INVOICE_OTHER_PRODUCT = 'UPDATE_INVOICE_OTHER_PRODUCT';

export const UPDATE_INVOICE_MAIN_PRODUCT = 'UPDATE_INVOICE_MAIN_PRODUCT';

export const ADD_INVOICE_OTHER_PRODUCT = 'ADD_INVOICE_OTHER_PRODUCT';

export const DELETE_INVOICE_OTHER_PRODUCT = 'DELETE_INVOICE_OTHER_PRODUCT';

export const ADD_MAIN_PRODUCT_VARIATION_INVOICE = 'ADD_MAIN_PRODUCT_VARIATION_INVOICE';
export const UPDATE_MAIN_PRODUCT_VARIATION_INVOICE = 'UPDATE_MAIN_PRODUCT_VARIATION_INVOICE';
export const DELETE_MAIN_PRODUCT_VARIATION_INVOICE = 'DELETE_MAIN_PRODUCT_VARIATION_INVOICE';

export const UPDATE_INVOICE_OPERATION = 'UPDATE_INVOICE_OPERATION';

export const GET_INVOICES_ANALYTICS_REQUEST = 'GET_INVOICES_ANALYTICS_REQUEST';
export const GET_INVOICES_ANALYTICS_SUCCESS = 'GET_INVOICES_ANALYTICS_SUCCESS';
export const GET_INVOICES_ANALYTICS_FAILURE = 'GET_INVOICES_ANALYTICS_FAILURE';

export const UPDATE_INVOICE_WASTE_MANAGEMENT = 'UPDATE_INVOICE_WASTE_MANAGEMENT';
