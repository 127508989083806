import {
    GET_DOSSIERS_CEE_REQUEST,
    GET_DOSSIERS_CEE_SUCCESS,
    GET_DOSSIER_CEE_SUCCESS,
    UPDATE_DOSSIER_CEE_SUCCESS,
    SEND_DOSSIER_CEE_SUCCESS,
    GET_DOSSIERS_CEE_ANALYTICS_SUCCESS,
    REFRESH_DOSSIER_CEE_RENOVATION_ADDRESS_SUCCESS,
    UPDATE_DOSSIER_CEE_LOCALLY,
} from '../types/dossier-cee.types';

function initState() {
    return {
        dossiersCEE: [],
        loading: true,
    };
}

export default function (state = initState(), action) {
    switch (action.type) {
        case GET_DOSSIERS_CEE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case GET_DOSSIERS_CEE_SUCCESS:
            return {
                ...state,
                dossiersCEE: action.payload.dossiersCEE,
                loading: false,
            };

        case GET_DOSSIER_CEE_SUCCESS:
            return {
                ...state,
                dossierCEE: action.payload.dossierCEE,
            };

        case UPDATE_DOSSIER_CEE_SUCCESS:
            return {
                ...state,
                dossierCEE: action.payload.dossierCEE,
            };

        case SEND_DOSSIER_CEE_SUCCESS: {
            const newState = { dossierCEE: action.payload.dossierCEE };
            if (state.dossiersCEE) {
                newState.dossiersCEE = state.dossiersCEE.map((dossierCEE) => {
                    if (dossierCEE.uuid === action.payload.dossierCEE.uuid) return action.payload.dossierCEE;

                    return dossierCEE;
                });
            }

            return newState;
        }

        case GET_DOSSIERS_CEE_ANALYTICS_SUCCESS:
            return {
                ...state,
                analytics: action.payload.analytics,
            };

        case REFRESH_DOSSIER_CEE_RENOVATION_ADDRESS_SUCCESS:
            return {
                ...state,
                dossierCEE: action.payload.dossierCEE,
            };

        case UPDATE_DOSSIER_CEE_LOCALLY:
            return {
                dossiersCEE: state.dossiersCEE.map((dossierCEE) => {
                    return dossierCEE.uuid === action.payload.dossierCEEUUID
                        ? {
                              ...dossierCEE,
                              ...action.payload.updates,
                          }
                        : dossierCEE;
                }),
            };

        default:
            return state;
    }
}
