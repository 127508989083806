export const ADD_OPERATION_NOT_POSSIBLE = "Il n'y a pas d'autres opérations disponibles pour cette catégorie de produit.";

export const NO_PRODUCTS = "Aucun produit n'a été ajouté.";

export const ROOF_CONSTITUTION_TYPE_ENERGY_EFFICIENCY_COMMENT = 'Facteur solaire avant travaux > 0,03';

export const MANDATORY_SHEET_METAL_COLOR = 'Tôle obligatoire';
export const MANDATORY_ABSORPTION_COEFFICIENT = "Coefficient d'absorption obligatoire";
export const MANDATORY_LAMBDA = 'Lambda obligatoire';
export const MANDATORY_THICKNESS = 'Épaisseur obligatoire';
export const MANDATORY_THERMAL_RESISTANCE = 'Résistance thermique obligatoire';
