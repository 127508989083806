export const ORGANIZATION_RGE_CERTIFICATE_CREATED = 'Votre certificat RGE a bien été enregistré. Il est en cours de vérification.';
export const ORGANIZATION_RGE_CERTIFICATES_CREATED = 'Vos certificats RGE ont bien été enregistrés. Ils sont en cours de vérification.';
export const ORGANIZATION_RGE_CERTIFICATE_DELETED = 'Votre certificat a bien été supprimé.';

export const SUB_CONTRACTOR_RGE_CERTIFICATE_CREATED = 'Le certificat RGE de votre sous-traitant a bien été enregistré. Il est en cours de vérification.';
export const SUB_CONTRACTOR_RGE_CERTIFICATES_CREATED = 'Les certificats RGE de votre sous-traitant ont bien été enregistrés. Ils sont en cours de vérification.';
export const SUB_CONTRACTOR_RGE_CERTIFICATE_DELETED = 'Le certificat RGE de votre sous-traitant a bien été supprimé.';

export const SEARCH_RGE_CERTIFICATE = 'Rechercher votre certificat RGE';

export const SHOULD_SELECT_AT_LEAST_ONE_DOMAIN = 'Vous devez sélectionner au moins un domaine de travaux';
