export const CREATE_CUSTOMER_PM_REQUEST = 'CREATE_CUSTOMER_PM_REQUEST';
export const CREATE_CUSTOMER_PM_SUCCESS = 'CREATE_CUSTOMER_PM_SUCCESS';
export const CREATE_CUSTOMER_PM_FAILURE = 'CREATE_CUSTOMER_PM_FAILURE';

export const GET_CUSTOMER_PM_REQUEST = 'GET_CUSTOMER_PM_REQUEST';
export const GET_CUSTOMER_PM_SUCCESS = 'GET_CUSTOMER_PM_SUCCESS';
export const GET_CUSTOMER_PM_FAILURE = 'GET_CUSTOMER_PM_FAILURE';

export const GET_CUSTOMERS_PM_REQUEST = 'GET_CUSTOMERS_PM_REQUEST';
export const GET_CUSTOMERS_PM_SUCCESS = 'GET_CUSTOMERS_PM_SUCCESS';
export const GET_CUSTOMERS_PM_FAILURE = 'GET_CUSTOMERS_PM_FAILURE';

export const PATCH_CUSTOMER_PM_REQUEST = 'PATCH_CUSTOMER_PM_REQUEST';
export const PATCH_CUSTOMER_PM_SUCCESS = 'PATCH_CUSTOMER_PM_SUCCESS';
export const PATCH_CUSTOMER_PM_FAILURE = 'PATCH_CUSTOMER_PM_FAILURE';

export const DELETE_CUSTOMER_PM_REQUEST = 'DELETE_CUSTOMER_PM_REQUEST';
export const DELETE_CUSTOMER_PM_SUCCESS = 'DELETE_CUSTOMER_PM_SUCCESS';
export const DELETE_CUSTOMER_PM_FAILURE = 'DELETE_CUSTOMER_PM_FAILURE';
