import {
    CREATE_INVOICE_SUCCESS,
    CREATE_INVOICE_FAILURE,
    GET_INVOICE_SUCCESS,
    GET_INVOICE_FAILURE,
    GET_INVOICES_REQUEST,
    GET_INVOICES_SUCCESS,
    GET_INVOICES_FAILURE,
    SAVE_INVOICE_SUCCESS,
    SAVE_INVOICE_FAILURE,
    DELETE_INVOICE_SUCCESS,
    DELETE_INVOICE_FAILURE,
    CANCEL_INVOICE_SUCCESS,
    CANCEL_INVOICE_FAILURE,
    UPDATE_INVOICE_FAILURE,
    UPDATE_INVOICE_SUCCESS,
    UPDATE_INVOICE_OPERATION_INSTALLATION_DATE,
    UPDATE_OPERATION_INSTALLATION_END_DATE,
    UPDATE_OPERATION_PRODUCT_QUANTITY,
    UPDATE_OPERATION_OTHER_PRODUCT_QUANTITY,
    UPDATE_INVOICE_ISSUE_DATE_REQUEST,
    UPDATE_INVOICE_OTHER_PRODUCT,
    UPDATE_INVOICE_MAIN_PRODUCT,
    ADD_INVOICE_OTHER_PRODUCT,
    DELETE_INVOICE_OTHER_PRODUCT,
    SEND_INVOICE_BY_EMAIL_SUCCESS,
    SEND_INVOICE_BY_EMAIL_FAILURE,
    ADD_MAIN_PRODUCT_VARIATION_INVOICE,
    UPDATE_MAIN_PRODUCT_VARIATION_INVOICE,
    DELETE_MAIN_PRODUCT_VARIATION_INVOICE,
    UPDATE_INVOICE_OPERATION,
    GET_INVOICES_ANALYTICS_SUCCESS,
    GET_INVOICES_ANALYTICS_FAILURE,
    UPDATE_INVOICE_WASTE_MANAGEMENT,
    UPDATE_INVOICE_OPERATIONS,
} from '../types/invoice.types';
import { productTypes, productCreationOriginTypes } from '../../utils/enums';
import productFormHelper from '../../utils/product-form-helper';
import { isNonEmptyObject } from '../../utils';

const initState = () => ({
    invoice: {},
    invoices: [],
    analytics: {
        invoices: {},
    },
    loading: true,
});

export default (state = initState(), action) => {
    switch (action.type) {
        case CREATE_INVOICE_SUCCESS:
            return {
                ...state,
                invoice: action.payload,
            };
        case CREATE_INVOICE_FAILURE:
            return {
                ...state,
            };
        case GET_INVOICE_SUCCESS:
            return {
                ...state,
                invoice: action.payload,
            };
        case GET_INVOICE_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case GET_INVOICES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_INVOICES_SUCCESS:
            return {
                ...state,
                invoices: action.payload,
                loading: false,
            };
        case GET_INVOICES_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        case SAVE_INVOICE_SUCCESS:
            return {
                ...state,
                invoice: action.payload,
            };
        case SAVE_INVOICE_FAILURE:
            return {
                ...state,
                error: action.error,
            };

        case DELETE_INVOICE_SUCCESS:
            return {
                ...state,
                invoices: state.invoices.filter((invoice) => invoice.uuid !== action.payload.uuid),
            };
        case DELETE_INVOICE_FAILURE:
            return {
                ...state,
                error: action.error,
            };

        case CANCEL_INVOICE_SUCCESS:
            return {
                ...state,
                invoice: action.payload,
            };
        case CANCEL_INVOICE_FAILURE:
            return {
                ...state,
                error: action.error,
            };

        case UPDATE_INVOICE_SUCCESS:
            return {
                ...state,
                invoice: action.payload,
            };
        case UPDATE_INVOICE_OPERATIONS:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    operations: action.payload.operations,
                },
            };

        case UPDATE_INVOICE_FAILURE:
            return {
                ...state,
                error: action.error,
            };

        case SEND_INVOICE_BY_EMAIL_SUCCESS:
            return {
                ...state,
                invoice: action.payload.invoice,
            };

        case SEND_INVOICE_BY_EMAIL_FAILURE:
            return {
                ...state,
                error: action.error,
            };

        case UPDATE_INVOICE_OPERATION_INSTALLATION_DATE: {
            const { additionalRelatedOperations = [] } = action.payload;

            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    invoiceIssueDate: null,
                    operations: state.invoice.operations.map((operation) => {
                        if (operation.uuid === action.payload.uuidOperation || additionalRelatedOperations.includes(operation.uuid)) {
                            return {
                                ...operation,
                                installationDate: action.payload.installationDate,
                                installationEndDate: null,
                            };
                        }

                        return operation;
                    }),
                },
            };
        }

        case UPDATE_OPERATION_INSTALLATION_END_DATE: {
            const { additionalRelatedOperations = [] } = action.payload;

            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    invoiceIssueDate: null,
                    operations: state.invoice.operations.map((operation) => {
                        if (operation.uuid === action.payload.uuidOperation || additionalRelatedOperations.includes(operation.uuid)) {
                            return {
                                ...operation,
                                installationEndDate: action.payload.installationEndDate,
                            };
                        }

                        return operation;
                    }),
                },
            };
        }

        case UPDATE_OPERATION_PRODUCT_QUANTITY: {
            const { additionalOperations = [] } = action.payload;

            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    operations: state.invoice.operations.map((operation) => {
                        if (operation.uuid === action.payload.uuidOperation) {
                            return updateMainProductQuantity(operation, action.payload);
                        } else if (isIncludedAsAdditionalOperation(operation, additionalOperations)) {
                            const additionalOperationUpdates = extractAdditionalOperationUpdates(operation, additionalOperations);

                            return updateMainProductQuantity(operation, additionalOperationUpdates);
                        }

                        return operation;
                    }),
                },
            };
        }

        case UPDATE_OPERATION_OTHER_PRODUCT_QUANTITY:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    operations: state.invoice.operations.map((operation) => {
                        if (operation.uuid === action.payload.uuidOperation) {
                            return {
                                ...operation,
                                products: operation.products.map((product) => {
                                    if (product.uuid === action.payload.uuidProduct) {
                                        const updatedProduct = {
                                            ...product,
                                            quantity: action.payload.quantity,
                                        };
                                        if (['quantity', 'price'].every((property) => Object.keys(updatedProduct).includes(property))) {
                                            updatedProduct.totalPrice = updatedProduct.quantity * updatedProduct.price;
                                        }

                                        return updatedProduct;
                                    }

                                    return product;
                                }),
                            };
                        }

                        return operation;
                    }),
                },
            };

        case UPDATE_INVOICE_ISSUE_DATE_REQUEST:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    invoiceIssueDate: action.payload.invoiceIssueDate,
                },
            };

        case UPDATE_INVOICE_OTHER_PRODUCT:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    operations: state.invoice.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                products: operation.products.map((product) => {
                                    if (product.uuid === action.payload.productUUID) {
                                        const updatedProduct = {
                                            ...product,
                                            ...action.payload.updates,
                                        };
                                        if (['quantity', 'price'].every((property) => Object.keys(updatedProduct).includes(property))) {
                                            updatedProduct.totalPrice = updatedProduct.quantity * updatedProduct.price;
                                        }
                                        return updatedProduct;
                                    }

                                    return product;
                                }),
                            };
                        }

                        return operation;
                    }),
                },
            };

        case UPDATE_INVOICE_MAIN_PRODUCT:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    operations: state.invoice.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                products: operation.products.map((product) => {
                                    if (product.product.type === productTypes.MAIN_PRODUCT) {
                                        const updatedMainProduct = {
                                            ...product,
                                            ...action.payload.updates,
                                        };
                                        if (['quantity', 'price'].every((property) => Object.keys(updatedMainProduct).includes(property))) {
                                            updatedMainProduct.totalPrice = updatedMainProduct.quantity * updatedMainProduct.price;
                                        }

                                        return updatedMainProduct;
                                    }

                                    return product;
                                }),
                            };
                        }

                        return operation;
                    }),
                },
            };

        case ADD_INVOICE_OTHER_PRODUCT:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    operations: state.invoice.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            const { products = [] } = operation;
                            products.push({ ...action.payload.product, creationOrigin: productCreationOriginTypes.INVOICE });

                            return {
                                ...operation,
                                products,
                            };
                        }

                        return operation;
                    }),
                },
            };

        case DELETE_INVOICE_OTHER_PRODUCT: {
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    operations: state.invoice.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) {
                            return {
                                ...operation,
                                products: operation.products.filter(({ uuid }) => uuid !== action.payload.productUUID),
                            };
                        }

                        return operation;
                    }),
                },
            };
        }

        case ADD_MAIN_PRODUCT_VARIATION_INVOICE:
        case UPDATE_MAIN_PRODUCT_VARIATION_INVOICE:
            return {
                ...state,
                invoice: applyMainProductVariationsChanges(state, action, { isFromInvoice: true }),
            };

        case DELETE_MAIN_PRODUCT_VARIATION_INVOICE:
            return {
                ...state,
                invoice: {
                    ...applyMainProductVariationsChanges(state, action),
                },
            };

        case UPDATE_INVOICE_OPERATION:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    operations: state.invoice.operations.map((operation) => {
                        if (operation.uuid === action.payload.operationUUID) return { ...operation, ...action.payload.updates };

                        return operation;
                    }),
                },
            };

        case GET_INVOICES_ANALYTICS_SUCCESS:
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    invoices: action.payload,
                },
            };
        case GET_INVOICES_ANALYTICS_FAILURE:
            return {
                ...state,
                analytics: initState().analytics,
            };

        case UPDATE_INVOICE_WASTE_MANAGEMENT:
            return {
                ...state,
                invoice: {
                    ...state.invoice,
                    wasteManagement: {
                        ...action.payload.wasteManagement,
                    },
                },
            };
        default:
            return state;
    }
};

function applyMainProductVariationsChanges(state, action) {
    const { type, payload } = action;
    const { operationUUID, variationUUID, variation, updates } = payload;

    return {
        ...state.invoice,
        operations: state.invoice.operations.map((operation) => {
            let variationsTotalLength = 0;
            if (operation.uuid === operationUUID) {
                const products = operation.products.map((product) => {
                    if (product.product.type === productTypes.MAIN_PRODUCT) {
                        const strategies = {
                            [ADD_MAIN_PRODUCT_VARIATION_INVOICE]: addMainProductVariation,
                            [UPDATE_MAIN_PRODUCT_VARIATION_INVOICE]: updateMainProductVariation,
                            [DELETE_MAIN_PRODUCT_VARIATION_INVOICE]: deleteMainProductVariation,
                        };
                        const selectedStrategy = strategies[type];
                        const variations = selectedStrategy ? selectedStrategy({ variations: product.declinations, variationUUID, variation, updates }) : [];
                        variationsTotalLength = variations.reduce((accumulator, { length = 0 }) => accumulator + length, 0);
                        const quantity = variationsTotalLength;
                        const totalPrice = quantity * product.price;
                        const blockValidity = productFormHelper.validateMainProduct({ ...product, quantity, totalPrice, declinations: variations });

                        return { ...product, declinations: variations, quantity, totalPrice, blockValidity };
                    }

                    return product;
                });

                return { ...operation, products, unitValue: variationsTotalLength };
            }

            return operation;
        }),
    };
}

function addMainProductVariation({ variations = [], variation } = {}) {
    return [...variations, variation];
}

function updateMainProductVariation({ variations = [], variationUUID, updates } = {}) {
    return variations.map((variation) => {
        if (variation.uuid === variationUUID) return { ...variation, ...updates };
        return variation;
    });
}

function deleteMainProductVariation({ variations = [], variationUUID }) {
    return variations.filter((variation) => variation.uuid !== variationUUID);
}

function updateMainProductQuantity(operation, { quantity, uuidProduct }) {
    return {
        ...operation,
        products: operation.products.map((product) => {
            if (product.uuid === uuidProduct) {
                return {
                    ...product,
                    totalPrice: quantity * product.price,
                    quantity,
                };
            }
            return product;
        }),
    };
}

function isIncludedAsAdditionalOperation(operation, additionalOperations) {
    return isNonEmptyObject(extractAdditionalOperationUpdates(operation, additionalOperations));
}

function extractAdditionalOperationUpdates(operation, additionalOperations) {
    return additionalOperations.find(({ uuidOperation }) => operation.uuid === uuidOperation);
}
