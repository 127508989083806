import moment from 'moment';
import { dateUnits } from '../../enums';

function getTodayAsDate() {
    return moment().toDate();
}

function formatToDate(date) {
    return moment(date).toDate();
}

function getMaxDate(dates) {
    return moment.max(dates).toDate();
}

function getMinDate(dates) {
    return moment.min(dates).toDate();
}

function isBefore(supposedDateBefore, supposedDateAfter = moment(), dateUnit = dateUnits.DAY) {
    return moment(supposedDateBefore).isBefore(supposedDateAfter, dateUnit);
}

function isAfter(supposedDateAfter, supposedDateBefore = moment(), dateUnit = dateUnits.DAY) {
    return moment(supposedDateAfter).isAfter(supposedDateBefore, dateUnit);
}

function formatDate({ date = moment(), format } = {}) {
    return format ? moment(date).format(format) : moment(date).format();
}

function add({ date = moment(), value = 0, dateUnit = dateUnits.DAY } = {}) {
    return moment(date).add(value, dateUnit).toDate();
}

function substract({ date = moment(), value = 0, dateUnit = dateUnits.DAY } = {}) {
    return moment(date).subtract(value, dateUnit).toDate();
}

function isSameOrBefore(supposedDateBefore, supposedDateAfter = moment(), dateUnit = dateUnits.DAY) {
    return moment(supposedDateBefore).isSameOrBefore(supposedDateAfter, dateUnit);
}

function isSameOrAfter(supposedDateAfter, supposedDateBefore, dateUnit = dateUnits.DAY) {
    return moment(supposedDateAfter).isSameOrAfter(supposedDateBefore, dateUnit);
}

function isSameOrBetween(supposedDateBetween, startDate = moment(), endDate = moment()) {
    return isSameOrBefore(startDate, supposedDateBetween) && isSameOrAfter(endDate, supposedDateBetween);
}

function now() {
    return moment().toDate();
}

export default { isBefore, isAfter, formatDate, isSameOrBetween, isSameOrAfter, add, substract, getTodayAsDate, formatToDate, getMaxDate, getMinDate, now, isSameOrBefore };
