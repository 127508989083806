import { LABEL_INPUT_PERFORMANCE_FACTOR } from '../../form_labels';
import { LABEL_COEFFICIANT_DE_PERFORMANCE, LABEL_RACKING_PROFILE } from '../../product-constants';

export default [
    {
        property: LABEL_COEFFICIANT_DE_PERFORMANCE,
        label: LABEL_INPUT_PERFORMANCE_FACTOR,
        type: 'number',
        required: true,
    },
    {
        property: LABEL_RACKING_PROFILE,
        label: LABEL_RACKING_PROFILE,
        type: 'string',
        required: true,
    },
];
