export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';

export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE';

export const GET_PRODUCT_QUANTITY_USED_IN_INVOICES_REQUEST = 'GET_PRODUCT_QUANTITY_USED_IN_INVOICES_REQUEST';
export const GET_PRODUCT_QUANTITY_USED_IN_INVOICES_SUCCESS = 'GET_PRODUCT_QUANTITY_USED_IN_INVOICES_SUCCESS';
export const GET_PRODUCT_QUANTITY_USED_IN_INVOICES_FAILURE = 'GET_PRODUCT_QUANTITY_USED_IN_INVOICES_FAILURE';

export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE';

export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE';

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE';

export const GET_PRODUCT_ELIGIBILITY_REQUEST = 'GET_PRODUCT_ELIGIBILITY_REQUEST';
export const GET_PRODUCT_ELIGIBILITY_SUCCESS = 'GET_PRODUCT_ELIGIBILITY_SUCCESS';
export const GET_PRODUCT_ELIGIBILITY_FAILURE = 'GET_PRODUCT_ELIGIBILITY_FAILURE';

export const GET_NUMBER_OF_OPERATIONS_BY_PRODUCT_IN_INVOICES_REQUEST = 'GET_NUMBER_OF_OPERATIONS_BY_PRODUCT_IN_INVOICES_REQUEST';
export const GET_NUMBER_OF_OPERATIONS_BY_PRODUCT_IN_INVOICES_SUCCESS = 'GET_NUMBER_OF_OPERATIONS_BY_PRODUCT_IN_INVOICES_SUCCESS';
export const GET_NUMBER_OF_OPERATIONS_BY_PRODUCT_IN_INVOICES_FAILURE = 'GET_NUMBER_OF_OPERATIONS_BY_PRODUCT_IN_INVOICES_FAILURE';
