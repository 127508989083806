import axios from 'axios';

const isEmailDisposable = async (email) => {
    if (!window.Cypress && (process.env.REACT_APP_NODE_ENV === 'development' || process.env.REACT_APP_NODE_ENV === 'staging')) return false;
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_MAIL_VERIFICATION}/${email}`);
        return data.disposable;
    } catch (e) {
        return false;
    }
};

export { isEmailDisposable };
