import { LABEL_INPUT_INSURANCE_NAME, LABEL_INPUT_INSURANCE_REFERENCE, LABEL_INPUT_INSURANCE_START_DATE, LABEL_INPUT_INSURANCE_WARRANTY_AREA } from '../../form_labels';
import { addressFieldsDescriptorGenerator } from '../common_descriptor_generator';

export default [
    ...addressFieldsDescriptorGenerator({ includeCadastralParcelNumber: false }),
    {
        property: 'name',
        label: LABEL_INPUT_INSURANCE_NAME,
        type: 'string',
        required: true,
    },
    {
        property: 'reference',
        label: LABEL_INPUT_INSURANCE_REFERENCE,
        type: 'string',
        required: true,
    },
    {
        property: 'warrantyArea',
        label: LABEL_INPUT_INSURANCE_WARRANTY_AREA,
        type: 'string',
        required: true,
    },
    {
        property: 'startDate',
        label: LABEL_INPUT_INSURANCE_START_DATE,
        type: 'string',
        required: true,
    },
];
