import { endpointV2, authHeaders } from '../../api';
import storage from '../../utils/storage';

const getBaseUrl = () => {
    return `/organizations/${storage.getOrganizationUUID()}/invoices`;
};

const createInvoice = (datas) => endpointV2.post(getBaseUrl(), datas, { headers: authHeaders() });

const getInvoice = (uuidInvoice) => endpointV2.get(`${getBaseUrl()}/${uuidInvoice}`, { headers: authHeaders() });

const saveInvoice = (uuidInvoice, updates) => endpointV2.put(`${getBaseUrl()}/${uuidInvoice}/save`, updates, { headers: authHeaders() });

const getInvoices = (query) => endpointV2.get(getBaseUrl(), { headers: authHeaders(), params: query });

const getInvoicesCount = (query) => endpointV2.get(`${getBaseUrl()}/count`, { headers: authHeaders(), params: query });

const deleteInvoice = (uuidInvoice) => endpointV2.delete(`${getBaseUrl()}/${uuidInvoice}/delete`, { headers: authHeaders() });

const cancelInvoice = (uuidInvoice) => endpointV2.delete(`${getBaseUrl()}/${uuidInvoice}/cancel`, { headers: authHeaders() });

const updateInvoice = (uuidInvoice, updates) => endpointV2.put(`${getBaseUrl()}/${uuidInvoice}/pay-invoice`, updates, { headers: authHeaders() });

const sendInvoiceByEmail = (invoiceUUID, datas) => endpointV2.put(`${getBaseUrl()}/${invoiceUUID}/send`, datas, { headers: authHeaders() });

const getInvoicesAnalytics = () => endpointV2.get(`/organizations/${storage.getOrganizationUUID()}/analytical-data/invoices`, { headers: authHeaders() });

export default { createInvoice, getInvoice, saveInvoice, getInvoices, deleteInvoice, cancelInvoice, updateInvoice, sendInvoiceByEmail, getInvoicesAnalytics, getInvoicesCount };
