import { HYBRID_SOLAR_CAPTORS, LABEL_NATURE_FLUID_CAPTORS, SYSTEM_LINKED_WITH_CENTRAL_HEATING_TRANSMITTER } from '../../product-constants';
import { LABEL_INPUT_SOLAR_CAPTORS_PRODUCTIVITY } from '../../form_labels';

export default [
    {
        property: HYBRID_SOLAR_CAPTORS,
        label: HYBRID_SOLAR_CAPTORS,
        type: 'radio',
        required: true,
    },
    {
        property: SYSTEM_LINKED_WITH_CENTRAL_HEATING_TRANSMITTER,
        label: SYSTEM_LINKED_WITH_CENTRAL_HEATING_TRANSMITTER,
        type: 'radio',
        required: true,
    },
    {
        property: LABEL_INPUT_SOLAR_CAPTORS_PRODUCTIVITY,
        label: LABEL_INPUT_SOLAR_CAPTORS_PRODUCTIVITY,
        type: 'number',
        required: true,
    },
    {
        property: LABEL_NATURE_FLUID_CAPTORS,
        label: LABEL_NATURE_FLUID_CAPTORS,
        type: 'select',
        required: true,
    },
];
