import { GET_EXPORTS_AVAILABILITIES_SUCCESS } from '../types/exports.types';

function initState() {
    return {
        availabilities: {},
    };
}

export default function (state = initState(), action) {
    const { type, payload } = action;

    switch (type) {
        case GET_EXPORTS_AVAILABILITIES_SUCCESS:
            return {
                ...state,
                availabilities: {
                    ...state.availabilities,
                    [payload.exportDataType]: payload.availabilities,
                },
            };

        default:
            return state;
    }
}
