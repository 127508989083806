export default function ({ required = false } = {}) {
    return [
        {
            property: 'taxNoticeNumber',
            type: 'string',
            required,
        },
        {
            property: 'taxNoticeReference',
            type: 'string',
            required,
        },
        {
            property: 'numberOfDependents',
            type: 'number',
            required,
        },
        {
            property: 'referenceTaxIncome',
            type: 'number',
            required,
        },
    ];
}
