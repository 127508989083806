export const CREATE_RENOVATION_ADDRESS_REQUEST = 'CREATE_RENOVATION_ADDRESS_REQUEST';
export const CREATE_RENOVATION_ADDRESS_SUCCESS = 'CREATE_RENOVATION_ADDRESS_SUCCESS';
export const CREATE_RENOVATION_ADDRESS_FAILURE = 'CREATE_RENOVATION_ADDRESS_FAILURE';

export const UPDATE_RENOVATION_ADDRESS_REQUEST = 'UPDATE_RENOVATION_ADDRESS_REQUEST';
export const UPDATE_RENOVATION_ADDRESS_SUCCESS = 'UPDATE_RENOVATION_ADDRESS_SUCCESS';
export const UPDATE_RENOVATION_ADDRESS_FAILURE = 'UPDATE_RENOVATION_ADDRESS_FAILURE';

export const DELETE_RENOVATION_ADDRESS_REQUEST = 'DELETE_RENOVATION_ADDRESS_REQUEST';
export const DELETE_RENOVATION_ADDRESS_SUCCESS = 'DELETE_RENOVATION_ADDRESS_SUCCESS';
export const DELETE_RENOVATION_ADDRESS_FAILURE = 'DELETE_RENOVATION_ADDRESS_FAILURE';

export const PATCH_RENOVATION_ADDRESS_REQUEST = 'PATCH_RENOVATION_ADDRESS_REQUEST';
export const PATCH_RENOVATION_ADDRESS_SUCCESS = 'PATCH_RENOVATION_ADDRESS_SUCCESS';
export const PATCH_RENOVATION_ADDRESS_FAILURE = 'PATCH_RENOVATION_ADDRESS_FAILURE';

export const GET_QPVS_REQUEST = 'GET_QPVS_REQUEST';
export const GET_QPVS_SUCCESS = 'GET_QPVS_SUCCESS';
export const GET_QPVS_FAILURE = 'GET_QPVS_FAILURE';

export const GET_CUSTOMER_RENOVATION_ADDRESSES_REQUEST = 'GET_CUSTOMER_RENOVATION_ADDRESSES_REQUEST';
export const GET_CUSTOMER_RENOVATION_ADDRESSES_SUCCESS = 'GET_CUSTOMER_RENOVATION_ADDRESSES_SUCCESS';
export const GET_CUSTOMER_RENOVATION_ADDRESSES_FAILURE = 'GET_CUSTOMER_RENOVATION_ADDRESSES_FAILURE';

export const CLEAR_CUSTOMER_RENOVATION_ADDRESSES = 'CLEAR_CUSTOMER_RENOVATION_ADDRESSES';
