import invoiceActions from '../actions/invoice.actions';
import {
    UPDATE_INVOICE_ISSUE_DATE_REQUEST,
    UPDATE_INVOICE_OPERATION_INSTALLATION_DATE,
    UPDATE_OPERATION_INSTALLATION_END_DATE,
    UPDATE_OPERATION_PRODUCT_QUANTITY,
    UPDATE_INVOICE_OTHER_PRODUCT,
    ADD_INVOICE_OTHER_PRODUCT,
    DELETE_INVOICE_OTHER_PRODUCT,
    ADD_MAIN_PRODUCT_VARIATION_INVOICE,
    UPDATE_MAIN_PRODUCT_VARIATION_INVOICE,
    DELETE_MAIN_PRODUCT_VARIATION_INVOICE,
    UPDATE_INVOICE_OPERATION,
    UPDATE_INVOICE_WASTE_MANAGEMENT,
    UPDATE_INVOICE_OPERATIONS,
} from '../types/invoice.types';
import middlewareHelper from './middleware-helper';

export default ({ dispatch, getState }) => (next) => (action) => {
    const nextAction = next(action);
    if (canTriggerSaveInvoice(action)) {
        const { invoice } = getState().invoicesState;
        if (canSaveInvoice(invoice)) {
            const { invoiceIssueDate, operations, wasteManagement } = invoice;
            const updates = { invoiceIssueDate, operations, generateInvoice: false, wasteManagement };
            dispatch(invoiceActions.saveInvoice({ uuidInvoice: invoice.uuid, updates }));
        }
    }

    return nextAction;
};

function canTriggerSaveInvoice(action) {
    return [
        UPDATE_INVOICE_ISSUE_DATE_REQUEST,
        UPDATE_INVOICE_OPERATION_INSTALLATION_DATE,
        UPDATE_OPERATION_INSTALLATION_END_DATE,
        UPDATE_OPERATION_PRODUCT_QUANTITY,
        ADD_INVOICE_OTHER_PRODUCT,
        UPDATE_INVOICE_OTHER_PRODUCT,
        DELETE_INVOICE_OTHER_PRODUCT,
        ADD_MAIN_PRODUCT_VARIATION_INVOICE,
        UPDATE_MAIN_PRODUCT_VARIATION_INVOICE,
        UPDATE_INVOICE_WASTE_MANAGEMENT,
        DELETE_MAIN_PRODUCT_VARIATION_INVOICE,
        UPDATE_INVOICE_OPERATION,
        UPDATE_INVOICE_OPERATIONS,
    ].includes(action.type);
}

function canSaveInvoice({ operations }) {
    return middlewareHelper.areAllOperationsAndProductsValid({ operations });
}
