import {
    LABEL_TYPE_FONCTIONNEMENT,
    LABEL_GREEN_FLAME_LABEL_SEVEN,
    LABEL_SEASONAL_PARTICLE_EMISSIONS,
    LABEL_SEASONAL_CARBON_MONOXIDE_EMISSIONS,
    LABEL_SEASONAL_NITROGEN_OXIDE_EMISSIONS,
    LABEL_SEASONAL_GASEOUS_ORGANIC_COMPOUNDS_EMISSIONS,
    LABEL_SEASONAL_ENERGY_EFFICIENCY_2015_1189,
    LABEL_CLASSE_REGULATEUR,
    LABEL_OLD_OR_NEW_SILO_OF_AT_LEAST_225_LITERS_PRESENCE,
    LABEL_OLD_OR_NEW_BUFFER_TANK_PRESENCE,
    LABEL_PUISSANCE_THERMIQUE,
    LABEL_USED_BIOMASS_IS_LIGNEOUS_BIOMASS_WITH_WOOD_BASE,
} from '../../product-constants';
import { functioningAlimentationTypes } from '../../enums';

export default [
    {
        property: LABEL_TYPE_FONCTIONNEMENT,
        label: LABEL_TYPE_FONCTIONNEMENT,
        type: 'string',
        required: true,
    },
    {
        property: LABEL_GREEN_FLAME_LABEL_SEVEN,
        label: LABEL_GREEN_FLAME_LABEL_SEVEN,
        type: 'checkbox',
        required: true,
    },
    {
        property: LABEL_PUISSANCE_THERMIQUE,
        label: 'Puissance thermique nominale',
        type: 'string',
        required: true,
    },
    {
        property: LABEL_CLASSE_REGULATEUR,
        label: 'Classe du régulateur telle que définie au paragraphe 6.1 de la communication de la Commission 2014/C 207/02 dans le cadre du règlement (UE) n°813/2013.',
        type: 'string',
        required: true,
    },
    {
        property: LABEL_USED_BIOMASS_IS_LIGNEOUS_BIOMASS_WITH_WOOD_BASE,
        label: LABEL_USED_BIOMASS_IS_LIGNEOUS_BIOMASS_WITH_WOOD_BASE,
        type: 'checkbox',
        required: true,
    },
    {
        property: LABEL_OLD_OR_NEW_SILO_OF_AT_LEAST_225_LITERS_PRESENCE,
        label: LABEL_OLD_OR_NEW_SILO_OF_AT_LEAST_225_LITERS_PRESENCE,
        type: 'radio',
        requiredIf: (formState) => formState[LABEL_TYPE_FONCTIONNEMENT] && formState[LABEL_TYPE_FONCTIONNEMENT] === functioningAlimentationTypes.AUTOMATIC_ALIMENTATION_PELLET_BOILER,
    },
    {
        property: LABEL_OLD_OR_NEW_BUFFER_TANK_PRESENCE,
        label: LABEL_OLD_OR_NEW_BUFFER_TANK_PRESENCE,
        type: 'radio',
        requiredIf: (formState) => formState[LABEL_TYPE_FONCTIONNEMENT] && formState[LABEL_TYPE_FONCTIONNEMENT] === functioningAlimentationTypes.MANUEL_ALIMENTATION_FUEL_BOILER,
    },
    {
        property: LABEL_SEASONAL_ENERGY_EFFICIENCY_2015_1189,
        label: LABEL_SEASONAL_ENERGY_EFFICIENCY_2015_1189,
        type: 'string',
        required: true,
    },
    {
        property: LABEL_SEASONAL_PARTICLE_EMISSIONS,
        label: LABEL_SEASONAL_PARTICLE_EMISSIONS,
        type: 'string',
        requiredIf: (formState) => {
            return formState[LABEL_GREEN_FLAME_LABEL_SEVEN] !== undefined && formState[LABEL_GREEN_FLAME_LABEL_SEVEN] === false;
        },
    },
    {
        property: LABEL_SEASONAL_CARBON_MONOXIDE_EMISSIONS,
        label: LABEL_SEASONAL_CARBON_MONOXIDE_EMISSIONS,
        type: 'string',
        requiredIf: (formState) => {
            return formState[LABEL_GREEN_FLAME_LABEL_SEVEN] !== undefined && formState[LABEL_GREEN_FLAME_LABEL_SEVEN] === false;
        },
    },
    {
        property: LABEL_SEASONAL_NITROGEN_OXIDE_EMISSIONS,
        label: LABEL_SEASONAL_NITROGEN_OXIDE_EMISSIONS,
        type: 'string',
        requiredIf: (formState) => {
            return formState[LABEL_GREEN_FLAME_LABEL_SEVEN] !== undefined && formState[LABEL_GREEN_FLAME_LABEL_SEVEN] === false;
        },
    },
    {
        property: LABEL_SEASONAL_GASEOUS_ORGANIC_COMPOUNDS_EMISSIONS,
        label: LABEL_SEASONAL_GASEOUS_ORGANIC_COMPOUNDS_EMISSIONS,
        type: 'string',
        requiredIf: (formState) => {
            return formState[LABEL_GREEN_FLAME_LABEL_SEVEN] !== undefined && formState[LABEL_GREEN_FLAME_LABEL_SEVEN] === false;
        },
    },
];
