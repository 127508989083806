import {
    CLEANUP,
    CLEANUP_CUSTOMERS,
    CLEANUP_TECHNICIANS,
    CLEANUP_TITLES,
    CREATE_APPOINTMENT_FAILURE,
    CREATE_APPOINTMENT_REQUEST,
    CREATE_CATEGORY_FAILURE,
    CREATE_CATEGORY_REQUEST,
    CREATE_CATEGORY_SUCCESS,
    DELETE_APPOINTMENT_FAILURE,
    DELETE_APPOINTMENT_FILE_SUCCESS,
    DELETE_APPOINTMENT_REQUEST,
    DELETE_CATEGORY_FAILURE,
    DELETE_CATEGORY_REQUEST,
    DELETE_CATEGORY_SUCCESS,
    DELETE_OTHER_PERSON_IN_APPOINTMENT_SUCCESS,
    GET_APPOINTMENTS_BY_DATE_FAILURE,
    GET_APPOINTMENTS_BY_DATE_REQUEST,
    GET_APPOINTMENTS_BY_DATE_SUCCESS,
    GET_APPOINTMENTS_FAILURE,
    GET_APPOINTMENTS_REQUEST,
    GET_APPOINTMENTS_SUCCESS,
    GET_CATEGORIES_FAILURE,
    GET_CATEGORIES_REQUEST,
    GET_CATEGORIES_SUCCESS,
    GET_CUSTOMER_FOR_APPOINTMENT_FAILURE,
    GET_CUSTOMER_FOR_APPOINTMENT_REQUEST,
    GET_CUSTOMER_FOR_APPOINTMENT_SUCCESS,
    GET_TECHNICIAN_FOR_APPOINTMENT_FAILURE,
    GET_TECHNICIAN_FOR_APPOINTMENT_REQUEST,
    GET_TECHNICIAN_FOR_APPOINTMENT_SUCCESS,
    RESET_FILTER,
    SEARCH_CUSTOMERS_FOR_APPOINTMENT_FAILURE,
    SEARCH_CUSTOMERS_FOR_APPOINTMENT_REQUEST,
    SEARCH_CUSTOMERS_FOR_APPOINTMENT_SUCCESS,
    SEARCH_TECHNICIANS_FOR_APPOINTMENT_FAILURE,
    SEARCH_TECHNICIANS_FOR_APPOINTMENT_REQUEST,
    SEARCH_TECHNICIANS_FOR_APPOINTMENT_SUCCESS,
    SEARCH_TITLES_FAILURE,
    SEARCH_TITLES_REQUEST,
    SEARCH_TITLES_SUCCESS,
    SET_APPOINTMENT_MODAL,
    SET_APPOINTMENTS_FILTER,
    SET_CALENDAR_PERIOD,
    SET_CURRENT_PAGE,
    SET_EDITABLE_CATEGORY,
    UPDATE_APPOINTMENT_FAILURE,
    UPDATE_APPOINTMENT_REQUEST,
    UPDATE_APPOINTMENT_SUCCESS,
    UPDATE_CATEGORY_FAILURE,
    UPDATE_CATEGORY_REQUEST,
    UPDATE_CATEGORY_SUCCESS,
} from '../types/planning.types';
import { getStartAndEndDateForCalendar } from '../../utils/planning-helpers';

const initialState = () => ({
    appointments: null,
    appointmentsForCalendar: [],
    appointmentsFilter: {
        categoryIds: [],
        statuses: [],
    },
    calendarTimePeriod: getStartAndEndDateForCalendar(new Date()),
    categories: null,
    titles: [],
    customers: [],
    technicians: [],
    selectedCustomer: null,
    selectedTechnician: null,
    totalAppointments: 0,
    currentPage: 1,
    perPage: 15,
    appointmentModal: null,
    editableCategory: null,
    isLoading: false,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        case GET_CATEGORIES_SUCCESS:
        case UPDATE_CATEGORY_SUCCESS:
        case CREATE_CATEGORY_SUCCESS:
        case DELETE_CATEGORY_SUCCESS:
            return {
                ...state,
                categories: action.payload,
                isLoading: false,
            };
        case GET_APPOINTMENTS_BY_DATE_REQUEST:
        case GET_APPOINTMENTS_REQUEST:
        case GET_CATEGORIES_REQUEST:
        case CREATE_CATEGORY_REQUEST:
        case UPDATE_CATEGORY_REQUEST:
        case DELETE_CATEGORY_REQUEST:
        case DELETE_APPOINTMENT_REQUEST:
        case CREATE_APPOINTMENT_REQUEST:
        case UPDATE_APPOINTMENT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case GET_APPOINTMENTS_SUCCESS:
        case UPDATE_APPOINTMENT_SUCCESS:
            return {
                ...state,
                appointments: action.payload.appointments,
                totalAppointments: action.payload.totalCount,
                isLoading: false,
            };
        case GET_APPOINTMENTS_BY_DATE_SUCCESS:
            return {
                ...state,
                appointmentsForCalendar: action.payload,
                isLoading: false,
            };
        case GET_APPOINTMENTS_FAILURE:
        case GET_APPOINTMENTS_BY_DATE_FAILURE:
        case GET_CATEGORIES_FAILURE:
        case CREATE_CATEGORY_FAILURE:
        case UPDATE_CATEGORY_FAILURE:
        case DELETE_CATEGORY_FAILURE:
        case DELETE_APPOINTMENT_FAILURE:
        case CREATE_APPOINTMENT_FAILURE:
        case UPDATE_APPOINTMENT_FAILURE:
        case SEARCH_CUSTOMERS_FOR_APPOINTMENT_FAILURE:
        case SEARCH_TITLES_FAILURE:
        case GET_CUSTOMER_FOR_APPOINTMENT_FAILURE:
        case SEARCH_TECHNICIANS_FOR_APPOINTMENT_FAILURE:
        case GET_TECHNICIAN_FOR_APPOINTMENT_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case SET_APPOINTMENTS_FILTER:
            return {
                ...state,
                appointmentsFilter: action.payload,
                currentPage: 1,
            };
        case SET_APPOINTMENT_MODAL:
            return {
                ...state,
                appointmentModal: action.payload,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload,
            };
        case DELETE_APPOINTMENT_FILE_SUCCESS:
            return {
                ...state,
                appointmentModal: {
                    ...state.appointmentModal,
                    appointment: {
                        ...state.appointmentModal.appointment,
                        uploadFiles: state.appointmentModal.appointment.uploadFiles.filter((file) => file._id !== action.payload),
                    },
                },
            };
        case DELETE_OTHER_PERSON_IN_APPOINTMENT_SUCCESS:
            return {
                ...state,
                appointmentModal: {
                    ...state.appointmentModal,
                    appointment: {
                        ...state.appointmentModal.appointment,
                        notifyOtherPersons: state.appointmentModal.appointment.notifyOtherPersons.filter((person) => person.uuid !== action.payload),
                    },
                },
            };
        case SET_EDITABLE_CATEGORY:
            return {
                ...state,
                editableCategory: action.payload,
            };
        case RESET_FILTER:
            return {
                ...state,
                appointmentsFilter: initialState().appointmentsFilter,
                currentPage: 1,
            };
        case SET_CALENDAR_PERIOD:
            return {
                ...state,
                calendarTimePeriod: action.payload,
            };

        case SEARCH_CUSTOMERS_FOR_APPOINTMENT_REQUEST:
            return {
                ...state,
                customers: [],
                isLoading: true,
            };
        case SEARCH_CUSTOMERS_FOR_APPOINTMENT_SUCCESS:
            return {
                ...state,
                customers: action.payload,
                isLoading: false,
            };
        case SEARCH_TITLES_REQUEST:
            return {
                ...state,
                titles: [],
                isLoading: true,
            };
        case SEARCH_TITLES_SUCCESS:
            return {
                ...state,
                titles: action.payload,
                isLoading: false,
            };
        case GET_CUSTOMER_FOR_APPOINTMENT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case GET_CUSTOMER_FOR_APPOINTMENT_SUCCESS:
            return {
                ...state,
                selectedCustomer: action.payload,
                isLoading: false,
            };
        case CLEANUP_CUSTOMERS:
            return {
                ...state,
                customers: [],
                selectedCustomer: null,
            };
        case CLEANUP_TITLES:
            return {
                ...state,
                titles: [],
            };
        case SEARCH_TECHNICIANS_FOR_APPOINTMENT_REQUEST:
            return {
                ...state,
                technicians: [],
                isLoading: true,
            };
        case SEARCH_TECHNICIANS_FOR_APPOINTMENT_SUCCESS:
            return {
                ...state,
                technicians: action.payload,
                isLoading: false,
            };
        case GET_TECHNICIAN_FOR_APPOINTMENT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case GET_TECHNICIAN_FOR_APPOINTMENT_SUCCESS:
            return {
                ...state,
                selectedTechnician: action.payload,
                isLoading: false,
            };
        case CLEANUP_TECHNICIANS:
            return {
                ...state,
                technicians: [],
                selectedTechnician: null,
            };

        case CLEANUP:
            return initialState();
        default:
            return state;
    }
};
