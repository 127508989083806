import { LABEL_INPUT_END_DATE_RGE_CERTIFICATE, LABEL_INPUT_REFERENCE_RGE_CERTIFICATE, LABEL_INPUT_START_DATE_RGE_CERTIFICATE } from '../form_labels';
import { SHOULD_SELECT_AT_LEAST_ONE_DOMAIN } from '../messages';

export default [
    {
        property: 'remoteName',
        label: 'Nom du fichier',
        type: 'string',
    },
    {
        property: 'reference',
        label: LABEL_INPUT_REFERENCE_RGE_CERTIFICATE,
        type: 'string',
        required: true,
    },
    {
        property: 'startDate',
        label: LABEL_INPUT_START_DATE_RGE_CERTIFICATE,
        type: 'date',
        required: true,
    },
    {
        property: 'endDate',
        label: LABEL_INPUT_END_DATE_RGE_CERTIFICATE,
        type: 'date',
        required: true,
    },
    {
        property: 'domains',
        label: 'Domaines',
        type: 'string',
        required: true,
        minItems: 1,
        errorMessages: {
            required: SHOULD_SELECT_AT_LEAST_ONE_DOMAIN,
            minItems: SHOULD_SELECT_AT_LEAST_ONE_DOMAIN,
        },
    },
];
