import { endpointV2, authHeaders } from '../../api';

const autocomplete = async (query) =>
    endpointV2.get('/services/remote-organization-information/autocomplete', {
        headers: authHeaders(),
        params: {
            query,
        },
    });

const getOrganizationInformation = async (companyId) =>
    endpointV2.get('/services/remote-organization-information/organization-information', {
        headers: authHeaders(),
        params: {
            companyId,
        },
    });

export default {
    autocomplete,
    getOrganizationInformation,
};
