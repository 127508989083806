import productProperties from './product-properties';

export default [
    {
        property: 'installationType',
        label: "Type d'installation",
        type: 'string',
        required: true,
    },
    ...productProperties,
];
