import {
    LABEL_MODELE_VENTILATION,
    LABEL_TYPE_VENTILATION,
    LABEL_CLASSE_ENERGETIQUE_VENTILATION,
    LABEL_REFERENCE_AVIS_TECHNIQUE,
    LABEL_DATE_VALIDITE_AVIS_TECHNIQUE,
    LABEL_PUISSANCE_ELECTRIQUE,
    LABEL_TYPE_CAISSON,
    LABEL_MARQUE_CAISSON,
    LABEL_REFERENCE_CAISSON,
    LABEL_MARQUE_BOUCHES_EXTRACTION,
    LABEL_REFERENCE_BOUCHES_EXTRACTION,
} from '../../product-constants';

export default [
    {
        property: LABEL_MODELE_VENTILATION,
        label: 'Modèle de ventilation',
        type: 'string',
        required: true,
    },
    {
        property: LABEL_TYPE_VENTILATION,
        label: 'Type',
        type: 'string',
        required: true,
    },
    {
        property: LABEL_CLASSE_ENERGETIQUE_VENTILATION,
        label: 'Classe énergétique',
        type: 'string',
        required: true,
    },
    {
        property: LABEL_REFERENCE_AVIS_TECHNIQUE,
        label: "Référence de l'avis technique",
        type: 'string',
        required: true,
    },
    {
        property: LABEL_DATE_VALIDITE_AVIS_TECHNIQUE,
        label: "Date de validité de l'avis technique",
        type: 'string',
        required: true,
    },
    {
        property: LABEL_PUISSANCE_ELECTRIQUE,
        label: 'Puissance électrique',
        type: 'number',
        required: true,
    },
    {
        property: LABEL_TYPE_CAISSON,
        label: 'Type de caisson',
        type: 'string',
        required: true,
    },
    {
        property: LABEL_MARQUE_CAISSON,
        label: 'Marque du caisson',
        type: 'string',
        required: true,
    },
    {
        property: LABEL_REFERENCE_CAISSON,
        label: 'Référence du caisson',
        type: 'string',
        required: true,
    },
    {
        property: LABEL_MARQUE_BOUCHES_EXTRACTION,
        label: "Marque des bouches d'extraction",
        type: 'string',
        required: true,
    },
    {
        property: LABEL_REFERENCE_BOUCHES_EXTRACTION,
        label: "Référence des bouches d'extraction",
        type: 'string',
        required: true,
    },
];
