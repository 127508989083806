export const LABEL_INPUT_ADDRESS_NAME = 'Nom du bâtiment';
export const LABEL_INPUT_ADDRESS_NAME_OPTIONAL = 'Nom du bâtiment (optionnel)';
export const LABEL_CHECKBOX_RENOVATION_ADDRESS_IS_HOME_ADDRESS = "L'adresse des travaux est l'adresse du domicile";
export const LABEL_INPUT_ADDRESS_PHONE = 'Téléphone';
export const LABEL_INPUT_ADDRESS_PHONE_OPTIONAL = 'Téléphone (optionnel)';
export const LABEL_SELECT_HOUSING_TYPE = 'Type de logement';
export const LABEL_SELECT_BUILDING_SECTOR = 'Secteur du bâtiment';
export const LABEL_SELECT_ACTIVITY_AREA = "Secteur d'activité";

export const MODAL_TITLE_CREATE_RENOVATION_ADDRESS = 'Ajouter une adresse de travaux';
export const MODAL_TITLE_UPDATE_RENOVATION_ADDRESS = 'Modifier une adresse de travaux';

export const LABEL_BUTTON_ADD_RENOVATION_ADDRESS = 'Ajouter une adresse de travaux';
export const LABEL_BUTTON_EDIT_RENOVATION_ADDRESS = 'Modifier une adresse de travaux';
export const LABEL_BUTTON_DELETE_RENOVATION_ADDRESS = 'Supprimer une adresse de travaux';
