import productHelper from '../../utils/product-helper';

function areAllOperationsAndProductsValid({ operations }) {
    return areAllOperationsValid(operations) && areAllOperationsMainProductsValid(operations) && areAllOperationsOtherProductsValid(operations);
}

function areAllOperationsValid(operations) {
    return operations.every((operation) => operation.blockValidity.valid);
}

function areAllOperationsMainProductsValid(operations) {
    return operations.every(({ products = [] }) => {
        const productsValid = productHelper.filterMainProducts(products).reduce((accumulator, { blockValidity }) => {
            return accumulator && blockValidity.valid;
        }, true);

        return productsValid;
    });
}

function areAllOperationsOtherProductsValid(operations) {
    return operations.every(({ products = [] }) => {
        const productsValid = productHelper.filterOtherProducts(products).reduce((accumulator, { blockValidity }) => {
            return accumulator && blockValidity.valid;
        }, true);

        return productsValid;
    });
}

export default { areAllOperationsAndProductsValid };
