import { GET_PROGRESS_BAR_VISIBILITY } from '../../types/ui/onboarding';

const initialState = {
    visible: false,
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_PROGRESS_BAR_VISIBILITY:
            return {
                visible: payload.visible,
            };
        default:
            return state;
    }
};
