import { productCategories, productCategoriesValues, productTypes, productTypeTranslations, productUnitsLabelEnum } from './enums';
import _ from 'lodash';
import { v1 as uuid } from 'uuid';

import { isDeeplyEqual, isValidValue } from '.';
import productFormHelper from './product-form-helper';
import productHelper from './product-helper';
import { HYBRID_SOLAR_CAPTORS, SYSTEM_LINKED_WITH_CENTRAL_HEATING_TRANSMITTER, LABEL_GREEN_FLAME_LABEL_SEVEN } from './product-constants';

const formatEnergyEfficienfy = (energyEfficiencyProperties, category, detailsPerOperation) => {
    let formattedEnergyEfficiencyProperties = [];
    if (productFormHelper.isCalorifugeProduct(category)) {
        formattedEnergyEfficiencyProperties.push("Vous renseignez la classe de l'isolant sur le devis en fonction de votre chantier.");
    } else {
        formattedEnergyEfficiencyProperties = energyEfficiencyProperties.map((energyEfficiency) => {
            return `${energyEfficiency.label} : ${productFormHelper.getEnergyEfficiencyFieldLabel(energyEfficiency.label, energyEfficiency.value)}`;
        });
    }

    return formattedEnergyEfficiencyProperties;
};

const filterProductsByType = (products, type) => {
    return products.filter((product) => product.type === type);
};

const getEnergyEfficiencyProperties = (product) => {
    if (product.type !== productTypes.MAIN_PRODUCT) return [];
    const formatedEnergyEfficiency = formatEnergyEfficienfy(product.energyEfficiencyProperties, product.category);
    if (product.category === productCategoriesValues.CHAUFFE_EAU_SOLAIRE_INDIVIDUEL) {
        return [
            `${HYBRID_SOLAR_CAPTORS} : ${productHelper.getValueLabel(productFormHelper.getExclusionDetailsToSpecifyValueByLabel(product, HYBRID_SOLAR_CAPTORS))}`,
            `Les capteurs solaires ont une certification CSTBat ou Solar Keymark ou équivalente : ${productHelper.getValueLabel(isValidValue(product.certificationFileUrl))}`,
        ];
    }

    if (product.category === productCategoriesValues.SYSTEME_SOLAIRE_COMBINE) {
        return [
            `${SYSTEM_LINKED_WITH_CENTRAL_HEATING_TRANSMITTER} : ${productHelper.getValueLabel(productFormHelper.getDetailsProductByLabel(product, SYSTEM_LINKED_WITH_CENTRAL_HEATING_TRANSMITTER))}`,
            `${HYBRID_SOLAR_CAPTORS} : ${productHelper.getValueLabel(productFormHelper.getExclusionDetailsToSpecifyValueByLabel(product, HYBRID_SOLAR_CAPTORS))}`,
            `Les capteurs solaires ont une certification CSTBat ou Solar Keymark ou équivalente : ${productHelper.getValueLabel(isValidValue(product.certificationFileUrl))}`,
            ...formatedEnergyEfficiency,
        ];
    }

    if (product.category === productCategoriesValues.APPAREIL_INDEPENDANT_CHAUFFAGE_BOIS && product.energyEfficiencyProperties?.length === 0) {
        return [LABEL_GREEN_FLAME_LABEL_SEVEN];
    }

    return formatedEnergyEfficiency;
};

const formatProductsForDisplay = (products) => {
    return products.map((product) => ({
        key: product.uuid,
        type: product.type,
        uuid: product.uuid,
        category: product.category,
        title: product.title,
        brand: product.brand,
        reference: product.reference,
        certification: product.certification,
        energyEfficiencyProperties: getEnergyEfficiencyProperties(product),

        name: product.name,
        textArea: product.textArea,
        price: product.price,
        vatRate: product.vatRate,

        isProductUpdatableOrDeletable: product.isProductUpdatableOrDeletable,
        productUpdatableOrDeletableState: {
            isProductUpdatableOrDeletable: product.isProductUpdatableOrDeletable,
            isConfirmed: productHelper.isProductConfirmed(product),
            isMainProduct: productHelper.isAMainProduct(product),
        },
        status: product.status,
    }));
};

const getProductCategory = (category) => {
    return productCategories[category];
};

const formatVatRate = (vatRate) => {
    return `${vatRate * 100} %`;
};

const formatDetailsPerOperation = (product) => {
    const formattedDetailsPerOperation = [];
    if (!_.isEmpty(product) && !_.isEmpty(product.detailsPerOperation)) {
        const entriesDetailsPerOperation = Object.entries(product.detailsPerOperation);
        for (const [key, value] of entriesDetailsPerOperation) {
            formattedDetailsPerOperation.push({
                operationSheetName: key,
                price: value.defaultPrice,
                vatRate: value.vatRate,
                formattedOperationSheetName: value.formattedOperationSheetName,
            });
        }
    }
    return formattedDetailsPerOperation;
};

const getProductOperations = (operations, product) => {
    if (product.detailsPerOperation) {
        Object.keys(product.detailsPerOperation).forEach((value) => operations.push(value));
    }

    return operations;
};

const getNumberOfOperations = (products) => {
    let operations = [];
    products.forEach((product) => {
        operations = getProductOperations(operations, product);
    });

    const numberOfOperations = Array.from(new Set(operations)).length;

    return numberOfOperations;
};

const getSelectProductTypePlaceholder = (productType) => {
    return productTypeTranslations[productType];
};

const getSelectProductUnitPlaceholder = (unit) => {
    return productUnitsLabelEnum[unit];
};

const formatOperationPrices = (product) => {
    const formattedOperationPrices = [];
    if (product.detailsPerOperation) {
        const entriesDetailsPerOperation = Object.entries(product.detailsPerOperation);
        for (const [key, value] of entriesDetailsPerOperation) {
            formattedOperationPrices.push({
                uuid: uuid(),
                operationSheetName: key,
                defaultPrice: value.defaultPrice,
                vatRate: value.vatRate,
                compliant: value.compliant,
                formattedOperationSheetName: value.formattedOperationSheetName,
            });
        }
    }
    return formattedOperationPrices;
};

const formatProductDetails = (product) => {
    const details = {};
    if (product.energyEfficiencyProperties) {
        product.energyEfficiencyProperties.forEach((energyEfficiency) => (details[energyEfficiency.label] = energyEfficiency.value));
    }

    if (product.detailsPerOperation) {
        const detailsPerOperation = Object.values(product.detailsPerOperation)[0];
        if (productHelper.productDetailsToSpecifyExist(detailsPerOperation)) {
            detailsPerOperation.details.productDetailsToSpecify.forEach((detailPerOperation) => (details[detailPerOperation.label] = detailPerOperation.value || !!detailPerOperation.value));
        }
        if (productHelper.productExclusionDetailsToSpecifyExist(detailsPerOperation)) {
            detailsPerOperation.details.productExclusionDetailsToSpecify.forEach((detailPerOperation) => (details[detailPerOperation.label] = detailPerOperation.value));
        }
    }

    return details;
};

const assignIdToEnergyEfficencyProperties = (selectedProductEnergyEfficiencyProperties, parsedFormDataEnergyEfficiencyProperties) => {
    const energyEfficiencyProperties = [];
    if (parsedFormDataEnergyEfficiencyProperties.length === 0) return [];

    if (selectedProductEnergyEfficiencyProperties.length === 0 && parsedFormDataEnergyEfficiencyProperties.length > 0) {
        parsedFormDataEnergyEfficiencyProperties.forEach((energyEfficiency, index) => {
            energyEfficiencyProperties.push({
                label: energyEfficiency.label,
                value: energyEfficiency.value,
            });
        });
    }

    for (let i = 0; i < selectedProductEnergyEfficiencyProperties.length; i++) {
        energyEfficiencyProperties.push({
            _id: selectedProductEnergyEfficiencyProperties[i]._id,
            label: parsedFormDataEnergyEfficiencyProperties[i].label,
            value: parsedFormDataEnergyEfficiencyProperties[i].value,
        });
    }
    return energyEfficiencyProperties;
};

const checkIfObjectsAreDeeplyEqualAndRemoveThemFromFoundUpdatesIfTheyAre = (selectedProduct, parsedFormData, foundUpdates) => {
    if (isDeeplyEqual(selectedProduct.detailsPerOperation, parsedFormData.detailsPerOperation)) {
        delete foundUpdates.detailsPerOperation;
    }
    if (isDeeplyEqual(selectedProduct.energyEfficiencyProperties, parsedFormData.energyEfficiencyProperties)) {
        delete foundUpdates.energyEfficiencyProperties;
    }
    return foundUpdates;
};

const displayContentTooltip = (isProductUpdatableOrDeletable, content) => {
    return isProductUpdatableOrDeletable ? '' : content;
};

const isFormForPricePerOperation = (formToDisplay) => {
    return ['operations', 'editOperation'].includes(formToDisplay);
};

function getProductThickness(detailsPerOperation) {
    return detailsPerOperation.details.productDetailsToSpecify.find((productDetailToSpecify) => productDetailToSpecify.label).value;
}

export default {
    formatProductsForDisplay,
    filterProductsByType,
    getProductCategory,
    formatVatRate,
    formatDetailsPerOperation,
    getNumberOfOperations,
    getSelectProductTypePlaceholder,
    formatOperationPrices,
    getSelectProductUnitPlaceholder,
    formatProductDetails,
    assignIdToEnergyEfficencyProperties,
    checkIfObjectsAreDeeplyEqualAndRemoveThemFromFoundUpdatesIfTheyAre,
    displayContentTooltip,
    isFormForPricePerOperation,
    getProductThickness,
    getEnergyEfficiencyProperties,
};
