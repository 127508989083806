export const SEARCH_CUSTOMERS_REQUEST = 'SEARCH_CUSTOMERS_REQUEST';
export const SEARCH_CUSTOMERS_SUCCESS = 'SEARCH_CUSTOMERS_SUCCESS';
export const SEARCH_CUSTOMERS_FAILURE = 'SEARCH_CUSTOMERS_FAILURE';

export const GET_CUSTOMER_ANALYTICS_REQUEST = 'GET_CUSTOMER_ANALYTICS_REQUEST';
export const GET_CUSTOMER_ANALYTICS_SUCCESS = 'GET_CUSTOMER_ANALYTICS_SUCCESS';
export const GET_CUSTOMER_ANALYTICS_FAILURE = 'GET_CUSTOMER_ANALYTICS_FAILURE';

export const GET_CUSTOMERS_ANALYTICS_REQUEST = 'GET_CUSTOMERS_ANALYTICS_REQUEST';
export const GET_CUSTOMERS_ANALYTICS_SUCCESS = 'GET_CUSTOMERS_ANALYTICS_SUCCESS';
export const GET_CUSTOMERS_ANALYTICS_FAILURE = 'GET_CUSTOMERS_ANALYTICS_FAILURE';
