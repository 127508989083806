import delegateeInvoiceOperationsFormFieldsDescriptor from './delegatee-invoice-operations-form';

export default [
    {
        property: 'reference',
        label: 'Numéro',
        type: 'string',
        required: true,
    },
    {
        property: 'date',
        label: 'Date',
        type: 'date',
        required: true,
    },
    ...delegateeInvoiceOperationsFormFieldsDescriptor,
];
