import { GET_RGE_CERTIFICATES_REQUEST, GET_RGE_CERTIFICATES_SUCCESS, GET_RGE_CERTIFICATES_FAILURE, CLEAR_RGE_CERTIFICATE_FINDER_DATA } from '../types/rge-certificates-finder.types';

function initState() {
    return {};
}

export default function (state = initState(), action) {
    switch (action.type) {
        case GET_RGE_CERTIFICATES_REQUEST:
            return {
                [action.payload.instanceKey]: {
                    loading: true,
                    remoteRGECertificates: [],
                },
            };
        case GET_RGE_CERTIFICATES_SUCCESS: {
            return {
                ...state,
                [action.payload.instanceKey]: {
                    loading: false,
                    remoteRGECertificates: action.payload.rgeCertificates,
                },
            };
        }
        case GET_RGE_CERTIFICATES_FAILURE: {
            return {
                ...state,
                [action.payload.instanceKey]: {
                    loading: false,
                    remoteRGECertificates: [],
                },
            };
        }

        case CLEAR_RGE_CERTIFICATE_FINDER_DATA: {
            const clonedState = { ...state };
            delete clonedState[action.payload.instanceKey];

            return clonedState;
        }

        default:
            return state;
    }
}
