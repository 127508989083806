import { DIFFERENT_PASSWORDS, OBLIGATORY_CONFIRM_PASSWORD } from '../messages';
import { isValidValue } from '../index';

export default [
    {
        property: 'password',
        label: 'Mot de passe',
        type: 'string',
        required: true,
    },
    {
        property: 'confirmPassword',
        label: 'Confirmation du mot de passe',
        type: 'string',
        required: true,
        validate: ({ password, confirmPassword }, formErrors) => {
            const samePasswords = password === confirmPassword;
            if (!samePasswords) formErrors.password = DIFFERENT_PASSWORDS;

            if (!isValidValue(confirmPassword)) formErrors.confirmPassword = OBLIGATORY_CONFIRM_PASSWORD;
            return samePasswords;
        },
    },
];
