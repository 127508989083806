import { LABEL_INPUT_HOUSING_NUMBER } from '../form_labels';
import {
    personIdentityFieldsDescriptorGenerator,
    personContactsFieldsDescriptorGenerator,
    addressFieldsDescriptorGenerator,
    fiscalInformationFieldsDescriptorGenerator,
    occupantFieldsDescriptor,
} from './common_descriptor_generator';

export default [
    ...personIdentityFieldsDescriptorGenerator(),
    ...personContactsFieldsDescriptorGenerator({ required: false }),
    ...addressFieldsDescriptorGenerator(),
    ...fiscalInformationFieldsDescriptorGenerator(),
    {
        property: 'housingNumber',
        label: LABEL_INPUT_HOUSING_NUMBER,
        type: 'string',
        requiredIf: ({ homeAddress }) => !homeAddress,
    },
    ...occupantFieldsDescriptor,
];
