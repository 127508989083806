import moment from 'moment';

import { dateUnits, genders } from './enums';
import dateUtils from './date_utils';
import { removeNullInString } from './string_utils';
import { isValidValue } from './index';

function getFullNameText(data = {}) {
    return data?.fullName || '';
}

function getFullAddressText(data = {}) {
    return data?.fullAddress || '';
}

function getGenderText({ gender } = {}) {
    if (!Object.keys(genders).includes(gender)) return '';

    return genders[gender];
}

function formatAddress({ streetNumber, streetName, city, postalCode } = {}) {
    return [removeNullInString(`${streetNumber ? streetNumber : ''} ${streetName ? streetName : ''}`), removeNullInString(`${postalCode ? postalCode : ''} ${city ? city : ''}`)]
        .filter(isValidValue)
        .map((addressLine) => addressLine.trim())
        .join(', ');
}

function formatToUpperCase(value) {
    return String(value).toUpperCase();
}

function formatPhoneNumber(phoneNumber, showDefaultMessage = true) {
    if (!phoneNumber) return showDefaultMessage ? 'Non renseigné' : '';

    return phoneNumber.padStart(10, '0').match(/\d{2}/g).join('.');
}

function formatLatePenalties(latePenalties) {
    if (!latePenalties) return "3 fois le taux d'intérêt légal";
    return `${latePenalties} %`;
}

function reverseString(value = '') {
    return value.split('').reverse().join('');
}

function roundToTwoDecimal(value) {
    return value ? Math.round((value + Number.EPSILON) * 100) / 100 : 0;
}

function extractAndFormatIntegerPart(parts) {
    let integerPart = parts[0];
    if (integerPart.length > 3) {
        const reversedIntegerPart = reverseString(integerPart);
        const threeDigitsGroups = reversedIntegerPart.match(/\d{3}/g);
        const restWithoutThreeDigitsGroups = reversedIntegerPart.substring(threeDigitsGroups.join('').length);
        integerPart = reverseString(restWithoutThreeDigitsGroups);
        threeDigitsGroups.reverse().forEach((group) => {
            integerPart += ` ${reverseString(group)}`;
        });
    }

    return integerPart;
}

function extractAndFormatDecimalPart(parts) {
    return parts.length > 1 ? `.${parts[1]}` : '';
}

function formatPrice(price = 0, options = { withMinusSymbol: false, withEuroSymbol: true, isTTC: false }) {
    const roundedPrice = roundToTwoDecimal(price);
    const priceParts = String(roundedPrice).split('.');

    const integerPart = extractAndFormatIntegerPart(priceParts);
    const decimalPart = extractAndFormatDecimalPart(priceParts);
    const pricePrefixes = [];
    if (options.withMinusSymbol) pricePrefixes.push('-');

    return `${pricePrefixes.join('')} ${integerPart}${decimalPart} ${options.withEuroSymbol ? '€' : ''} ${options.isTTC ? 'TTC' : ''}`.trim();
}

function formatVolume(volume = 0, options = { withUnit: true }) {
    const roundedVolume = roundToTwoDecimal(volume);
    const volumeParts = String(roundedVolume).split('.');

    const integerPart = extractAndFormatIntegerPart(volumeParts);
    const decimalPart = extractAndFormatDecimalPart(volumeParts);
    const volumeSuffixes = [];
    if (options.withUnit) volumeSuffixes.push('kWh cumac');

    return `${integerPart}${decimalPart} ${volumeSuffixes.join('')}`.trim();
}

function formatBillingPaymentTypes({ orderBy, iban }) {
    const paymentTypes = [];
    if (orderBy) paymentTypes.push('Chèque');
    if (iban) paymentTypes.push('Virement bancaire');

    return paymentTypes.join(', ');
}

function formatDate(date) {
    return moment(date).format('DD/MM/YYYY');
}

function capitalize(value) {
    if (typeof value !== 'string') return value;

    return value.length > 1 ? value[0].toUpperCase().concat(value.substr(1).toLowerCase()) : value.toUpperCase();
}

function sortObjectsDescendingByDateProperty({ objects = [], dateProperty }) {
    if (!dateProperty) return;

    return objects.sort((prevObject, nextObject) => {
        if (dateUtils.isAfter(prevObject[dateProperty], nextObject[dateProperty], dateUnits.MILLISECONDS)) return -1;
        if (dateUtils.isBefore(prevObject[dateProperty], nextObject[dateProperty], dateUnits.MILLISECONDS)) return 1;

        return 0;
    });
}

export default {
    capitalize,
    formatBillingPaymentTypes,
    formatDate,
    formatLatePenalties,
    formatPhoneNumber,
    formatPrice,
    formatToUpperCase,
    formatAddress,
    formatVolume,
    getFullAddressText,
    getFullNameText,
    getGenderText,
    roundToTwoDecimal,
    sortObjectsDescendingByDateProperty,
};
