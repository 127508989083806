const { isNullishOrEmpty } = require('.');

const hasProperty = (object, property) => {
    return Object.prototype.hasOwnProperty.call(object, property);
};

function extractPropertiesFromObject(source, properties) {
    if (isNullishOrEmpty(source) || isNullishOrEmpty(properties)) return {};

    const result = {};
    properties.forEach((property) => {
        result[property] = source[property];
    });

    return result;
}

module.exports = {
    hasProperty,
    extractPropertiesFromObject,
};
