import {
    CREATE_ORGANIZATION_REQUEST,
    CREATE_ORGANIZATION_SUCCESS,
    CREATE_ORGANIZATION_FAILURE,
    GET_ORGANIZATION_REQUEST,
    GET_ORGANIZATION_SUCCESS,
    GET_ORGANIZATION_FAILURE,
    PATCH_ORGANIZATION_REQUEST,
    PATCH_ORGANIZATION_SUCCESS,
    PATCH_ORGANIZATION_FAILURE,
    UPDATE_ORGANIZATION_REQUEST,
    UPDATE_ORGANIZATION_SUCCESS,
    UPDATE_ORGANIZATION_FAILURE,
    UPDATE_ORGANIZATION_ONBOARDING_REQUEST,
    UPDATE_ORGANIZATION_ONBOARDING_SUCCESS,
    UPDATE_ORGANIZATION_ONBOARDING_FAILURE,
    DELETE_INVITATION_REQUEST,
    DELETE_INVITATION_SUCCESS,
    DELETE_INVITATION_FAILURE,
    SHOW_SUBSCRIPTION_MODAL,
    HIDE_SUBSCRIPTION_MODAL,
    REFRESH_ORGANIZATION_INFORMATION_REQUEST,
    REFRESH_ORGANIZATION_INFORMATION_SUCCESS,
    REFRESH_ORGANIZATION_INFORMATION_FAILURE,
} from '../types/organization.types';

import organizationService from '../services/organization.service';
import organizationFinderActions from '../actions/organization-finder';
import formActions from '../actions/ui/form';
import dialogsActions from '../actions/ui/dialogs';
import authActions from '../actions/auth';
import { handleErrorResponse, isResourceNotFoundError, parseError } from '../../api';
import history from '../../utils/history';
import storage from '../../utils/storage';
import { patchOperations } from '../../utils/enums';
import { INVITATION_DELETED, ORGANIZATION_INFORMATION_REFRESHED } from '../../utils/messages';
import organizationHelper from '../../utils/organization-helper';

const createOrganization = (organizationToSave) => (dispatch, getState) => {
    dispatch({
        type: CREATE_ORGANIZATION_REQUEST,
    });
    dispatch(formActions.startRequesting());

    const account = getState().auth.account;

    organizationService
        .createOrganization(account.uuid, organizationToSave)
        .then(({ data }) => {
            storage.setOrganizationUUID({ organizationUUID: data.uuid });
            dispatch(authActions.getAccount());

            history.push('/organisation');

            dispatch(organizationFinderActions.clearData());
            dispatch({ type: CREATE_ORGANIZATION_SUCCESS, payload: data });
            dispatch(formActions.stopRequesting());
            dispatch(
                dialogsActions.showSuccessDialog({
                    message: 'Votre organisation est bien enregistrée',
                })
            );
        })
        .catch((error) => {
            const errorMessage = parseError(error);
            dispatch({
                type: CREATE_ORGANIZATION_FAILURE,
                error: errorMessage,
            });
            dispatch(formActions.stopRequesting());
            dispatch(
                dialogsActions.showFailureDialog({
                    message: errorMessage,
                })
            );
        });
};

const getOrganization = () => (dispatch) => {
    if (storage.isComplete()) {
        dispatch({ type: GET_ORGANIZATION_REQUEST, payload: null });

        organizationService
            .getOrganization(storage.getOrganizationUUID(), { includeInvitations: true, includeApplicationConfigurations: true })
            .then(({ data }) => {
                dispatch({ type: GET_ORGANIZATION_SUCCESS, payload: data });
            })
            .catch((error) => {
                dispatch({
                    type: GET_ORGANIZATION_FAILURE,
                    error: parseError(error),
                });
                if (isResourceNotFoundError(error)) {
                    dispatch(authActions.logout());
                }
            });
    } else {
        dispatch({ type: GET_ORGANIZATION_FAILURE });
    }
};

const updateOrganization = (organizationUpdates) => (dispatch) => {
    dispatch({
        type: UPDATE_ORGANIZATION_REQUEST,
    });
    dispatch(formActions.startRequesting());

    organizationService
        .updateOrganization(storage.getOrganizationUUID(), organizationUpdates)
        .then(({ data }) => {
            storage.setOrganizationUUID({ organizationUUID: data.uuid });
            dispatch(authActions.getAccount());

            dispatch(organizationFinderActions.clearData());
            dispatch({ type: UPDATE_ORGANIZATION_SUCCESS, payload: data });
            history.push('/organisation');
            dispatch(formActions.stopRequesting());
            dispatch(
                dialogsActions.showSuccessDialog({
                    message: 'Votre organisation a bien été mise à jour.',
                })
            );
        })
        .catch((error) => {
            const errorMessage = parseError(error);
            dispatch({
                type: UPDATE_ORGANIZATION_FAILURE,
                error: errorMessage,
            });
            dispatch(formActions.stopRequesting());
            dispatch(
                dialogsActions.showFailureDialog({
                    message: errorMessage,
                })
            );
        });
};

/*
 * Patch Organization
 */

const handleOnPatchOrganizationRequest = (dispatch) => {
    dispatch({ type: PATCH_ORGANIZATION_REQUEST });
    dispatch(formActions.startRequesting());
};

const handleOnPatchOrganizationSuccess = (dispatch, payloadData, successMessage) => {
    dispatch({ type: PATCH_ORGANIZATION_SUCCESS, payload: payloadData });
    history.push('/organisation');
    dispatch(formActions.stopRequesting());
    dispatch(
        dialogsActions.showSuccessDialog({
            message: successMessage,
        })
    );
};

const handleOnPatchOrganizationFailure = (dispatch, error) => {
    const errorMessage = parseError(error);
    dispatch(formActions.stopRequesting());
    dispatch(
        dialogsActions.showFailureDialog({
            message: errorMessage,
        })
    );
    dispatch({
        type: PATCH_ORGANIZATION_FAILURE,
        payload: { errorMessage },
    });
};

const createSubDocument = ({ data, path, successMessage, onSuccess }) => (dispatch, getState) => {
    handleOnPatchOrganizationRequest(dispatch);

    const account = getState().auth.account;
    organizationService
        .patchOrganizationWithJsonObject(account.uuid, storage.getOrganizationUUID(), patchOperations.ADD, path, data)
        .then(({ data }) => {
            if (onSuccess) onSuccess();
            handleOnPatchOrganizationSuccess(dispatch, data, successMessage);
        })
        .catch((error) => {
            handleOnPatchOrganizationFailure(dispatch, error);
        });
};

const createSubDocumentWithFile = ({ formData, data, path, successMessage, onSuccess }) => (dispatch, getState) => {
    handleOnPatchOrganizationRequest(dispatch);

    const account = getState().auth.account;
    formData.append('op', patchOperations.ADD);
    formData.append('path', path);
    formData.append('value', JSON.stringify(data));

    organizationService
        .patchOrganizationWithFile(account.uuid, storage.getOrganizationUUID(), formData)
        .then(({ data }) => {
            if (onSuccess) onSuccess();
            handleOnPatchOrganizationSuccess(dispatch, data, successMessage);
        })
        .catch((error) => {
            handleOnPatchOrganizationFailure(dispatch, error);
        });
};

const updateSubDocument = ({ uuid, updates, path, successMessage }) => (dispatch, getState) => {
    handleOnPatchOrganizationRequest(dispatch);

    const account = getState().auth.account;
    organizationService
        .patchOrganizationWithJsonObject(account.uuid, storage.getOrganizationUUID(), patchOperations.UPDATE, uuid ? `${path}/${uuid}` : path, updates)
        .then(({ data }) => {
            handleOnPatchOrganizationSuccess(dispatch, data, successMessage);
        })
        .catch((error) => {
            handleOnPatchOrganizationFailure(dispatch, error);
        });
};

const updateSubDocumentWithFile = ({ uuid, formData, updates = {}, path, successMessage, onSuccess }) => (dispatch, getState) => {
    handleOnPatchOrganizationRequest(dispatch);

    const account = getState().auth.account;

    formData.append('op', patchOperations.UPDATE);
    formData.append('path', uuid ? `${path}/${uuid}` : path);
    formData.append('value', JSON.stringify(updates));

    organizationService
        .patchOrganizationWithFile(account.uuid, storage.getOrganizationUUID(), formData)
        .then(({ data }) => {
            if (onSuccess) onSuccess();
            handleOnPatchOrganizationSuccess(dispatch, data, successMessage);
        })
        .catch((error) => {
            handleOnPatchOrganizationFailure(dispatch, error);
        });
};

const deleteSubDocument = ({ uuid, path, successMessage }) => (dispatch, getState) => {
    handleOnPatchOrganizationRequest(dispatch);

    const account = getState().auth.account;
    organizationService
        .patchOrganizationWithJsonObject(account.uuid, storage.getOrganizationUUID(), patchOperations.SAFEDELETE, `${path}/${uuid}`)
        .then(({ data }) => {
            handleOnPatchOrganizationSuccess(dispatch, data, successMessage);
        })
        .catch((error) => {
            handleOnPatchOrganizationFailure(dispatch, error);
        });
};

const updateOrganizationOnboarding = (value) => (dispatch) => {
    dispatch({ type: UPDATE_ORGANIZATION_ONBOARDING_REQUEST });

    organizationService
        .updateOrganizationOnboarding(storage.getOrganizationUUID(), { value, path: '/onboarding', op: 'update' })
        .then(({ data }) => {
            dispatch({ type: UPDATE_ORGANIZATION_ONBOARDING_SUCCESS, payload: data });
        })
        .catch(() => {
            dispatch({ type: UPDATE_ORGANIZATION_ONBOARDING_FAILURE });
        });
};

function deleteInvitation({ invitationUUID }) {
    return (dispatch, getState) => {
        dispatch({ type: DELETE_INVITATION_REQUEST });
        dispatch(formActions.startRequesting());

        const accountUUID = getState().auth.account.uuid;
        organizationService
            .deleteInvitation(invitationUUID, accountUUID)
            .then(({ data }) => {
                dispatch(formActions.stopRequesting());
                dispatch({ type: DELETE_INVITATION_SUCCESS, payload: { invitation: data } });
                dispatch(dialogsActions.showSuccessDialog({ message: INVITATION_DELETED }));
            })
            .catch(handleErrorResponse({ dispatch, errorType: DELETE_INVITATION_FAILURE }));
    };
}

function refreshOrganizationInformation() {
    return (dispatch) => {
        dispatch({ type: REFRESH_ORGANIZATION_INFORMATION_REQUEST });
        dispatch(formActions.startRequesting());

        organizationService
            .refreshOrganizationInformation(storage.getOrganizationUUID())
            .then(({ data: organization }) => {
                dispatch(formActions.stopRequesting());
                dispatch({ type: REFRESH_ORGANIZATION_INFORMATION_SUCCESS, payload: organizationHelper.extractOrganizationDetails(organization) });
                dispatch(dialogsActions.showSuccessDialog({ message: ORGANIZATION_INFORMATION_REFRESHED }));
            })
            .catch(handleErrorResponse({ dispatch, errorType: REFRESH_ORGANIZATION_INFORMATION_FAILURE }));
    };
}

function showSubscriptionModal({ organization }) {
    return { type: SHOW_SUBSCRIPTION_MODAL, payload: { organization } };
}

function hideSubscriptionModal() {
    return { type: HIDE_SUBSCRIPTION_MODAL };
}

export default {
    createOrganization,
    getOrganization,
    updateOrganization,
    createSubDocument,
    updateSubDocument,
    deleteSubDocument,
    createSubDocumentWithFile,
    updateSubDocumentWithFile,
    updateOrganizationOnboarding,
    deleteInvitation,
    showSubscriptionModal,
    hideSubscriptionModal,
    refreshOrganizationInformation,
};
