export const INFORMATIONS_TEST_ID = 'consumer mediator test-id';

export const NO_CONSUMER_MEDIATOR = 'Aucun médiateur de la consommation sélectionné';

export const CONSUMER_MEDIATOR_DEFINITION = {
    LINE_1: "Le médiateur de la consommation est un tiers qui aide un consommateur et un professionnel à parvenir à un accord pour résoudre à l'amiable un litige qui les oppose.",
    LINE_2: "À défaut d'accord amiable, le médiateur propose aux parties une solution pour régler le litige.",
    LINE_3: "La mention d'un médiateur de la consommation est obligatoire sur le cadre de contribution dans le cadre du dispositif des certificats d'économies d'énergie (arrêté du 25 mars 2020).",
};

export const CONSUMER_MEDIATOR_INFORMATION =
    "Le médiateur de la consommation est un tiers qui aide un consommateur et un professionnel à parvenir à un accord pour résoudre à l'amiable un litige qui les oppose. À défaut d'accord amiable, le médiateur propose aux parties une solution pour régler le litige. La mention d'un médiateur de la consommation est obligatoire sur le cadre de contribution dans le cadre du dispositif des certificats d'économies d'énergie (arrêté du 25 mars 2020). ";

export const SELECT_LABEL = 'Sélectionnez un médiateur dans la liste ci-dessous :';

export const LABEL_BUTTON_SAVE = 'Enregistrer';

export const EDIT_CONSUMER_MEDIATOR = 'edit';
