import productProperties from './product-properties';

export default [
    {
        property: 'type',
        label: 'Usage du produit',
        type: 'string',
        required: true,
    },
    ...productProperties,
];
