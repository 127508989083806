export const CREATE_CONTRACT_REQUEST = 'CREATE_CONTRACT_REQUEST';
export const CREATE_CONTRACT_SUCCESS = 'CREATE_CONTRACT_SUCCESS';
export const CREATE_CONTRACT_FAILURE = 'CREATE_CONTRACT_FAILURE';

export const GET_CONTRACTS_REQUEST = 'GET_CONTRACTS_REQUEST';
export const GET_CONTRACTS_SUCCESS = 'GET_CONTRACTS_SUCCESS';
export const GET_CONTRACTS_FAILURE = 'GET_CONTRACTS_FAILURE';

export const GET_CONTRACT_REQUEST = 'GET_CONTRACT_REQUEST';
export const GET_CONTRACT_SUCCESS = 'GET_CONTRACT_SUCCESS';
export const GET_CONTRACT_FAILURE = 'GET_CONTRACT_FAILURE';

export const DELETE_CONTRACT_REQUEST = 'DELETE_CONTRACT_REQUEST';
export const DELETE_CONTRACT_SUCCESS = 'DELETE_CONTRACT_SUCCESS';
export const DELETE_CONTRACT_FAILURE = 'DELETE_CONTRACT_FAILURE';

export const PATCH_CONTRACT_REQUEST = 'PATCH_CONTRACT_REQUEST';
export const PATCH_CONTRACT_SUCCESS = 'PATCH_CONTRACT_SUCCESS';
export const PATCH_CONTRACT_FAILURE = 'PATCH_CONTRACT_FAILURE';

export const GET_CONTRACTS_ANALYTICS_REQUEST = 'GET_CONTRACTS_ANALYTICS_REQUEST';
export const GET_CONTRACTS_ANALYTICS_SUCCESS = 'GET_CONTRACTS_ANALYTICS_SUCCESS';
export const GET_CONTRACTS_ANALYTICS_FAILURE = 'GET_CONTRACTS_ANALYTICS_FAILURE';
