import { unitTypes } from '../../utils/enums';
import {
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCTS_FAILURE,
    GET_PRODUCT_SUCCESS,
    GET_PRODUCT_QUANTITY_USED_IN_INVOICES_SUCCESS,
    CREATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_SUCCESS,
    GET_PRODUCT_ELIGIBILITY_SUCCESS,
    GET_NUMBER_OF_OPERATIONS_BY_PRODUCT_IN_INVOICES_SUCCESS,
} from '../types/product-catalogue.types';

const initState = () => ({
    product: {},
    products: [],
    productQuantityInInvoices: { quantity: 0, unit: unitTypes.SQUARE_METER },
    eligibilityTable: [],
    numberOfOperationsInInvoices: 0,
});

export default (state = initState(), action) => {
    switch (action.type) {
        case GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: action.payload,
            };
        case GET_PRODUCTS_FAILURE:
            return {
                ...state,
            };

        case GET_PRODUCT_SUCCESS:
            return {
                ...state,
                product: action.payload,
            };

        case GET_PRODUCT_QUANTITY_USED_IN_INVOICES_SUCCESS:
            return {
                ...state,
                productQuantityInInvoices: action.payload.productQuantityInInvoices,
            };

        case CREATE_PRODUCT_SUCCESS:
            return {
                ...state,
            };

        case UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                product: action.payload,
            };

        case DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                products: state.products.filter((product) => product.uuid !== action.payload.uuid),
            };

        case GET_PRODUCT_ELIGIBILITY_SUCCESS:
            return {
                ...state,
                eligibilityTable: action.payload,
            };

        case GET_NUMBER_OF_OPERATIONS_BY_PRODUCT_IN_INVOICES_SUCCESS:
            return {
                ...state,
                numberOfOperationsInInvoices: action.payload,
            };

        default:
            return state;
    }
};
