import { applicationConfigurationNames, invitationStatuses, invitationStatusesTranslation, paymentTypesTranslations, promotionCodeBenefitTypes, subscriptionStatuses } from './enums';
import { FULLY_UPDATE_ORGANIZATION_TOOLTIP_CONTENT } from '../utils/messages/organizations';
import { isNonEmptyObject, isValidValue } from './';
import { ACCOUNT_NOT_VERIFIED, INVITATION_NOT_DELETABLE } from './messages';
import storage from './storage';
import entitiesUtils from './entities-utils';
import dateUtils from './date_utils';
import { extractPropertiesFromObject } from './object.helper';

function isPaymentTypeEnabled(organizationBilling = {}, paymentType) {
    const { paymentTypes = [] } = organizationBilling;

    return paymentTypes.includes(paymentType);
}

function formatOrganizationBillingPaymentTypes(organizationBilling = {}) {
    const { paymentTypes = [] } = organizationBilling;

    return paymentTypes.map((paymentType) => paymentTypesTranslations[paymentType]).join(', ');
}

function getCurrentOrganization(organization = {}, remoteOrganizationInformation = {}) {
    return isNonEmptyObject(organization) ? organization : parseRemoteOrganizationInformation(remoteOrganizationInformation);
}

function parseRemoteOrganizationInformation(remoteOrganizationInformation) {
    return isNonEmptyObject(remoteOrganizationInformation)
        ? {
              ...remoteOrganizationInformation.details,
              organizationAddresses: remoteOrganizationInformation.addresses,
              organizationContacts: remoteOrganizationInformation.contacts,
          }
        : {};
}

function formatEditable(editable) {
    return isValidValue(editable) ? editable : false;
}

function formatOrganizationInvitations(organizationInvitations = []) {
    return organizationInvitations
        .filter((invitation) => {
            return !invitation.email.includes('support@renolib.fr');
        })
        .map((invitation) => ({
            firstName: extractInvitationFirstName(invitation),
            lastName: extractInvitationLastName(invitation),
            email: invitation.email,
            phone: extractInvitationPhone(invitation),
            mobile: extractInvitationMobile(invitation),
            status: invitation.status,
            statusDate: extractInvitationStatusDate(invitation),
            invitation,
        }));
}

function extractInvitationFirstName({ account }) {
    return account?.firstName;
}

function extractInvitationLastName({ account }) {
    return account?.lastName;
}

function extractInvitationPhone({ account }) {
    return account?.phone;
}

function extractInvitationMobile({ account }) {
    return account?.mobile;
}

function extractInvitationStatusDate({ status, sendingDate, acceptanceDate, confirmationDate }) {
    const statusDateValuesPerStatus = {
        [invitationStatuses.SENT]: sendingDate,
        [invitationStatuses.ACCEPTED]: acceptanceDate,
        [invitationStatuses.CONFIRMED]: confirmationDate,
    };

    return statusDateValuesPerStatus[status];
}

function formatInvitationStatus(status) {
    return {
        [invitationStatuses.SENT]: invitationStatusesTranslation.SENT,
        [invitationStatuses.ACCEPTED]: invitationStatusesTranslation.ACCEPTED,
        [invitationStatuses.CONFIRMED]: invitationStatusesTranslation.CONFIRMED,
    }[status];
}

function isFirstNameColumnSortable(organizationInvitations = []) {
    return organizationInvitations.every((invitation) => isValidValue(extractInvitationFirstName(invitation)));
}

function isLastNameColumnSortable(organizationInvitations = []) {
    return organizationInvitations.every((invitation) => isValidValue(extractInvitationLastName(invitation)));
}

function getOrganizationFullyUpdateTooltipMessage(organization) {
    return !isOrganizationFullyUpdatable(organization) ? FULLY_UPDATE_ORGANIZATION_TOOLTIP_CONTENT : '';
}

function isOrganizationFullyUpdatable({ fullyUpdatable }) {
    return fullyUpdatable;
}

function formatSubscriptionTrialMode({ subscription }) {
    return subscription.trialMode ? 'Active' : 'Terminée';
}

function formatSubscriptionAccountsCount({ applicationConfigurations }) {
    const allowedAccountsCount = applicationConfigurations[applicationConfigurationNames.ALLOWED_ACCOUNTS_COUNT].allowedCount;
    const usedAccountsCount = applicationConfigurations[applicationConfigurationNames.ALLOWED_ACCOUNTS_COUNT].usedCount;

    return `${usedAccountsCount} / ${allowedAccountsCount}`;
}

function formatSubscriptionContractsCount({ applicationConfigurations }) {
    const allowedContractsCount = applicationConfigurations[applicationConfigurationNames.ALLOWED_CONTRACTS_COUNT].allowedCount;
    const usedContractsCount = applicationConfigurations[applicationConfigurationNames.ALLOWED_CONTRACTS_COUNT].usedCount;

    return `${usedContractsCount} / ${allowedContractsCount}`;
}

function formatContractsNegotiatedByRenolibCount({ applicationConfigurations }) {
    return applicationConfigurations[applicationConfigurationNames.ALLOWED_CONTRACTS_COUNT].negotiatedByRenolibCount;
}

function isInvitationButtonDisabled(account) {
    return !account.isVerified;
}

function getInvitationButtonTooltipContent(account) {
    return !account.isVerified ? ACCOUNT_NOT_VERIFIED : '';
}

function isInvitationDeletable(invitation) {
    return invitation.status === invitationStatuses.SENT;
}

function getDeleteInvitationButtonMessage(invitation) {
    return isInvitationDeletable(invitation) ? '' : INVITATION_NOT_DELETABLE;
}

function canShowSubscriptionModal(organization, subscriptionModalShown) {
    if (!storage.isAuthenticate() || !isNonEmptyObject(organization?.subscription)) return false;

    const { status, trialMode } = organization.subscription;

    return subscriptionModalShown || status === subscriptionStatuses.CANCELLED || (status === subscriptionStatuses.PENDING && trialMode === false);
}

function canShowRenewSubscriptionButton(subscription) {
    return isSubscriptionCancelled(subscription);
}

function canShowManageSubscriptionButton(subscription) {
    return isSubscriptionActive(subscription) || isSubscriptionPending(subscription);
}

function isSubscriptionCancelled(subscription) {
    return subscription.status === subscriptionStatuses.CANCELLED;
}

function isSubscriptionActive(subscription) {
    return subscription.status === subscriptionStatuses.ACTIVE;
}

function isSubscriptionPending(subscription) {
    return subscription.status === subscriptionStatuses.PENDING;
}

function formatCurrentBillingPeriodEndDate(organization) {
    const currentPeriodEndDateTimestamp = getCurrentBillingPeriodEndDateTimestamp(organization);

    return entitiesUtils.formatDate(currentPeriodEndDateTimestamp);
}

function getCurrentBillingPeriodEndDateTimestamp(organization) {
    const { subscription, appliedPromotionCodes = [] } = organization;
    const { trialModePlannedEndDate, currentPeriodEndDate } = subscription;
    const potentialCurrentBillingPeriodEndDates = [trialModePlannedEndDate, currentPeriodEndDate];
    if (appliedPromotionCodes.length > 0) {
        const lastAppliedPromotionCode = appliedPromotionCodes[appliedPromotionCodes.length - 1];
        potentialCurrentBillingPeriodEndDates.push(lastAppliedPromotionCode.endDate);
    }

    return Math.max(...potentialCurrentBillingPeriodEndDates.filter(isValidValue).map(Date.parse));
}

function formatCurrentBillingPeriodEndDateMessage(organization) {
    let message = '';
    const { appliedPromotionCodes } = organization;
    if (appliedPromotionCodes.length > 0) {
        const lastAppliedPromotionCode = appliedPromotionCodes[appliedPromotionCodes.length - 1];
        if (
            isNonEmptyObject(lastAppliedPromotionCode.promotionCode) &&
            lastAppliedPromotionCode.promotionCode.benefitType === promotionCodeBenefitTypes.APPLICATION_FREE_ACCESS &&
            dateUtils.isSameOrAfter(lastAppliedPromotionCode.endDate)
        ) {
            message = `(Vous bénéficiez d'un avantage : ${lastAppliedPromotionCode.promotionCode.benefitData.numberOfFreeMonths} mois gratuit(s) grâce au code promo ${lastAppliedPromotionCode.promotionCode.code})`;
        }
    }

    return message;
}

function extractOrganizationDetails(organization) {
    return extractPropertiesFromObject(organization, ['siren', 'siret', 'name', 'capital', 'type', 'vat', 'ape', 'naf', 'logo', 'website', 'rcs']);
}

export default {
    isPaymentTypeEnabled,
    formatOrganizationBillingPaymentTypes,
    getCurrentOrganization,
    formatEditable,
    formatOrganizationInvitations,
    formatInvitationStatus,
    isFirstNameColumnSortable,
    isLastNameColumnSortable,
    getOrganizationFullyUpdateTooltipMessage,
    isOrganizationFullyUpdatable,
    formatSubscriptionTrialMode,
    formatSubscriptionAccountsCount,
    formatSubscriptionContractsCount,
    getInvitationButtonTooltipContent,
    isInvitationButtonDisabled,
    isInvitationDeletable,
    getDeleteInvitationButtonMessage,
    canShowSubscriptionModal,
    canShowRenewSubscriptionButton,
    canShowManageSubscriptionButton,
    isSubscriptionCancelled,
    isSubscriptionActive,
    formatContractsNegotiatedByRenolibCount,
    formatCurrentBillingPeriodEndDate,
    formatCurrentBillingPeriodEndDateMessage,
    extractOrganizationDetails,
};
