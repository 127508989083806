import { endpointV2, authHeaders, getAuthHeadersWithJSONContentType } from '../../api';
import storage from '../../utils/storage';

function getBaseUrl() {
    return `/organizations/${storage.getOrganizationUUID()}/quotations`;
}

async function createQuotation() {
    return endpointV2.post(getBaseUrl(), null, {
        headers: authHeaders(),
    });
}

async function getQuotationsCount(query) {
    return endpointV2.get(`${getBaseUrl()}/count`, {
        headers: authHeaders(),
        params: {
            ...query,
        },
    });
}

async function getQuotations(query) {
    return endpointV2.get(getBaseUrl(), {
        headers: authHeaders(),
        params: {
            ...query,
        },
    });
}

async function getQuotation(quotationUUID) {
    return endpointV2.get(`${getBaseUrl()}/${quotationUUID}`, {
        headers: authHeaders(),
    });
}

async function patchQuotation(quotationUUID, operation, path, value) {
    return endpointV2.patch(
        `${getBaseUrl()}/${quotationUUID}`,
        { op: operation, path, value },
        {
            headers: getAuthHeadersWithJSONContentType(),
        }
    );
}

async function saveQuotation(quotationUUID, updates) {
    return endpointV2.put(`${getBaseUrl()}/${quotationUUID}/save`, updates, { headers: getAuthHeadersWithJSONContentType() });
}

async function saveQuotationAsDraft(quotationUUID, updates) {
    return endpointV2.put(`${getBaseUrl()}/${quotationUUID}/save-as-draft`, updates, { headers: getAuthHeadersWithJSONContentType() });
}

async function refuseQuotation(quotationUUID) {
    return endpointV2.put(`${getBaseUrl()}/${quotationUUID}/refuse`, {}, { headers: getAuthHeadersWithJSONContentType() });
}

async function deleteQuotation(quotationUUID, accountUUID) {
    return endpointV2.delete(`${getBaseUrl()}/${quotationUUID}`, { headers: authHeaders(), params: { uuidAccount: accountUUID } });
}

async function getQuotationsAnalytics() {
    return endpointV2.get(`/organizations/${storage.getOrganizationUUID()}/analytical-data/quotations`, { headers: authHeaders() });
}

async function checkQuotationInformationRedundancy(quotation) {
    return endpointV2.post(`${getBaseUrl()}/information-redundancy-check`, quotation, { headers: getAuthHeadersWithJSONContentType() });
}

async function sendQuotationByEmail(quotationUUID, datas) {
    return endpointV2.put(`${getBaseUrl()}/${quotationUUID}/send`, datas, { headers: getAuthHeadersWithJSONContentType() });
}

async function resetQuotationContract(quotationUUID) {
    return endpointV2.put(`${getBaseUrl()}/${quotationUUID}/reset-contract`, {}, { headers: getAuthHeadersWithJSONContentType() });
}

async function setQuotationSentToCustomer(quotationUUID) {
    return endpointV2.put(`${getBaseUrl()}/${quotationUUID}/set-quotation-to-sent-customer`, {}, { headers: getAuthHeadersWithJSONContentType() });
}

export default {
    createQuotation,
    getQuotationsCount,
    getQuotations,
    getQuotation,
    patchQuotation,
    saveQuotation,
    saveQuotationAsDraft,
    deleteQuotation,
    getQuotationsAnalytics,
    checkQuotationInformationRedundancy,
    sendQuotationByEmail,
    refuseQuotation,
    resetQuotationContract,
    setQuotationSentToCustomer,
};
