import { GET_OPERATION_SHEETS_SUCCESS, GET_OPERATION_SHEET_SUCCESS } from '../types/operation-sheet.types';

function initState() {
    return {
        operationSheets: [],
        operationSheet: {},
    };
}

export default function (state = initState(), action) {
    switch (action.type) {
        case GET_OPERATION_SHEETS_SUCCESS:
            return {
                ...state,
                operationSheets: action.payload.operationSheets,
            };

        case GET_OPERATION_SHEET_SUCCESS:
            return {
                ...state,
                operationSheet: action.payload.operationSheet,
            };
        default:
            return state;
    }
}
