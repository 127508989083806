export const LABEL_SELECT_TECHNICIAN = 'Sélectionner un technicien';
export const LABEL_SELECT_CONTRACT = 'Sélectionner un contrat';
export const LABEL_ADD_OPERATION = 'Ajouter une opération';
export const LABEL_SEND = 'Envoyer';

export const LABEL_SELECT_QUOTATION_OPERATION = 'Sélectionner une opération';
export const LABEL_SELECT_INSTALLATION_TYPE = "Type d'installation";

export const LABEL_AUTOCOMPLETE_PRODUCT = 'Rechercher un produit';

export const LABEL_BUTTON_CREATE_PRODUCT = 'Créer un produit';

export const LABEL_BUTTON_QUOTATION_PROVIDE_PRODUCT = 'Renseigner votre produit';
export const LABEL_BUTTON_QUOTATION_EDIT_PRODUCT = 'Modifier votre produit';

export const LABEL_INPUT_TECHNICAL_VISIT_DATE = 'Date de la visite technique préalable';
export const LABEL_CHECKBOX_WITHOUT_TECHNICAL_VISIT_DATE = 'Sans visite technique préalable';

export const LABEL_INPUT_BUILDING_TOTAL_AREA = 'Surface totale du bâtiment (m²)';
export const LABEL_INPUT_WINDOW_TOTAL_AREA = 'Surface de la toiture protégée (m²)';

export const LABEL_INPUT_QUANTITY = 'Quantité';

export const LABEL_SEARCH_PRODUCT = 'Rechercher un produit';
export const LABEL_CREATE_PRODUCT = 'Créer un produit';
export const LABEL_CREATED_PRODUCT = 'Votre produit a bien été enregistré';

export const LABEL_WASTE_MANAGEMENT_TITLE = 'Gestion des déchets conformément au décret n° 2020-1817 du 29 décembre 2020';
export const LABEL_WASTE_TYPE = 'Typologie de déchets :';
export const LABEL_ADD_WASTE_TYPE = 'Ajouter une autre typologie de déchets';
export const LABEL_WASTE_COLLECTION_DEPOT_SOCIAL_REASON = 'Raison sociale du point de collecte';
export const LABEL_TRASH_COLLECTION_DEPOT_LABEL = 'Point(s) de collecte des déchets :';
export const LABEL_WASTE_COLLECTION_DEPOT_CREATE = 'Créer un point de collecte';
export const LABEL_WASTE_COLLECTION_DEPOT_AUTOCOMPLETE = `Recherche d'un point de collecte`;
export const LABEL_WASTE_MODALITIES = `Modalités prévues de gestion et d'enlèvement des déchets (ex: tri réalisé, collectes séparées de déchets, broyage de déchets...) :`;
