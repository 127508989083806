export const requiredErrorMessages = {
    generic: 'Veuillez renseigner ce champs',
    genericAll: 'Veuillez renseigner tous les champs',
};

export const customErrorMessages = {
    dateIsBeforeContractSignDate: 'La date sélectionnée doit être supérieure à la date de signature du contrat',
    startDateIsBeforeToday: 'La date sélectionée doit être supérieure à celle du jour.',
    invalidPhone: 'Le numéro de téléphone saisi est invalide',
    invalidEmail: "L'adresse email saisie est invalide",
    differentPasswords: 'Vos mots de passe ne sont pas identiques',
    invalidIban: "L'IBAN saisi est invalide",
    invalidUrl: 'Le lien saisi est invalide',
    invalidPostalCode: "Le code postal n'est pas valide",
    missingPhone: 'Vous devez renseigner au moins un numéro de téléphone',
    missingEmail: 'Adresse email obligatoire',
};
