import {
    SIGN_UP_REQUEST,
    SIGN_UP_SUCCESS,
    SIGN_UP_FAILURE,
    SIGN_IN_REQUEST,
    SIGN_IN_SUCCESS,
    SIGN_IN_FAILURE,
    LOGOUT,
    GET_ACCOUNT_REQUEST,
    GET_ACCOUNT_SUCCESS,
    GET_ACCOUNT_FAILURE,
    SEND_VALIDATION_LINK_REQUEST,
    SEND_VALIDATION_LINK_SUCCESS,
    CONFIRM_VALIDATION_TOKEN_REQUEST,
    CONFIRM_VALIDATION_TOKEN_SUCCESS,
    CONFIRM_VALIDATION_TOKEN_FAILURE,
    SEND_INVITATION_LINKS_REQUEST,
    SEND_INVITATION_LINKS_SUCCESS,
    SEND_INVITATION_LINKS_FAILURE,
    UPDATE_ACCOUNT_REQUEST,
    UPDATE_ACCOUNT_SUCCESS,
    UPDATE_ACCOUNT_FAILURE,
    UPDATE_ACCOUNT_PASSWORD_REQUEST,
    UPDATE_ACCOUNT_PASSWORD_SUCCESS,
    UPDATE_ACCOUNT_PASSWORD_FAILURE,
    SEND_RESET_PASSWORD_LINK_REQUEST,
    SEND_RESET_PASSWORD_LINK_SUCCESS,
    SEND_RESET_PASSWORD_LINK_FAILURE,
    SET_NEW_PASSWORD_REQUEST,
    SET_NEW_PASSWORD_SUCCESS,
    SET_NEW_PASSWORD_FAILURE,
    SEND_VALIDATION_LINK_FAILURE,
} from '../types/auth';
import authService from '../services/auth.service';
import formActions from '../actions/ui/form';
import dialogsActions from '../actions/ui/dialogs';
import { handleErrorResponse, isResourceNotFoundError, parseError } from '../../api';
import { SIGN_IN_ROUTE } from '../../api/routes';
import storage from '../../utils/storage';
import history from '../../utils/history';
import organizationActions from './organization.actions';
import formDataParser from '../../utils/form-data-parser';
import { ACCOUNT_VALIDATION_EMAIL_SENT } from '../../utils/messages';

const signUp = ({ account, organizationUUID }) => (dispatch) => {
    dispatch({ type: SIGN_UP_REQUEST, payload: account });
    dispatch(formActions.startRequesting());

    authService
        .signUp(account, organizationUUID)
        .then((response) => {
            const { data } = response;

            dispatch(formActions.stopRequesting());
            dispatch({ type: SIGN_UP_SUCCESS, payload: data });

            const { token } = data;
            storage.setToken({ token });
            if (organizationUUID) storage.setOrganizationUUID({ uuidOrganization: organizationUUID });

            setTimeout(() => {
                // window.location.reload();
                history.push('/');
            }, 20);
        })
        .catch((error) => {
            const errorMessage = parseError(error);
            if (errorMessage) dispatch(formActions.stopRequesting({ errorMessage }));

            dispatch({ type: SIGN_UP_FAILURE, payload: { errorMessage } });
        });
};

const signIn = ({ credentials }) => (dispatch) => {
    dispatch({ type: SIGN_IN_REQUEST, payload: credentials });
    dispatch(formActions.startRequesting());

    authService
        .signIn(credentials)
        .then((response) => {
            const { data } = response;

            dispatch(formActions.stopRequesting());
            dispatch({ type: SIGN_IN_SUCCESS, payload: data });

            const { token } = data;
            storage.setToken({ token });
            setOrganizationUUIDInLocalStorage(data);

            setTimeout(() => {
                window.location.reload();
            }, 20);
        })
        .catch((error) => {
            const errorMessage = parseError(error);
            if (errorMessage) dispatch(formActions.stopRequesting({ errorMessage }));

            dispatch({ type: SIGN_IN_FAILURE });
        });
};

const logout = () => {
    storage.clearAll();
    history.push(SIGN_IN_ROUTE);

    return {
        type: LOGOUT,
    };
};

const getAccount = () => (dispatch, getState) => {
    const lastActionType = getState().ui.lastAction.type;

    const accountId = storage.getAccountId();
    dispatch({ type: GET_ACCOUNT_REQUEST });

    authService
        .getAccount(accountId)
        .then((response) => {
            const { data } = response;

            setOrganizationUUIDInLocalStorage(data);

            dispatch({
                type: GET_ACCOUNT_SUCCESS,
                payload: { account: data, lastActionType },
            });
        })
        .catch((error) => {
            const errorMessage = parseError(error);
            dispatch({
                type: GET_ACCOUNT_FAILURE,
                payload: { error: errorMessage },
            });
            if (isResourceNotFoundError(error)) dispatch(logout());
        });
};

function setOrganizationUUIDInLocalStorage(signInResponse) {
    if (signInResponse.uuidOrganization) {
        storage.setOrganizationUUID({
            organizationUUID: signInResponse.uuidOrganization,
        });
    }
}

const sendValidationLink = () => (dispatch, getState) => {
    dispatch({ type: SEND_VALIDATION_LINK_REQUEST });
    dispatch(formActions.startRequesting());

    authService
        .sendValidationLink(getState().auth.account.email)
        .then(() => {
            dispatch({ type: SEND_VALIDATION_LINK_SUCCESS });
            dispatch(formActions.stopRequesting());
            dispatch(
                dialogsActions.showSuccessDialog({
                    message: ACCOUNT_VALIDATION_EMAIL_SENT,
                })
            );
        })
        .catch(handleErrorResponse({ dispatch, errorType: SEND_VALIDATION_LINK_FAILURE }));
};

const confirmValidationToken = ({ token }) => (dispatch) => {
    dispatch({ type: CONFIRM_VALIDATION_TOKEN_REQUEST });

    authService
        .confirmValidationToken(token)
        .then(() => {
            dispatch({ type: CONFIRM_VALIDATION_TOKEN_SUCCESS });
            history.push('/');
        })
        .catch((error) => {
            const errorMessage = parseError(error);
            dispatch({
                type: CONFIRM_VALIDATION_TOKEN_FAILURE,
                payload: { errorMessage },
            });
        });
};

function sendInvitationLinks({ emails }) {
    return (dispatch, getState) => {
        dispatch({ type: SEND_INVITATION_LINKS_REQUEST });
        dispatch(formActions.startRequesting());

        const accountUUID = getState().auth.account.uuid;
        authService
            .sendInvitationLinks(emails, accountUUID)
            .then(() => {
                dispatch(formActions.stopRequesting());
                dispatch({ type: SEND_INVITATION_LINKS_SUCCESS });
                dispatch(dialogsActions.showSuccessDialog({ message: 'Invitation(s) envoyée(s) !' }));
                dispatch(organizationActions.getOrganization());
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(formActions.stopRequesting());
                dispatch({ type: SEND_INVITATION_LINKS_FAILURE, payload: { errorMessage } });
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
}

function updateAccount({ updates = {}, file } = {}) {
    return (dispatch, getState) => {
        dispatch({ type: UPDATE_ACCOUNT_REQUEST });
        dispatch(formActions.startRequesting());

        authService
            .updateAccount(getState().auth.account.uuid, formDataParser.toFormData(updates, file))
            .then(({ data }) => {
                dispatch(formActions.stopRequesting());
                dispatch({ type: UPDATE_ACCOUNT_SUCCESS, payload: { account: data } });
                dispatch(dialogsActions.showSuccessDialog({ message: 'Vos informations ont bien été mises à jour' }));
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(formActions.stopRequesting());
                dispatch({ type: UPDATE_ACCOUNT_FAILURE, payload: { errorMessage } });
            });
    };
}

function updateAccountPassword(passwordUpdates) {
    return (dispatch, getState) => {
        dispatch({ type: UPDATE_ACCOUNT_PASSWORD_REQUEST });
        dispatch(formActions.startRequesting());

        const accountId = getState().auth.account._id;
        authService
            .updateAccountPassword(accountId, passwordUpdates)
            .then(({ data }) => {
                dispatch(formActions.stopRequesting());
                dispatch({ type: UPDATE_ACCOUNT_PASSWORD_SUCCESS, payload: { account: data } });
                dispatch(dialogsActions.showSuccessDialog({ message: 'Votre mot de passe a bien été mis à jour' }));
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
                dispatch({ type: UPDATE_ACCOUNT_PASSWORD_FAILURE, payload: { errorMessage } });
            });
    };
}

function sendResetPasswordLink({ email }) {
    return (dispatch) => {
        dispatch({ type: SEND_RESET_PASSWORD_LINK_REQUEST });
        dispatch(formActions.startRequesting());

        authService
            .sendResetPasswordLink(email)
            .then(() => {
                dispatch({ type: SEND_RESET_PASSWORD_LINK_SUCCESS });
                dispatch(formActions.stopRequesting({ successMessage: 'Un email de changement de mot de passe vous a été envoyé' }));
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch({ type: SEND_RESET_PASSWORD_LINK_FAILURE });
                dispatch(formActions.stopRequesting({ errorMessage }));
            });
    };
}

function setNewPassword({ accountUUID, password, token }) {
    return (dispatch) => {
        dispatch({ type: SET_NEW_PASSWORD_REQUEST });
        dispatch(formActions.startRequesting());

        authService
            .setNewPassword(accountUUID, password, token)
            .then(() => {
                dispatch({ type: SET_NEW_PASSWORD_SUCCESS });
                dispatch(formActions.stopRequesting({ successMessage: 'Votre mot de passe a bien été mis à jour' }));
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch({ type: SET_NEW_PASSWORD_FAILURE });
                dispatch(formActions.stopRequesting({ errorMessage }));
            });
    };
}

export default {
    signUp,
    signIn,
    logout,
    getAccount,
    sendValidationLink,
    confirmValidationToken,
    sendInvitationLinks,
    updateAccount,
    updateAccountPassword,
    sendResetPasswordLink,
    setNewPassword,
};
