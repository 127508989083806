import {
    AUTOCOMPLETE_ORGANIZATION_REQUEST,
    AUTOCOMPLETE_ORGANIZATION_SUCCESS,
    AUTOCOMPLETE_ORGANIZATION_FAILURE,
    GET_ORGANIZATION_INFORMATION_REQUEST,
    GET_ORGANIZATION_INFORMATION_SUCCESS,
    GET_ORGANIZATION_INFORMATION_FAILURE,
    CLEAR_ORGANIZATION_FINDER_DATA,
} from '../types/organization-finder';

import organizationFinderService from '../services/organization-finder';
import { parseError } from '../../api';

const autocomplete = ({ query, instanceKey }) => (dispatch) => {
    dispatch({
        type: AUTOCOMPLETE_ORGANIZATION_REQUEST,
        payload: { instanceKey },
    });

    organizationFinderService
        .autocomplete(query)
        .then((response) => {
            const { data } = response;
            dispatch({
                type: AUTOCOMPLETE_ORGANIZATION_SUCCESS,
                payload: { instanceKey, data },
            });
        })
        .catch(() => {
            dispatch({
                type: AUTOCOMPLETE_ORGANIZATION_FAILURE,
                payload: { instanceKey },
            });
        });
};

const getOrganizationInformation = ({ companyId }) => (dispatch) => {
    dispatch({
        type: GET_ORGANIZATION_INFORMATION_REQUEST,
    });

    organizationFinderService
        .getOrganizationInformation(companyId)
        .then((response) => {
            const { data } = response;
            dispatch({
                type: GET_ORGANIZATION_INFORMATION_SUCCESS,
                payload: data,
            });
        })
        .catch((error) => {
            dispatch({
                type: GET_ORGANIZATION_INFORMATION_FAILURE,
                payload: { error: parseError(error) },
            });
        });
};

const clearData = ({ instanceKey } = {}) => ({
    type: CLEAR_ORGANIZATION_FINDER_DATA,
    payload: { instanceKey },
});

export default {
    autocomplete,
    getOrganizationInformation,
    clearData,
};
