import { serialize } from 'object-to-formdata';

function fromJSON(json) {
    return serialize(json, { indices: true });
}

function toFormData(jsonPart = {}, filePart) {
    const formData = fromJSON(jsonPart);
    if (filePart) formData.append('file', filePart);

    return formData;
}

function formDataToJSON(value) {
    if (value instanceof FormData) {
        const valueAsJSON = {};
        value.forEach((value, key) => {
            valueAsJSON[key] = toJSON(value);
        });

        return valueAsJSON;
    }

    return value;
}

function toJSON(value) {
    try {
        return JSON.parse(value);
    } catch (error) {
        return value;
    }
}

export default { fromJSON, toFormData, formDataToJSON };
