export default [
    {
        property: 'email',
        label: 'Adresse email',
        type: 'string',
        dataType: 'emailAddress',
        required: true,
    },
    {
        property: 'password',
        label: 'Mot de passe',
        type: 'string',
        required: true,
    },
];
