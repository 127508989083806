import {
    CREATE_CUSTOMER_PM_SUCCESS,
    GET_CUSTOMER_PM_SUCCESS,
    GET_CUSTOMERS_PM_SUCCESS,
    PATCH_CUSTOMER_PM_SUCCESS,
    DELETE_CUSTOMER_PM_SUCCESS,
    GET_CUSTOMERS_PM_FAILURE,
    GET_CUSTOMERS_PM_REQUEST,
} from '../types/customer-pm.types';
import { GET_QPVS_SUCCESS } from '../types/renovation-address.types';

function initState() {
    return {
        customerPM: {},
        customersPM: [],
        loading: true,
        qpvs: [],
    };
}

export default function (state = initState(), action) {
    switch (action.type) {
        case GET_CUSTOMERS_PM_REQUEST:
            return {
                ...state,
                customersPM: action.payload,
                loading: true,
            };
        case CREATE_CUSTOMER_PM_SUCCESS:
            return {
                ...state,
                customerPM: action.payload.customerPM,
            };

        case GET_CUSTOMER_PM_SUCCESS:
            return {
                ...state,
                customerPM: action.payload.customerPM,
            };

        case GET_CUSTOMERS_PM_SUCCESS:
            return {
                ...state,
                customersPM: action.payload.customersPM,
                loading: false,
            };
        case GET_CUSTOMERS_PM_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case PATCH_CUSTOMER_PM_SUCCESS:
            return {
                ...state,
                customerPM: action.payload.customerPM,
            };

        case DELETE_CUSTOMER_PM_SUCCESS:
            return {
                ...state,
                customersPM: state.customersPM.filter((customerPM) => customerPM.uuid !== action.payload.customerPM.uuid),
            };

        case GET_QPVS_SUCCESS:
            return {
                ...state,
                qpvs: action.payload.qpvs,
            };

        default:
            return state;
    }
}
