export const LABEL_CHECKBOX_IS_CUSTOMER_HOUSE_OWNER = 'Le client est propriétaire du logement';

export const LABEL_BUTTON_CREATE_CUSTOMER_PP = 'Créer un compte client particulier';
export const LABEL_BUTTON_UPDATE_CUSTOMER_PP = 'Modifier un compte client particulier';

export const MODAL_TITLE_CREATE_CUSTOMER_PP = 'Créer un compte client particulier';
export const MODAL_TITLE_CREATE_CUSTOMER_PM = 'Créer un compte client professionnel';
export const MODAL_TITLE_CREATE_OCCUPANT = 'Nouvel occupant';

export const MODAL_TITLE_UPDATE_CUSTOMER_PP = 'Modifier un compte particulier';

export const LABEL_MA_PRIME_RENOV = "Profil MaPrimeRénov'";
export const LABEL_IS_CUSTOMER_HOUSE_OWNER = 'Le client est propriétaire du logement.';
