import { v1 as uuidV1 } from 'uuid';

import {
    CREATE_QUOTATION_REQUEST,
    CREATE_QUOTATION_SUCCESS,
    CREATE_QUOTATION_FAILURE,
    GET_QUOTATIONS_REQUEST,
    GET_QUOTATIONS_SUCCESS,
    GET_QUOTATIONS_FAILURE,
    GET_QUOTATION_REQUEST,
    GET_QUOTATION_SUCCESS,
    GET_QUOTATION_FAILURE,
    PATCH_QUOTATION_REQUEST,
    PATCH_QUOTATION_SUCCESS,
    PATCH_QUOTATION_FAILURE,
    ADD_OPERATION,
    UPDATE_OPERATION,
    RESET_OPERATION,
    DELETE_OPERATION,
    ADD_PRODUCT,
    UPDATE_MAIN_PRODUCT,
    UPDATE_OTHER_PRODUCT,
    DELETE_OTHER_PRODUCT,
    DELETE_ALL_OTHER_PRODUCTS,
    UPDATE_OPERATION_INSTALLATION_DATE,
    UPDATE_OPERATION_TECHNICIAN,
    SAVE_QUOTATION_REQUEST,
    SAVE_QUOTATION_SUCCESS,
    SAVE_QUOTATION_FAILURE,
    UPDATE_QUOTATION,
    DELETE_QUOTATION_REQUEST,
    DELETE_QUOTATION_SUCCESS,
    DELETE_QUOTATION_FAILURE,
    GET_QUOTATIONS_ANALYTICS_REQUEST,
    GET_QUOTATIONS_ANALYTICS_SUCCESS,
    GET_QUOTATIONS_ANALYTICS_FAILURE,
    CHECK_QUOTATION_INFORMATION_REDUNDANCY_REQUEST,
    CHECK_QUOTATION_INFORMATION_REDUNDANCY_SUCCESS,
    CHECK_QUOTATION_INFORMATION_REDUNDANCY_FAILURE,
    SEND_QUOTATION_BY_EMAIL_REQUEST,
    SEND_QUOTATION_BY_EMAIL_SUCCESS,
    SEND_QUOTATION_BY_EMAIL_FAILURE,
    SAVE_QUOTATION_AS_DRAFT_REQUEST,
    SAVE_QUOTATION_AS_DRAFT_SUCCESS,
    SAVE_QUOTATION_AS_DRAFT_FAILURE,
    RESET_QUOTATION,
    ADD_MAIN_PRODUCT_VARIATION,
    UPDATE_MAIN_PRODUCT_VARIATION,
    DELETE_MAIN_PRODUCT_VARIATION,
    UPDATE_QUOTATION_PRODUCT_REQUEST,
    UPDATE_QUOTATION_PRODUCT_SUCCESS,
    UPDATE_QUOTATION_PRODUCT_FAILURE,
    CLEAR_QUOTATION,
    REFUSE_QUOTATION_REQUEST,
    REFUSE_QUOTATION_SUCCESS,
    REFUSE_QUOTATION_FAILURE,
    UPDATE_WASTE_MANAGEMENT,
    RESET_QUOTATION_CONTRACT_FAILURE,
    RESET_QUOTATION_CONTRACT_REQUEST,
    RESET_QUOTATION_CONTRACT_SUCCESS,
    SET_QUOTATION_SENT_TO_CUSTOMER_REQUEST,
    SET_QUOTATION_SENT_TO_CUSTOMER_SUCCESS,
    SET_QUOTATION_SENT_TO_CUSTOMER_FAILURE,
    UPDATE_QUOTATION_OPERATIONS,
} from '../types/quotation.types';
import quotationService from '../services/quotation.service';
import productCatalogueService from '../services/product-catalogue.service';
import renovationAddressActions from '../actions/renovation-address.actions';
import { patchQuotationPaths, quotationTechnicianTypes, productTypes } from '../../utils/enums';
import { parseError, isBadRequestError, handleErrorResponse } from '../../api';
import formActions from './ui/form';
import dialogsActions from './ui/dialogs';
import history from '../../utils/history';
import operationHelper, { calculateOperationSavingCertificatePremiumForOneDollarNetPrice } from '../../utils/operation-helper';
import quotationFormHelper from '../../utils/quotation-form-helper';
import productHelper from '../../utils/product-helper';
import { isObjectEmpty } from '../../utils';
import { create } from '../services/waste-collection-depot';
import productFormHelper from '../../utils/product-form-helper';

function createQuotation() {
    return (dispatch) => {
        dispatch({ type: CREATE_QUOTATION_REQUEST });
        dispatch(formActions.startRequesting());

        quotationService
            .createQuotation()
            .then(({ data }) => {
                dispatch(formActions.stopRequesting());
                dispatch({ type: CREATE_QUOTATION_SUCCESS, payload: { quotation: data } });
                history.push(`/devis/${data.uuid}/edition`);
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(formActions.stopRequesting());
                dispatch({ type: CREATE_QUOTATION_FAILURE, payload: { errorMessage } });

                if (isBadRequestError(error) && errorMessage.includes('\n')) {
                    const messages = errorMessage.split('\n');
                    dispatch(dialogsActions.showFailureDialog({ messages }));
                } else dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
}

function getQuotations(query) {
    return (dispatch) => {
        dispatch({ type: GET_QUOTATIONS_REQUEST });
        if (!query.searchString) dispatch(formActions.startRequesting());
        quotationService
            .getQuotations(query)
            .then(({ data }) => {
                dispatch(formActions.stopRequesting());
                dispatch({ type: GET_QUOTATIONS_SUCCESS, payload: { quotations: data } });
            })
            .catch((error) => {
                dispatch(formActions.stopRequesting());
                const errorMessage = parseError(error);
                dispatch({ type: GET_QUOTATIONS_FAILURE, payload: { errorMessage } });
            });
    };
}

function getQuotation({ quotationUUID }) {
    return (dispatch) => {
        dispatch({ type: GET_QUOTATION_REQUEST });
        dispatch(formActions.startRequesting());

        quotationService
            .getQuotation(quotationUUID)
            .then(({ data }) => {
                dispatch({ type: GET_QUOTATION_SUCCESS, payload: { quotation: data } });
                dispatch(formActions.stopRequesting());
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(formActions.stopRequesting());
                dispatch({ type: GET_QUOTATION_FAILURE, payload: { errorMessage } });
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage, redirect: '/devis' }));
            });
    };
}

function patchQuotation({ operation, path, value }) {
    return (dispatch, getState) => {
        dispatch({ type: PATCH_QUOTATION_REQUEST });
        const { uuid } = getState().quotationsState.quotation;

        quotationService
            .patchQuotation(uuid, operation, path, value)
            .then(({ data }) => {
                dispatch({ type: PATCH_QUOTATION_SUCCESS, payload: { quotation: data } });
                if (path === patchQuotationPaths.CUSTOMER) dispatch(renovationAddressActions.getCustomerRenovationAddresses(value));
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch({ type: PATCH_QUOTATION_FAILURE, payload: { errorMessage } });
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
}

function initOperation(idOrganization) {
    return {
        uuid: uuidV1(),
        blockValidity: { valid: false, errorMessages: ["Veuillez sélectionner une fiche d'opération"] },
        technician: {
            technicianType: quotationTechnicianTypes.ORGANIZATION,
            technicianId: idOrganization,
            blockValidity: { valid: true, errorMessages: [] },
        },
        installationDate: {
            renovationStartingDelay: 1,
            blockValidity: { valid: true, errorMessages: [] },
        },
        withoutOtherProducts: true,
        technicalVisit: true,
        customerEnergySavingCertificatePremium: 0,
    };
}

function addOperation() {
    return (dispatch, getState) => {
        const { idOrganization } = getState().quotationsState.quotation;

        dispatch({
            type: ADD_OPERATION,
            payload: { operation: initOperation(idOrganization) },
        });
    };
}

function setTotalNetToOneEuro() {
    return (dispatch, getState) => {
        const { quotation } = getState().quotationsState;
        dispatch({ type: UPDATE_QUOTATION_OPERATIONS, payload: { operations: calculateOperationSavingCertificatePremiumForOneDollarNetPrice(quotation) } });
    };
}

function updateOperation({ operationUUID, updates, additionalRelatedOperations = [] }) {
    return (dispatch, getState) => {
        const { operations } = getState().quotationsState.quotation;

        const foundOperation = operations.find(({ uuid }) => uuid === operationUUID);
        const { operationSheetName } = foundOperation;
        const mainProductUpdates = {};

        mainProductUpdates.unit = operationHelper.getOperationSheetUnitLabel(operationSheetName);

        const mainProduct = productHelper.getMainProduct(foundOperation?.products);

        if (mainProduct && updates.unitValue && foundOperation.unitValue !== updates.unitValue) {
            const mainProductQuantity = mainProduct.quantity;
            mainProductUpdates.quantity = operationHelper.isOperationUnitValueDifferentOfMainProductQuantity(operationSheetName) ? mainProductQuantity : updates.unitValue;
        }
        if (operationHelper.isOperationUnitValueEqualToMainProductQuantity(foundOperation) && updates.unitValue && foundOperation.unitValue !== updates.unitValue) {
            mainProductUpdates.quantity = updates.unitValue;
        }

        dispatch({
            type: UPDATE_OPERATION,
            payload: { operationUUID, updates, mainProductUpdates, additionalRelatedOperations },
        });
    };
}

function resetOperation({ operationUUID, ...operationProperties }) {
    return (dispatch, getState) => {
        const { idOrganization } = getState().quotationsState.quotation;

        dispatch({
            type: RESET_OPERATION,
            payload: { operationUUID, operation: { ...initOperation(idOrganization), ...operationProperties, blockValidity: { valid: false, errorMessages: ['Veuillez compléter le formulaire'] } } },
        });
    };
}

function deleteOperation({ operationUUID }) {
    return {
        type: DELETE_OPERATION,
        payload: { operationUUID },
    };
}

function initProduct(type) {
    const productInitData = {
        uuid: uuidV1(),
        blockValidity: { valid: false, errorMessages: ['Veuillez sélectionner un produit'] },
        product: { type },
        declinations: [],
    };
    if (type === productTypes.OTHER_PRODUCT) productInitData.unit = 'u';

    return productInitData;
}

function addProduct({ operationUUID, type }) {
    return {
        type: ADD_PRODUCT,
        payload: { operationUUID, product: initProduct(type) },
    };
}

function addMainProduct({ operationUUID }) {
    return addProduct({ operationUUID, type: productTypes.MAIN_PRODUCT });
}

function addOtherProduct({ operationUUID }) {
    return addProduct({ operationUUID, type: productTypes.OTHER_PRODUCT });
}

function updateWasteManagement({ wasteManagement }) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_WASTE_MANAGEMENT,
            payload: { wasteManagement },
        });
    };
}

function updateMainProduct({ operationUUID, updates }) {
    return (dispatch, getState) => {
        const { operations } = getState().quotationsState.quotation;
        const { operationSheetName } = operations.find(({ uuid }) => uuid === operationUUID);
        const operationUpdates = {};
        const completedUpdates = updates;
        if (updates.product && updates.product.detailsPerOperation) {
            completedUpdates.price = updates.product.detailsPerOperation[operationSheetName].defaultPrice;
            completedUpdates.vatRate = updates.product.detailsPerOperation[operationSheetName].vatRate;
            completedUpdates.unit = updates.product.unit;
            if (productFormHelper.isProductQuantityEqualToOne(operationSheetName)) {
                completedUpdates.quantity = 1;
                completedUpdates.blockValidity = productFormHelper.validateMainProduct(completedUpdates);
            }
        }
        if (updates.quantity && !operationHelper.isOperationUnitValueDifferentOfMainProductQuantity(operationSheetName)) {
            operationUpdates.unitValue = updates.quantity;
        }

        dispatch({
            type: UPDATE_MAIN_PRODUCT,
            payload: { operationUUID, updates: completedUpdates, operationUpdates },
        });
    };
}

function updateOtherProduct({ operationUUID, productUUID, updates }) {
    const completedUpdates = updates;
    if (updates.product) {
        completedUpdates.price = updates.product.price;
        completedUpdates.vatRate = updates.product.vatRate;
        completedUpdates.unit = updates.product.unit;
    }

    return {
        type: UPDATE_OTHER_PRODUCT,
        payload: { operationUUID, productUUID, updates: completedUpdates },
    };
}

function selectOtherProduct({ operationUUID, productUUID, selectedProduct }) {
    const product = {
        title: selectedProduct.product.title,
        price: selectedProduct.product.price,
        vatRate: selectedProduct.product.vatRate,
        unit: selectedProduct.product.unit,
        ...selectedProduct,
    };
    return updateOtherProduct({ operationUUID, productUUID, updates: product });
}

function resetOtherProduct({ operationUUID, productUUID }) {
    return updateOtherProduct({ operationUUID, productUUID, updates: { product: { type: productTypes.OTHER_PRODUCT } } });
}

function updateLastOtherProduct({ operationUUID, updates }) {
    return (dispatch, getState) => {
        const { operations } = getState().quotationsState.quotation;
        const { products } = operations.find(({ uuid }) => uuid === operationUUID);
        const otherProducts = products.filter(({ product }) => product.type === productTypes.OTHER_PRODUCT);
        const lastOtherProduct = otherProducts[otherProducts.length - 1];

        dispatch(updateOtherProduct({ operationUUID, productUUID: lastOtherProduct.uuid, updates }));
    };
}

function deleteOtherProduct({ operationUUID, productUUID }) {
    return {
        type: DELETE_OTHER_PRODUCT,
        payload: { operationUUID, productUUID },
    };
}

function deleteAllOtherProducts({ operationUUID }) {
    return {
        type: DELETE_ALL_OTHER_PRODUCTS,
        payload: { operationUUID },
    };
}

function updateOperationInstallationDate({ operationUUID, installationDate }) {
    return {
        type: UPDATE_OPERATION_INSTALLATION_DATE,
        payload: { operationUUID, installationDate },
    };
}

function updateOperationTechnician({ operationUUID, technician }) {
    return {
        type: UPDATE_OPERATION_TECHNICIAN,
        payload: { operationUUID, technician },
    };
}

function updateQuotation({ updates }) {
    return {
        type: UPDATE_QUOTATION,
        payload: { updates },
    };
}

function saveWasteCollectionDepot(wasteCollectionDepot) {
    return (dispatch) => {
        dispatch(formActions.startRequesting());
        return create(wasteCollectionDepot)
            .then(({ data }) => {
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showSuccessDialog({ message: 'Votre depot a bien été enregistré' }));
                return data;
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
}
function saveQuotation() {
    return (dispatch, getState) => {
        const { quotation } = getState().quotationsState;
        dispatch({ type: SAVE_QUOTATION_REQUEST });
        dispatch(formActions.startRequesting());

        quotationService
            .saveQuotation(quotation.uuid, quotationFormHelper.formatQuotationForSaving(quotation))
            .then(({ data }) => {
                dispatch(formActions.stopRequesting());
                dispatch({ type: SAVE_QUOTATION_SUCCESS, payload: { quotation: data } });
                dispatch(dialogsActions.showSuccessDialog({ message: 'Votre devis a bien été enregistré' }));
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(formActions.stopRequesting());
                dispatch({ type: SAVE_QUOTATION_FAILURE, payload: { errorMessage } });
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
}

function saveQuotationAsDraft(quotation = {}) {
    return (dispatch, getState) => {
        dispatch({ type: SAVE_QUOTATION_AS_DRAFT_REQUEST });

        let formattedQuotation = quotation;
        if (isObjectEmpty(formattedQuotation)) {
            formattedQuotation = quotationFormHelper.formatQuotationForSaving(getState().quotationsState.quotation);
        }

        const { uuid, ...restOfQuotation } = formattedQuotation;
        quotationService
            .saveQuotationAsDraft(uuid, restOfQuotation)
            .then(({ data }) => {
                dispatch({ type: SAVE_QUOTATION_AS_DRAFT_SUCCESS, payload: { quotation: data } });
            })
            .catch(handleErrorResponse({ dispatch, errorType: SAVE_QUOTATION_AS_DRAFT_FAILURE }));
    };
}

function refuseQuotation({ quotationUUID }) {
    return (dispatch, getState) => {
        dispatch({ type: REFUSE_QUOTATION_REQUEST });
        dispatch(formActions.startRequesting());
        quotationService
            .refuseQuotation(quotationUUID)
            .then(({ data }) => {
                dispatch(formActions.stopRequesting());
                dispatch({ type: REFUSE_QUOTATION_SUCCESS, payload: { quotation: data } });
                dispatch(dialogsActions.showSuccessDialog({ message: 'Le devis a bien été marqué comme refusé' }));
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(formActions.stopRequesting());
                dispatch({ type: REFUSE_QUOTATION_FAILURE, payload: { errorMessage } });
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
}

function deleteQuotation({ quotationUUID }) {
    return (dispatch, getState) => {
        dispatch({ type: DELETE_QUOTATION_REQUEST });
        dispatch(formActions.startRequesting());

        const accountUUID = getState().auth.account.uuid;
        quotationService
            .deleteQuotation(quotationUUID, accountUUID)
            .then(({ data }) => {
                dispatch(formActions.stopRequesting());
                dispatch({ type: DELETE_QUOTATION_SUCCESS, payload: { quotation: data } });
                dispatch(dialogsActions.showSuccessDialog({ message: 'Le devis a bien été supprimé' }));
                if (history.location.pathname !== '/devis') {
                    dispatch({ type: GET_QUOTATIONS_REQUEST });
                    history.push('/devis');
                }
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(formActions.stopRequesting());
                dispatch({ type: DELETE_QUOTATION_FAILURE, payload: { errorMessage } });
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
}

function getQuotationsAnalytics() {
    return (dispatch) => {
        dispatch({ type: GET_QUOTATIONS_ANALYTICS_REQUEST });

        quotationService
            .getQuotationsAnalytics()
            .then(({ data }) => {
                dispatch({ type: GET_QUOTATIONS_ANALYTICS_SUCCESS, payload: { analytics: data } });
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch({ type: GET_QUOTATIONS_ANALYTICS_FAILURE, payload: { errorMessage } });
            });
    };
}

function checkQuotationInformationRedundancy() {
    return (dispatch, getState) => {
        dispatch({ type: CHECK_QUOTATION_INFORMATION_REDUNDANCY_REQUEST });

        const { quotation } = getState().quotationsState;
        quotationService
            .checkQuotationInformationRedundancy(quotation)
            .then(() => {
                dispatch({ type: CHECK_QUOTATION_INFORMATION_REDUNDANCY_SUCCESS });
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch({ type: CHECK_QUOTATION_INFORMATION_REDUNDANCY_FAILURE });
                dispatch(dialogsActions.showConfirmDialog({ message: errorMessage }));
            });
    };
}

function sendQuotationByEmail({ quotationUUID, sendingData }) {
    return (dispatch) => {
        dispatch({ type: SEND_QUOTATION_BY_EMAIL_REQUEST });
        dispatch(formActions.startRequesting());

        quotationService
            .sendQuotationByEmail(quotationUUID, sendingData)
            .then(({ data }) => {
                dispatch(getQuotationsAnalytics());
                dispatch({ type: SEND_QUOTATION_BY_EMAIL_SUCCESS, payload: { quotation: data } });
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showSuccessDialog({ message: 'Votre devis a bien été envoyé' }));
                history.push(`/devis/${data.uuid}/apercu`);
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(formActions.stopRequesting());
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
                dispatch({ type: SEND_QUOTATION_BY_EMAIL_FAILURE, payload: { errorMessage } });
            });
    };
}

function resetQuotation() {
    return { type: RESET_QUOTATION };
}

function addMainProductVariation({ operationUUID }) {
    return { type: ADD_MAIN_PRODUCT_VARIATION, payload: { operationUUID, variation: { uuid: uuidV1() } } };
}

function updateMainProductVariation({ operationUUID, variationUUID, updates = {} } = {}) {
    return { type: UPDATE_MAIN_PRODUCT_VARIATION, payload: { operationUUID, variationUUID, updates } };
}

function deleteMainProductVariation({ operationUUID, variationUUID } = {}) {
    return { type: DELETE_MAIN_PRODUCT_VARIATION, payload: { operationUUID, variationUUID } };
}

function updateQuotationProduct({ productUUID, updates, onSuccess }) {
    return (dispatch) => {
        dispatch({ type: UPDATE_QUOTATION_PRODUCT_REQUEST });
        dispatch(formActions.startRequesting());

        return productCatalogueService
            .updateProduct(productUUID, updates)
            .then(({ data: product }) => {
                onSuccess();
                dispatch({ type: UPDATE_QUOTATION_PRODUCT_SUCCESS, payload: { product } });
                dispatch(formActions.stopRequesting());
            })
            .catch(handleErrorResponse({ dispatch, errorType: UPDATE_QUOTATION_PRODUCT_FAILURE }));
    };
}

function resetQuotationContract() {
    return (dispatch, getState) => {
        const { quotation } = getState().quotationsState;
        dispatch({ type: RESET_QUOTATION_CONTRACT_REQUEST });

        quotationService
            .resetQuotationContract(quotation.uuid)
            .then(({ data }) => {
                dispatch({ type: RESET_QUOTATION_CONTRACT_SUCCESS, payload: { quotation: data } });
            })
            .catch(handleErrorResponse({ dispatch, errorType: RESET_QUOTATION_CONTRACT_FAILURE, showErrorDialog: false }));
    };
}

function setQuotationSentToCustomer({ quotationUUID }) {
    return (dispatch) => {
        dispatch({ type: SET_QUOTATION_SENT_TO_CUSTOMER_REQUEST });
        dispatch(formActions.startRequesting());
        quotationService
            .setQuotationSentToCustomer(quotationUUID)
            .then(({ data }) => {
                dispatch(formActions.stopRequesting());
                dispatch({ type: SET_QUOTATION_SENT_TO_CUSTOMER_SUCCESS, payload: { quotation: data } });
                dispatch(dialogsActions.showSuccessDialog({ message: 'Le devis a bien été marqué comme envoyé à votre client.' }));
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(formActions.stopRequesting());
                dispatch({ type: SET_QUOTATION_SENT_TO_CUSTOMER_FAILURE, payload: { errorMessage } });
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
}

function clearQuotation() {
    return { type: CLEAR_QUOTATION };
}

export default {
    createQuotation,
    getQuotations,
    getQuotation,
    patchQuotation,
    addOperation,
    updateOperation,
    resetOperation,
    deleteOperation,
    addMainProduct,
    addOtherProduct,
    updateMainProduct,
    updateOtherProduct,
    updateLastOtherProduct,
    deleteOtherProduct,
    deleteAllOtherProducts,
    updateOperationInstallationDate,
    updateOperationTechnician,
    updateQuotation,
    saveQuotation,
    deleteQuotation,
    getQuotationsAnalytics,
    resetOtherProduct,
    checkQuotationInformationRedundancy,
    selectOtherProduct,
    sendQuotationByEmail,
    saveQuotationAsDraft,
    setTotalNetToOneEuro,
    resetQuotation,
    saveWasteCollectionDepot,
    updateWasteManagement,
    addMainProductVariation,
    updateMainProductVariation,
    deleteMainProductVariation,
    updateQuotationProduct,
    clearQuotation,
    refuseQuotation,
    resetQuotationContract,
    setQuotationSentToCustomer,
};
