export default [
    {
        property: 'operationSheetName',
        label: 'Opération',
        type: 'string',
        required: true,
    },
    {
        property: 'defaultPrice',
        label: 'Prix',
        type: 'number',
        required: true,
    },
    {
        property: 'vatRate',
        label: 'TVA',
        type: 'number',
        required: true,
    },
];
