const departments = [
    {
        number: '01',
        climatZone: 'H1',
        RGPE: 55 / 100,
        RPE: 88 / 100,
    },
    {
        number: '02',
        climatZone: 'H1',
        RGPE: 63 / 100,
        RPE: 91 / 100,
    },
    {
        number: '03',
        climatZone: 'H1',
        RGPE: 67 / 100,
        RPE: 93 / 100,
    },
    {
        number: '04',
        climatZone: 'H2',
        RGPE: 67 / 100,
        RPE: 92 / 100,
    },
    {
        number: '05',
        climatZone: 'H1',
        RGPE: 53 / 100,
        RPE: 88 / 100,
    },
    {
        number: '06',
        climatZone: 'H3',
        RGPE: 43 / 100,
        RPE: 80 / 100,
    },
    {
        number: '07',
        climatZone: 'H2',
        RGPE: 67 / 100,
        RPE: 94 / 100,
    },
    {
        number: '08',
        climatZone: 'H1',
        RGPE: 64 / 100,
        RPE: 93 / 100,
    },
    {
        number: '09',
        climatZone: 'H2',
        RGPE: 74 / 100,
        RPE: 95 / 100,
    },
    {
        number: '10',
        climatZone: 'H1',
        RGPE: 63 / 100,
        RPE: 92 / 100,
    },
    {
        number: '11',
        climatZone: 'H3',
        RGPE: 74 / 100,
        RPE: 95 / 100,
    },
    {
        number: '12',
        climatZone: 'H2',
        RGPE: 67 / 100,
        RPE: 94 / 100,
    },
    {
        number: '13',
        climatZone: 'H3',
        RGPE: 61 / 100,
        RPE: 89 / 100,
    },
    {
        number: '14',
        climatZone: 'H1',
        RGPE: 60 / 100,
        RPE: 91 / 100,
    },
    {
        number: '15',
        climatZone: 'H1',
        RGPE: 65 / 100,
        RPE: 93 / 100,
    },
    {
        number: '16',
        climatZone: 'H2',
        RGPE: 70 / 100,
        RPE: 93 / 100,
    },
    {
        number: '17',
        climatZone: 'H2',
        RGPE: 67 / 100,
        RPE: 93 / 100,
    },
    {
        number: '18',
        climatZone: 'H2',
        RGPE: 61 / 100,
        RPE: 91 / 100,
    },
    {
        number: '19',
        climatZone: 'H1',
        RGPE: 68 / 100,
        RPE: 93 / 100,
    },
    {
        number: '21',
        climatZone: 'H1',
        RGPE: 58 / 100,
        RPE: 90 / 100,
    },
    {
        number: '22',
        climatZone: 'H2',
        RGPE: 71 / 100,
        RPE: 95 / 100,
    },
    {
        number: '23',
        climatZone: 'H1',
        RGPE: 65 / 100,
        RPE: 92 / 100,
    },
    {
        number: '24',
        climatZone: 'H2',
        RGPE: 67 / 100,
        RPE: 93 / 100,
    },
    {
        number: '25',
        climatZone: 'H1',
        RGPE: 64 / 100,
        RPE: 91 / 100,
    },
    {
        number: '26',
        climatZone: 'H2',
        RGPE: 70 / 100,
        RPE: 94 / 100,
    },
    {
        number: '27',
        climatZone: 'H1',
        RGPE: 59 / 100,
        RPE: 90 / 100,
    },
    {
        number: '28',
        climatZone: 'H1',
        RGPE: 57 / 100,
        RPE: 87 / 100,
    },
    {
        number: '29',
        climatZone: 'H2',
        RGPE: 69 / 100,
        RPE: 95 / 100,
    },
    {
        number: '30',
        climatZone: 'H3',
        RGPE: 77 / 100,
        RPE: 95 / 100,
    },
    {
        number: '31',
        climatZone: 'H2',
        RGPE: 63 / 100,
        RPE: 90 / 100,
    },
    {
        number: '32',
        climatZone: 'H2',
        RGPE: 64 / 100,
        RPE: 91 / 100,
    },
    {
        number: '33',
        climatZone: 'H2',
        RGPE: 64 / 100,
        RPE: 88 / 100,
    },
    {
        number: '34',
        climatZone: 'H3',
        RGPE: 68 / 100,
        RPE: 93 / 100,
    },
    {
        number: '35',
        climatZone: 'H2',
        RGPE: 61 / 100,
        RPE: 92 / 100,
    },
    {
        number: '36',
        climatZone: 'H2',
        RGPE: 61 / 100,
        RPE: 92 / 100,
    },
    {
        number: '37',
        climatZone: 'H2',
        RGPE: 67 / 100,
        RPE: 93 / 100,
    },
    {
        number: '38',
        climatZone: 'H1',
        RGPE: 60 / 100,
        RPE: 90 / 100,
    },
    {
        number: '39',
        climatZone: 'H1',
        RGPE: 64 / 100,
        RPE: 91 / 100,
    },
    {
        number: '40',
        climatZone: 'H2',
        RGPE: 64 / 100,
        RPE: 92 / 100,
    },
    {
        number: '41',
        climatZone: 'H2',
        RGPE: 61 / 100,
        RPE: 92 / 100,
    },
    {
        number: '42',
        climatZone: 'H1',
        RGPE: 63 / 100,
        RPE: 92 / 100,
    },
    {
        number: '43',
        climatZone: 'H1',
        RGPE: 68 / 100,
        RPE: 93 / 100,
    },
    {
        number: '44',
        climatZone: 'H2',
        RGPE: 62 / 100,
        RPE: 91 / 100,
    },
    {
        number: '45',
        climatZone: 'H1',
        RGPE: 61 / 100,
        RPE: 91 / 100,
    },
    {
        number: '46',
        climatZone: 'H2',
        RGPE: 70 / 100,
        RPE: 94 / 100,
    },
    {
        number: '47',
        climatZone: 'H2',
        RGPE: 72 / 100,
        RPE: 94 / 100,
    },
    {
        number: '48',
        climatZone: 'H2',
        RGPE: 59 / 100,
        RPE: 89 / 100,
    },
    {
        number: '49',
        climatZone: 'H2',
        RGPE: 56 / 100,
        RPE: 87 / 100,
    },
    {
        number: '50',
        climatZone: 'H2',
        RGPE: 59 / 100,
        RPE: 90 / 100,
    },
    {
        number: '51',
        climatZone: 'H1',
        RGPE: 51 / 100,
        RPE: 85 / 100,
    },
    {
        number: '52',
        climatZone: 'H1',
        RGPE: 60 / 100,
        RPE: 90 / 100,
    },
    {
        number: '53',
        climatZone: 'H2',
        RGPE: 60 / 100,
        RPE: 92 / 100,
    },
    {
        number: '54',
        climatZone: 'H1',
        RGPE: 60 / 100,
        RPE: 89 / 100,
    },
    {
        number: '55',
        climatZone: 'H1',
        RGPE: 67 / 100,
        RPE: 92 / 100,
    },
    {
        number: '56',
        climatZone: 'H2',
        RGPE: 60 / 100,
        RPE: 90 / 100,
    },
    {
        number: '57',
        climatZone: 'H1',
        RGPE: 61 / 100,
        RPE: 89 / 100,
    },
    {
        number: '58',
        climatZone: 'H1',
        RGPE: 63 / 100,
        RPE: 92 / 100,
    },
    {
        number: '59',
        climatZone: 'H1',
        RGPE: 63 / 100,
        RPE: 92 / 100,
    },
    {
        number: '60',
        climatZone: 'H1',
        RGPE: 55 / 100,
        RPE: 87 / 100,
    },
    {
        number: '61',
        climatZone: 'H1',
        RGPE: 63 / 100,
        RPE: 91 / 100,
    },
    {
        number: '62',
        climatZone: 'H1',
        RGPE: 66 / 100,
        RPE: 93 / 100,
    },
    {
        number: '63',
        climatZone: 'H1',
        RGPE: 59 / 100,
        RPE: 90 / 100,
    },
    {
        number: '64',
        climatZone: 'H2',
        RGPE: 60 / 100,
        RPE: 90 / 100,
    },
    {
        number: '65',
        climatZone: 'H2',
        RGPE: 66 / 100,
        RPE: 93 / 100,
    },
    {
        number: '66',
        climatZone: 'H3',
        RGPE: 72 / 100,
        RPE: 94 / 100,
    },
    {
        number: '67',
        climatZone: 'H1',
        RGPE: 61 / 100,
        RPE: 89 / 100,
    },
    {
        number: '68',
        climatZone: 'H1',
        RGPE: 61 / 100,
        RPE: 90 / 100,
    },
    {
        number: '69',
        climatZone: 'H1',
        RGPE: 58 / 100,
        RPE: 89 / 100,
    },
    {
        number: '70',
        climatZone: 'H1',
        RGPE: 66 / 100,
        RPE: 93 / 100,
    },
    {
        number: '71',
        climatZone: 'H1',
        RGPE: 61 / 100,
        RPE: 91 / 100,
    },
    {
        number: '72',
        climatZone: 'H2',
        RGPE: 61 / 100,
        RPE: 92 / 100,
    },
    {
        number: '73',
        climatZone: 'H1',
        RGPE: 53 / 100,
        RPE: 87 / 100,
    },
    {
        number: '74',
        climatZone: 'H1',
        RGPE: 52 / 100,
        RPE: 85 / 100,
    },
    {
        number: '75',
        climatZone: 'H1',
        RGPE: 51 / 100,
        RPE: 80 / 100,
    },
    {
        number: '76',
        climatZone: 'H1',
        RGPE: 54 / 100,
        RPE: 87 / 100,
    },
    {
        number: '77',
        climatZone: 'H1',
        RGPE: 62 / 100,
        RPE: 92 / 100,
    },
    {
        number: '78',
        climatZone: 'H1',
        RGPE: 53 / 100,
        RPE: 87 / 100,
    },
    {
        number: '79',
        climatZone: 'H2',
        RGPE: 62 / 100,
        RPE: 93 / 100,
    },
    {
        number: '80',
        climatZone: 'H1',
        RGPE: 64 / 100,
        RPE: 91 / 100,
    },
    {
        number: '81',
        climatZone: 'H2',
        RGPE: 74 / 100,
        RPE: 96 / 100,
    },
    {
        number: '82',
        climatZone: 'H2',
        RGPE: 77 / 100,
        RPE: 96 / 100,
    },
    {
        number: '83',
        climatZone: 'H3',
        RGPE: 62 / 100,
        RPE: 90 / 100,
    },
    {
        number: '84',
        climatZone: 'H2',
        RGPE: 70 / 100,
        RPE: 94 / 100,
    },
    {
        number: '85',
        climatZone: 'H2',
        RGPE: 63 / 100,
        RPE: 94 / 100,
    },
    {
        number: '86',
        climatZone: 'H2',
        RGPE: 65 / 100,
        RPE: 92 / 100,
    },
    {
        number: '87',
        climatZone: 'H1',
        RGPE: 63 / 100,
        RPE: 92 / 100,
    },
    {
        number: '88',
        climatZone: 'H1',
        RGPE: 62 / 100,
        RPE: 91 / 100,
    },
    {
        number: '89',
        climatZone: 'H1',
        RGPE: 68 / 100,
        RPE: 93 / 100,
    },
    {
        number: '90',
        climatZone: 'H1',
        RGPE: 64 / 100,
        RPE: 90 / 100,
    },
    {
        number: '91',
        climatZone: 'H1',
        RGPE: 55 / 100,
        RPE: 89 / 100,
    },
    {
        number: '92',
        climatZone: 'H1',
        RGPE: 46 / 100,
        RPE: 82 / 100,
    },
    {
        number: '93',
        climatZone: 'H1',
        RGPE: 62 / 100,
        RPE: 90 / 100,
    },
    {
        number: '94',
        climatZone: 'H1',
        RGPE: 53 / 100,
        RPE: 86 / 100,
    },
    {
        number: '95',
        climatZone: 'H1',
        RGPE: 58 / 100,
        RPE: 89 / 100,
    },
    {
        number: '971',
        climatZone: 'H3',
        RGPE: 80 / 100,
        RPE: 94 / 100,
        ZNI: true,
        bonificationAsZNI: true,
    },
    {
        number: '972',
        climatZone: 'H3',
        RGPE: 80 / 100,
        RPE: 94 / 100,
        ZNI: true,
        bonificationAsZNI: true,
    },
    {
        number: '973',
        climatZone: 'H3',
        RGPE: 80 / 100,
        RPE: 94 / 100,
        ZNI: true,
        bonificationAsZNI: true,
    },
    {
        number: '974',
        climatZone: 'H3',
        RGPE: 80 / 100,
        RPE: 94 / 100,
        ZNI: true,
        bonificationAsZNI: true,
    },
    {
        number: '976',
        climatZone: 'H3',
        RGPE: 80 / 100,
        RPE: 94 / 100,
        ZNI: true,
        bonificationAsZNI: true,
    },
    {
        number: '2A', // corse - Ajaccio - corse du sud
        climatZone: 'H3',
        RGPE: 59 / 100,
        RPE: 87 / 100,
        bonificationAsZNI: true,
    },
    {
        number: '2B', // corse - Bastia - haute corse
        climatZone: 'H3',
        RGPE: 63 / 100,
        RPE: 89 / 100,
        bonificationAsZNI: true,
    },
    {
        number: '200', // corse - Ajaccio sud 200XX => same as 2A
        climatZone: 'H3',
        RGPE: 59 / 100,
        RPE: 87 / 100,
        bonificationAsZNI: true,
    },
    {
        number: '201', // corse - sud 201XX => same as 2A
        climatZone: 'H3',
        RGPE: 59 / 100,
        RPE: 87 / 100,
        bonificationAsZNI: true,
    },
    {
        number: '202', // corse - nord 202XX => same as 2B
        climatZone: 'H3',
        RGPE: 63 / 100,
        RPE: 89 / 100,
        bonificationAsZNI: true,
    },
];

export const getDepartmentByPostalCode = (postalCode) => {
    const postalShortMetropol = postalCode.toString().substring(0, 2);
    const postalShortZNI = postalCode.toString().substring(0, 3);

    return departments.find((department) => department.number === postalShortMetropol || department.number === postalShortZNI);
};
