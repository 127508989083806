import { isNullishOrEmpty } from '..';

export default [
    {
        property: 'operations',
        type: 'array',
        validate({ operations }, formErrors) {
            if (isNullishOrEmpty(operations)) {
                formErrors.operations = 'Vous devez sélectionner au moins une opération';
                return false;
            }
            if (operations.some(isNullishOrEmpty)) {
                formErrors.operations = 'Veuillez compléter toutes vos opérations';
                return false;
            }

            return true;
        },
    },
];
