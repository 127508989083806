import React, { useEffect } from 'react';
import { BrowserRouter, Router, Switch, Route } from 'react-router-dom';
import loadable from '@loadable/component';
import { useSelector, useDispatch } from 'react-redux';

import history from '../utils/history';
import { publicRoutes } from '../routes';
import formActions from '../store/actions/ui/form';

import TypedRoute from './helpers/TypedRoute';

const Layout = loadable(() => import('./layout/Layout'));

export default function App() {
    const dispatch = useDispatch();
    const { errorMessage } = useSelector((state) => state.ui.form);

    useEffect(() => {
        const clickListener = document.addEventListener('click', () => {
            if (errorMessage) dispatch(formActions.resetForm());
        });

        return () => document.removeEventListener('click', clickListener);
    }, [errorMessage, dispatch]);

    return (
        <BrowserRouter>
            <Router history={history}>
                <Switch>
                    {publicRoutes.map((publicRoute, index) => (
                        <TypedRoute type='public' key={index} path={publicRoute.path} component={publicRoute.component} exact={!!publicRoute.exact} />
                    ))}
                    <Route path='/' component={Layout} />
                </Switch>
            </Router>
        </BrowserRouter>
    );
}
