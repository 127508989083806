import { productSubCategories } from '../../enums';
import { LABEL_INPUT_NOMINAL_POWER, LABEL_INPUT_SEASONAL_PERFORMANCE_COEFFICIENT } from '../../form_labels';
import { LABEL_EFFICACITE_ENERGETIQUE, LABEL_NOMINAL_POWER, LABEL_SEASONAL_PERFORMANCE_COEFFICIENT, LABEL_TYPE_FONCTIONNEMENT } from '../../product-constants';

export default [
    {
        property: LABEL_EFFICACITE_ENERGETIQUE,
        label: 'Efficacité énergétique saisonnière selon le règlement (EU) n°813/2013 de la commission du 2 août 2013',
        type: 'number',
        requiredIf({ subCategory }) {
            return [
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_EAU,
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_EAU_EAU,
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_HYBRIDE,
            ].includes(subCategory);
        },
    },
    {
        property: LABEL_TYPE_FONCTIONNEMENT,
        label: 'Type de fonctionnement',
        type: 'string',
        requiredIf({ subCategory }) {
            return [
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_EAU,
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_EAU_EAU,
                productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_HYBRIDE,
            ].includes(subCategory);
        },
    },
    {
        property: LABEL_SEASONAL_PERFORMANCE_COEFFICIENT,
        label: LABEL_INPUT_SEASONAL_PERFORMANCE_COEFFICIENT,
        type: 'string',
        requiredIf({ subCategory }) {
            return subCategory === productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_AIR;
        },
    },
    {
        property: LABEL_NOMINAL_POWER,
        label: LABEL_INPUT_NOMINAL_POWER,
        type: 'string',
        requiredIf({ subCategory }) {
            return subCategory === productSubCategories.POMPE_CHALEUR.POMPE_CHALEUR_AIR_AIR;
        },
    },
];
