import {
    AUTOCOMPLETE_ADDRESS_REQUEST,
    AUTOCOMPLETE_ADDRESS_SUCCESS,
    AUTOCOMPLETE_ADDRESS_FAILURE,
    GET_PLACE_DETAILS_SUCCESS,
    GET_PLACE_DETAILS_FAILURE,
    CLEAR_ADDRESS_FINDER_DATA,
} from '../types/address-finder.types';

const initState = () => ({});

export default (state = initState(), action) => {
    switch (action.type) {
        case AUTOCOMPLETE_ADDRESS_REQUEST:
            return {
                ...state,
                [action.payload.instanceKey]: {
                    loading: true,
                    places: Object.keys(state).includes(action.payload.instanceKey) ? state[action.payload.instanceKey].places : [],
                },
            };
        case AUTOCOMPLETE_ADDRESS_SUCCESS:
            return {
                ...state,
                [action.payload.instanceKey]: {
                    loading: false,
                    places: action.payload.places,
                },
            };
        case AUTOCOMPLETE_ADDRESS_FAILURE:
            return {
                ...state,
                [action.payload.instanceKey]: {
                    loading: false,
                    places: Object.keys(state).includes(action.payload.instanceKey) ? state[action.payload.instanceKey].places : [],
                },
            };
        case GET_PLACE_DETAILS_SUCCESS:
            return {
                ...state,
                [action.payload.instanceKey]: Object.keys(state).includes(action.payload.instanceKey)
                    ? {
                          loading: state[action.payload.instanceKey].loading,
                          places: state[action.payload.instanceKey].places,
                          placeDetails: action.payload.placeDetails,
                      }
                    : {},
            };
        case GET_PLACE_DETAILS_FAILURE:
            return {
                ...state,
                [action.payload.instanceKey]: Object.keys(state).includes(action.payload.instanceKey)
                    ? {
                          loading: state[action.payload.instanceKey].loading,
                          places: state[action.payload.instanceKey].places,
                      }
                    : {},
            };

        case CLEAR_ADDRESS_FINDER_DATA:
            return initState();
        default:
            return state;
    }
};
