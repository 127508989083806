import { START_REQUESTING, STOP_REQUESTING, RESET_FORM } from '../../types/ui/form';

const initState = () => ({
    errorMessage: '',
    requesting: false,
    loading: false,
});

export default (state = initState(), action) => {
    switch (action.type) {
        case START_REQUESTING:
            return {
                requesting: true,
                loading: !(action.payload && action.payload.fromPublicRoute),
            };
        case STOP_REQUESTING:
            return {
                errorMessage: action.payload.errorMessage,
                successMessage: action.payload.successMessage,
                requesting: false,
                loading: false,
            };
        case RESET_FORM:
            return state.errorMessage || state.successMessage ? initState() : state;
        default:
            return state;
    }
};
