import { flow } from 'lodash';
import moment from 'moment';
import momentUtils from '../../../../../../utils/date_utils/moment/index';
import { contractAmendmentStatus } from '../../../../../../utils/enums';

export const getAmendmentAtDate = (date) => (contractAmendments) => contractAmendments.find(({ startDate, endDate }) => momentUtils.isSameOrBetween(date, startDate, endDate));
export const getConfirmedAmendment = (contractAmendments) => contractAmendments.filter(({ status }) => status === contractAmendmentStatus.CONFIRMED);

export const getActiveAmendment = flow([getConfirmedAmendment, getAmendmentAtDate(moment())]);

export const formatQuotationContractSelectorName = ({ reference, delegateeName, contractAmendments }) => {
    const amendment = getActiveAmendment(contractAmendments);
    return amendment ? `${delegateeName} - ${reference} - ${amendment.reference}` : `${delegateeName} - ${reference}`;
};
