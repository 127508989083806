import { endpointV2, authHeaders, getAuthHeadersWithJSONContentType } from '../../api';
import { REFRESH_ROUTE } from '../../api/routes';
import storage from '../../utils/storage';

function getInvitationsBaseUrl() {
    return `/organizations/${storage.getOrganizationUUID()}/invitations`;
}

const createOrganization = (uuidAccount, organization) =>
    endpointV2.post('/organizations', organization, {
        headers: { 'Content-Type': 'application/json', ...authHeaders() },
        params: {
            uuidAccount,
        },
    });

const getOrganization = (uuidOrganization, params = {}) =>
    endpointV2.get(`/organizations/${uuidOrganization}`, {
        headers: authHeaders(),
        params,
    });

const updateOrganization = (uuidOrganization, organization) =>
    endpointV2.put(`/organizations/${uuidOrganization}`, organization, {
        headers: { 'Content-Type': 'application/json', ...authHeaders() },
    });

const patchOrganizationWithJsonObject = async (uuidAccount, uuidOrganization, operationName, path, value = {}) =>
    endpointV2.patch(
        `/organizations/${uuidOrganization}`,
        {
            op: operationName,
            path,
            value,
        },
        {
            headers: { 'Content-Type': 'application/json', ...authHeaders() },
            params: {
                uuidAccount,
            },
        }
    );

const patchOrganizationWithFile = async (uuidAccount, uuidOrganization, formData) =>
    endpointV2.patch(`/organizations/${uuidOrganization}`, formData, {
        headers: authHeaders(),
        params: {
            uuidAccount,
        },
    });

const updateOrganizationOnboarding = async (uuidOrganization, values) =>
    endpointV2.patch(`/organizations/${uuidOrganization}`, values, {
        headers: authHeaders(),
    });

async function deleteInvitation(invitationUUID, accountUUID) {
    return endpointV2.delete(`${getInvitationsBaseUrl()}/${invitationUUID}`, { headers: authHeaders(), params: { uuidAccount: accountUUID } });
}

async function refreshOrganizationInformation(uuidOrganization) {
    return endpointV2.put(
        `/organizations/${uuidOrganization}`.concat(REFRESH_ROUTE),
        {},
        {
            headers: getAuthHeadersWithJSONContentType(),
        }
    );
}

export default {
    createOrganization,
    getOrganization,
    updateOrganization,
    patchOrganizationWithJsonObject,
    patchOrganizationWithFile,
    updateOrganizationOnboarding,
    deleteInvitation,
    refreshOrganizationInformation,
};
