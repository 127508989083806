import {
    CREATE_CONTRACT_SUCCESS,
    CREATE_CONTRACT_FAILURE,
    GET_CONTRACTS_SUCCESS,
    GET_CONTRACTS_FAILURE,
    DELETE_CONTRACT_SUCCESS,
    DELETE_CONTRACT_FAILURE,
    PATCH_CONTRACT_SUCCESS,
    PATCH_CONTRACT_FAILURE,
    GET_CONTRACTS_ANALYTICS_SUCCESS,
    GET_CONTRACTS_ANALYTICS_FAILURE,
    GET_CONTRACTS_REQUEST,
    GET_CONTRACT_SUCCESS,
    GET_CONTRACT_FAILURE,
} from '../types/contracts.types';

const initState = () => ({
    contracts: null,
    contract: null,
    loading: true,
    analytics: {
        contracts: {},
    },
});

export default (state = initState(), action) => {
    switch (action.type) {
        case GET_CONTRACTS_REQUEST:
            return {
                ...state,
                contracts: action.payload,
                loading: true,
            };
        case CREATE_CONTRACT_SUCCESS:
            return {
                ...state,
            };
        case CREATE_CONTRACT_FAILURE: {
            return {
                ...state,
                error: action.error,
            };
        }
        case GET_CONTRACTS_SUCCESS:
            return {
                ...state,
                contracts: action.payload,
                loading: false,
            };
        case GET_CONTRACTS_FAILURE: {
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        }
        case GET_CONTRACT_SUCCESS:
            return {
                ...state,
                contract: action.payload,
            };
        case GET_CONTRACT_FAILURE:
            return {
                ...state,
                contract: null,
            };
        case DELETE_CONTRACT_SUCCESS:
            return {
                ...state,
                contracts: action.payload,
            };
        case DELETE_CONTRACT_FAILURE: {
            return {
                ...state,
                error: action.error,
            };
        }
        case PATCH_CONTRACT_SUCCESS: {
            return {
                ...state,
            };
        }
        case PATCH_CONTRACT_FAILURE: {
            return {
                ...state,
                error: action.error,
            };
        }
        case GET_CONTRACTS_ANALYTICS_SUCCESS:
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    contracts: action.payload,
                },
            };
        case GET_CONTRACTS_ANALYTICS_FAILURE:
            return {
                ...state,
                analytics: {
                    ...state.analytics,
                    contracts: {
                        totalPremiumsSent: 0,
                        totalVolumeFinalized: 0,
                    },
                },
            };
        default:
            return state;
    }
};
