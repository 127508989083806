export const CREATE_CUSTOMER_PP_REQUEST = 'CREATE_CUSTOMER_PP_REQUEST';
export const CREATE_CUSTOMER_PP_SUCCESS = 'CREATE_CUSTOMER_PP_SUCCESS';
export const CREATE_CUSTOMER_PP_FAILURE = 'CREATE_CUSTOMER_PP_FAILURE';

export const GET_CUSTOMER_PP_REQUEST = 'GET_CUSTOMER_PP_REQUEST';
export const GET_CUSTOMER_PP_SUCCESS = 'GET_CUSTOMER_PP_SUCCESS';
export const GET_CUSTOMER_PP_FAILURE = 'GET_CUSTOMER_PP_FAILURE';

export const GET_CUSTOMERS_PP_REQUEST = 'GET_CUSTOMERS_PP_REQUEST';
export const GET_CUSTOMERS_PP_SUCCESS = 'GET_CUSTOMERS_PP_SUCCESS';
export const GET_CUSTOMERS_PP_FAILURE = 'GET_CUSTOMERS_PP_FAILURE';

export const UPDATE_CUSTOMER_PP_REQUEST = 'UPDATE_CUSTOMER_PP_REQUEST';
export const UPDATE_CUSTOMER_PP_SUCCESS = 'UPDATE_CUSTOMER_PP_SUCCESS';
export const UPDATE_CUSTOMER_PP_FAILURE = 'UPDATE_CUSTOMER_PP_FAILURE';

export const DELETE_CUSTOMER_PP_REQUEST = 'DELETE_CUSTOMER_PP_REQUEST';
export const DELETE_CUSTOMER_PP_SUCCESS = 'DELETE_CUSTOMER_PP_SUCCESS';
export const DELETE_CUSTOMER_PP_FAILURE = 'DELETE_CUSTOMER_PP_FAILURE';
