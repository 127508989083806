function includesProperty(object, propertyName) {
    return Object.keys(object).includes(propertyName);
}

function isValueValid(value) {
    let valid = true;
    if (typeof value !== 'boolean' && !value) valid = false;

    return valid;
}

function assignDefaultValuesInTarget(target, descriptor) {
    descriptor.forEach((propertyDescriptor) => {
        if (includesProperty(propertyDescriptor, 'defaultValue') && !includesProperty(target, propertyDescriptor.name)) {
            Object.assign(target, { [propertyDescriptor.name]: propertyDescriptor.defaultValue });
        }
    });
}

function validatePropertyAsRequiredInTarget(target, { required, name }) {
    let validPropertyAsRequired = true;
    if (required && (!includesProperty(target, name) || !isValueValid(target[name]))) validPropertyAsRequired = false;

    return validPropertyAsRequired;
}

function validatePropertyAsRequiredValueInTarget(target, { requiredValue, name }) {
    let validPropertyAsRequired = true;
    if (requiredValue !== undefined && target[name] !== requiredValue) validPropertyAsRequired = false;

    return validPropertyAsRequired;
}

function validatePropertiesInTarget(target, descriptor) {
    let validTarget = true;

    descriptor.forEach((propertyDescriptor) => {
        let validProperty = validatePropertyAsRequiredInTarget(target, propertyDescriptor);
        validProperty = validProperty && validatePropertyAsRequiredValueInTarget(target, propertyDescriptor);
        validTarget = validTarget && validProperty;
    });

    return validTarget;
}

export default function validateObjectTarget(target, descriptor, descriptorData) {
    const newTarget = { ...target };
    assignDefaultValuesInTarget(newTarget, descriptor);

    const validTarget = validatePropertiesInTarget(newTarget, descriptor, descriptorData);

    return { newTarget, validTarget };
}
