import { isValidValue } from '.';
import tokenUtils from './token_utils';

// storage keys
export const TOKEN_KEY = 'token_key';
export const ORGANIZATION_UUID_KEY = 'ORGANIZATION_UUID_KEY';

function isAuthenticate() {
    return isValidValue(getToken());
}

function setToken({ token } = {}) {
    localStorage.setItem(TOKEN_KEY, token);
}

function getToken() {
    return localStorage.getItem(TOKEN_KEY);
}

function isComplete() {
    return isValidValue(getOrganizationUUID());
}

function setOrganizationUUID({ organizationUUID } = {}) {
    return localStorage.setItem(ORGANIZATION_UUID_KEY, organizationUUID);
}

function getOrganizationUUID() {
    return localStorage.getItem(ORGANIZATION_UUID_KEY);
}

// Clear
function clearAll() {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(ORGANIZATION_UUID_KEY);
}

function getAccountId() {
    const token = getToken();
    if (!token) return null;

    const payload = tokenUtils.decodeToken(token);

    return payload.sub;
}

// Testing
function setOrganizationAsCompleted() {
    setOrganizationUUID({ organizationUUID: process.env.REACT_APP_ORGANIZATION_UUID });
}

function setAccountAsLoggedIn() {
    setToken({ token: process.env.REACT_APP_TOKEN });
}

export default {
    isAuthenticate,
    setToken,
    getToken,
    clearAll,
    getAccountId,
    isComplete,
    setOrganizationUUID,
    getOrganizationUUID,
    setOrganizationAsCompleted,
    setAccountAsLoggedIn,
};
