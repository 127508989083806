import { endpointV2, authHeaders, getAuthHeadersWithJSONContentType } from '../../api';
import storage from '../../utils/storage';

function getBaseUrl() {
    return `/organizations/${storage.getOrganizationUUID()}/customers-pp`;
}

async function createCustomerPP(customerPPData) {
    return endpointV2.post(getBaseUrl(), customerPPData, {
        headers: getAuthHeadersWithJSONContentType(),
    });
}

async function getCustomerPP(customerPPUUID) {
    return endpointV2.get(`${getBaseUrl()}/${customerPPUUID}`, {
        headers: authHeaders(),
    });
}

async function getCustomersPP() {
    return endpointV2.get(getBaseUrl(), {
        headers: authHeaders(),
    });
}

async function updateCustomerPP(customerPPUUID, customerPPUpdateData) {
    return endpointV2.put(`${getBaseUrl()}/${customerPPUUID}`, customerPPUpdateData, {
        headers: getAuthHeadersWithJSONContentType(),
    });
}

async function deleteCustomerPP(customerPPUUID, accountUUID) {
    return endpointV2.delete(`${getBaseUrl()}/${customerPPUUID}`, {
        headers: authHeaders(),
        params: {
            uuidAccount: accountUUID,
        },
    });
}

export default {
    createCustomerPP,
    getCustomerPP,
    getCustomersPP,
    updateCustomerPP,
    deleteCustomerPP,
};
