import { SHOW_CONFIRM_DAILOG, SHOW_SUCCESS_DIALOG, SHOW_FAILURE_DIALOG, SHOW_SUBSCRIPTION_FAILURE_DIALOG, HIDE_DIALOGS, SHOW_WARNING_DIALOG } from '../../types/ui/dialogs';

function initState() {
    return {
        showConfirmDialog: false,
        showWarningDialog: false,
        showSuccessDialog: false,
        showFailureDialog: false,
        showSubscriptionFailureDialog: false,
    };
}

export default function (state = initState(), action) {
    const { type, payload } = action;

    switch (type) {
        case SHOW_CONFIRM_DAILOG:
            return {
                ...state,
                showConfirmDialog: true,
                message: payload.message,
                withChoice: payload.withChoice,
                confirmedButtonText: payload.confirmedButtonText,
                onConfirm: payload.onConfirm,
                closable: payload.closable,
                renderCustomComponent: payload.renderCustomComponent,
            };

        case SHOW_WARNING_DIALOG:
            return {
                ...state,
                showWarningDialog: true,
                message: payload.message,
                withChoice: payload.withChoice,
                confirmedButtonText: payload.confirmedButtonText,
                onConfirm: payload.onConfirm,
                onCancel: payload.onCancel,
                closable: payload.closable,
                renderCustomComponent: payload.renderCustomComponent,
            };

        case SHOW_SUCCESS_DIALOG:
            return {
                ...state,
                showSuccessDialog: true,
                message: payload.message,
            };

        case SHOW_FAILURE_DIALOG:
            return {
                ...state,
                showFailureDialog: true,
                message: payload.message,
                messages: payload.messages,
                redirect: payload.redirect,
            };

        case SHOW_SUBSCRIPTION_FAILURE_DIALOG:
            return {
                ...state,
                showSubscriptionFailureDialog: true,
                message: payload.message,
            };

        case HIDE_DIALOGS:
            return initState();

        default:
            return state;
    }
}
