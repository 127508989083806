import { appointmentNotifiedAccountsEnum, appointmentStatuses, categoryColors, personStatus, statusesInfo } from './enums';
import date_utils from './date_utils';

export const getStatusMarkerColor = (otherPersons) => {
    if (otherPersons.find((person) => person.status === personStatus.REJECTED)) {
        return 'red';
    }
    if (otherPersons.length > 0 && otherPersons.find((person) => person.status !== personStatus.ACCEPTED) && otherPersons.find((person) => person.status !== personStatus.REJECTED)) {
        return 'orange';
    }
    if (otherPersons.length > 0 && otherPersons.filter((person) => person.status === personStatus.ACCEPTED).length === otherPersons.length) {
        return 'green';
    }
    return 'grey';
};

export const getPersonStatusColor = (status) => {
    switch (status) {
        case personStatus.REJECTED:
            return '#F53A6B';
        case personStatus.ACCEPTED:
            return '#00928F';
        default:
            return '#E46526';
    }
};

export const getCategoryColor = (category) => (category ? categoryColors[category.markerColor] : '');

export const getAppointmentColor = (appointment) => {
    if (appointment.status === appointmentStatuses.REJECTED || appointment.status === appointmentStatuses.CANCELLED) {
        return statusesInfo[appointment.status].color;
    }
    return getCategoryColor(appointment.appointmentCategory) || '#0026C8';
};

const formatDate = (date) => date_utils.formatDate({ date, format: 'HH:mm' });

export const getTimepickerData = (date) => {
    const startDate = new Date(date);
    startDate.setHours(6, 0, 0, 0);

    return Array(71)
        .fill(null)
        .map((el, index) => {
            const currentMinutes = startDate.getMinutes();
            startDate.setMinutes(currentMinutes + 15);
            return { value: new Date(startDate), label: formatDate(startDate) };
        });
};

export const getAvailableTimepickerData = (cDate, sDate) => {
    let startDate = new Date(sDate || cDate);

    const datesAreInOneDay = new Date(startDate).setHours(23, 59, 59, 999) > new Date(cDate);

    const endDate = datesAreInOneDay ? new Date(cDate).setHours(23, 59, 59, 999) : new Date(cDate).setHours(0, 0, 0, 0);

    if (!datesAreInOneDay || !sDate) {
        startDate.setHours(6, 0, 0, 0);
    }

    const resultArr = [];

    while (startDate < endDate) {
        resultArr.push({ value: new Date(startDate), label: formatDate(startDate) });

        startDate.setMinutes(startDate.getMinutes() + 15);
    }

    return resultArr;
};

export const getDefaultTime = (currentDate) => {
    const timeOptions = getTimepickerData(currentDate);
    const timeArr = timeOptions.map((option) => option.value);
    let defaultTime = timeArr[timeArr.length - 1];
    let minPositiveDifference = defaultTime.getTime() - currentDate.getTime();
    timeArr.forEach((date) => {
        const difference = date.getTime() - currentDate.getTime();
        if (difference > 0 && difference < minPositiveDifference) {
            minPositiveDifference = difference;
            defaultTime = date;
        }
    });

    return defaultTime;
};

export const getStartAndEndDateForCalendar = (currentDate) => {
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 15);
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 15);
    return { startDate, endDate, currentDate };
};

export const getInitialContactNotifyInfo = ({ phone, email }) => {
    let notificationType = appointmentNotifiedAccountsEnum.SMS_AND_EMAIL;
    if (email && phone) notificationType = appointmentNotifiedAccountsEnum.SMS_AND_EMAIL;
    if (email && !phone) notificationType = appointmentNotifiedAccountsEnum.EMAIL;
    if (!email && phone) notificationType = appointmentNotifiedAccountsEnum.SMS;

    return { email, phone, notificationType };
};
