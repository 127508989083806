import addressDescriptorGenerator from './common_descriptor_generator/address';

export default [
    ...addressDescriptorGenerator({ required: true, includeCadastralParcelNumber: false }),
    {
        property: 'addressName',
        label: "Nom de l'adresse",
        type: 'string',
    },
    {
        property: 'isMainAddress',
        label: 'Siège social',
        type: 'boolean',
    },
];
