import { SEARCH_PRODUCTS_REQUEST, SEARCH_PRODUCTS_SUCCESS, SEARCH_PRODUCTS_FAILURE, RESET_PRODUCT_FINDER } from '../types/product.types';

function initState() {
    return {
        products: [],
        loading: false,
    };
}

export default function (state = initState(), action) {
    switch (action.type) {
        case SEARCH_PRODUCTS_REQUEST:
            return {
                ...state,
                products: [],
                loading: true,
            };

        case SEARCH_PRODUCTS_SUCCESS:
            return {
                products: action.payload.products,
                loading: false,
            };

        case SEARCH_PRODUCTS_FAILURE:
            return {
                products: [],
                loading: false,
            };

        case RESET_PRODUCT_FINDER:
            return initState();

        default:
            return state;
    }
}
