import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxThunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import reducers from './reducers';
import { quotationMiddleware, invoiceMiddleware } from './middlewares';

export function initStore({ preloadedState } = {}) {
    return createStore(reducers, preloadedState, composeWithDevTools(applyMiddleware(...getMiddlewares())));
}

function getMiddlewares() {
    const middlewares = [reduxThunk, quotationMiddleware, invoiceMiddleware];
    const reduxLogger = createLogger({
        collapsed: (getState, action, logEntry) => !logEntry.error,
    });
    if (process.env.NODE_ENV === 'development') middlewares.push(reduxLogger);

    return middlewares;
}
