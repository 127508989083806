export default [
    {
        property: 'title',
        label: 'Nom',
        type: 'string',
        required: true,
    },
    {
        property: 'unit',
        label: 'Unité',
        type: 'string',
        required: true,
    },
    {
        property: 'price',
        label: 'Prix',
        type: 'number',
        required: true,
    },
    {
        property: 'vatRate',
        label: 'TVA',
        type: 'number',
        required: true,
    },
];
