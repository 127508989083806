export const GET_RGE_CERTIFICATES_REQUEST = 'GET_RGE_CERTIFICATES_REQUEST';
export const GET_RGE_CERTIFICATES_SUCCESS = 'GET_RGE_CERTIFICATES_SUCCESS';
export const GET_RGE_CERTIFICATES_FAILURE = 'GET_RGE_CERTIFICATES_FAILURE';

export const DELETE_RGE_CERTIFICATE_FILES_REQUEST = 'DELETE_RGE_CERTIFICATE_FILES_REQUEST';
export const DELETE_RGE_CERTIFICATE_FILES_SUCCESS = 'DELETE_RGE_CERTIFICATE_FILES_SUCCESS';
export const DELETE_RGE_CERTIFICATE_FILES_FAILURE = 'DELETE_RGE_CERTIFICATE_FILES_FAILURE';

export const CLEAR_RGE_CERTIFICATES_FINDER_DATA = 'CLEAR_RGE_CERTIFICATES_FINDER_DATA';

export const CLEAR_RGE_CERTIFICATE_FINDER_DATA = 'CLEAR_RGE_CERTIFICATE_FINDER_DATA';
