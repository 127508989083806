import validateObjectTarget from './operation_form_helper_utils/validate-object-target';
import validateArrayTarget from './operation_form_helper_utils/validate-array-target';
import { operationInstallationTypes, operationHeatingTypes, operationInstallationFinishesTranslations, operationInstallationTypesTranslations } from './enums';
import { LABEL_INSTALLATION_FINISHES } from './operation-constants';
import operationSheetNames from './operation-sheet-names';
import { isNonEmptyObject } from '.';

function validateOperationForm(formState, operationFormDescriptor, descriptorData = {}) {
    const { installationDetailsToSpecify = [], exclusionDetailsToSpecify = [], otherDetailsToSpecify = [], ...operationProperties } = formState;
    const errorMessages = [];
    const {
        propertiesDescriptor,
        installationDetailsToSpecifyPropertiesDescriptor,
        exclusionDetailsToSpecifyPropertiesDescriptor,
        otherDetailsToSpecifyPropertiesDescriptor,
    } = operationFormDescriptor;

    const operationValidation = validateObjectTarget(operationProperties, propertiesDescriptor, descriptorData);
    const installationDetailsToSpecifyValidation = validateArrayTarget(installationDetailsToSpecify, installationDetailsToSpecifyPropertiesDescriptor, descriptorData);
    const exclusionDetailsToSpecifyValidation = validateArrayTarget(exclusionDetailsToSpecify, exclusionDetailsToSpecifyPropertiesDescriptor, descriptorData);
    const otherDetailsToSpecifyValidation = validateArrayTarget(otherDetailsToSpecify, otherDetailsToSpecifyPropertiesDescriptor, descriptorData);

    const newFormState = {
        ...operationValidation.newTarget,
        installationDetailsToSpecify: installationDetailsToSpecifyValidation.newTarget,
        exclusionDetailsToSpecify: exclusionDetailsToSpecifyValidation.newTarget,
        otherDetailsToSpecify: otherDetailsToSpecifyValidation.newTarget,
    };
    const validForm =
        operationValidation.validTarget && installationDetailsToSpecifyValidation.validTarget && exclusionDetailsToSpecifyValidation.validTarget && otherDetailsToSpecifyValidation.validTarget;
    if (!validForm) errorMessages.push('Veuillez compléter le formulaire');

    return { newFormState, validForm, errorMessages };
}

function extractPropertiesOfOperationArray(array = []) {
    const properties = {};
    array.forEach(({ label, value }) => {
        properties[label] = value;
    });

    return properties;
}

function isLabelIncludedInOperationArray(array = [], targetLabel, targetValue) {
    return !!array.find(({ label, value }) => label === targetLabel && value === targetValue);
}

function extractPropertyFromOperationArray(array = [], label) {
    return array.find((item) => item.label === label)?.value;
}

function getOtherDetailToSpecifyValue(operation, label) {
    const { otherDetailsToSpecify = [] } = operation;

    return extractPropertyFromOperationArray(otherDetailsToSpecify, label);
}

function getInstallationDetailToSpecifyValue(operation, label) {
    const { installationDetailsToSpecify = [] } = operation;

    return extractPropertyFromOperationArray(installationDetailsToSpecify, label);
}

function getInstallationFinishesValue(operation, { onlyCustomValue = false, onlySelectedValue = false } = {}) {
    const value = getInstallationDetailToSpecifyValue(operation, LABEL_INSTALLATION_FINISHES.DEFAULT);
    if (!value) return '';
    if (onlyCustomValue) return isValueIncludedInInstallationFinishesValues(value) ? '' : value;
    if (onlySelectedValue) return isValueIncludedInInstallationFinishesValues(value) ? value : operationInstallationFinishesTranslations.AUTRE;

    return value;
}

function isValueIncludedInInstallationFinishesValues(value) {
    const installationFinishesValues = Object.values(operationInstallationFinishesTranslations);

    return installationFinishesValues.includes(value);
}

function getExclusionDetailToSpecifyValue(operation, label) {
    const { exclusionDetailsToSpecify = [] } = operation;

    return extractPropertyFromOperationArray(exclusionDetailsToSpecify, label);
}

function translateInstallationType(installationType) {
    return operationInstallationTypesTranslations[installationType] || '';
}

function getHeatingTypeLabel(operation, label) {
    const heatingType = getOtherDetailToSpecifyValue(operation, label);
    const labels = {
        [operationHeatingTypes.ELECTRICITE]: 'Électricité',
        [operationHeatingTypes.COMBUSTIBLE]: 'Combustible',
    };

    return labels[heatingType] || '';
}

function isInstallationTypeValid(installationType) {
    return !!installationType && installationType !== operationInstallationTypes.NONE;
}

function getCompliancy(operation) {
    return operation.compliant || false;
}

function canCheckOperationCompliancy(operation) {
    return operation.blockValidity.valid && !!getCompliancyMessage(operation) && !operation.compliant;
}

function getCompliancyMessage(operation) {
    const { compliancyMessages = [] } = operation;

    return compliancyMessages.length > 0 ? compliancyMessages[0] : '';
}

function canCheckTechnicianCompliancy(technician) {
    const { errorMessages = [] } = technician.blockValidity;

    return errorMessages.length > 0;
}

function doesOperationRequireInstallationFinishes(operation) {
    return [operationSheetNames.BAR_EN_102V_A14_1, operationSheetNames.BAR_EN_102V_A39_2, operationSheetNames.BAR_EN_107, operationSheetNames.BAT_EN_102, operationSheetNames.BAT_EN_108].includes(
        operation.operationSheetName
    );
}

function removeOperationVersion(operationSheetName) {
    return operationSheetName.replace(/_/g, '-').split('V-')[0];
}

function isOperationBatEn109WithBatEn106Included(operation, operations) {
    return operation?.operationSheetName === operationSheetNames.BAT_EN_109 && isNonEmptyObject(findOperation(operations, operationSheetNames.BAT_EN_106));
}

function isOperationBatEn106WithBatEn109Included(operation, operations) {
    return operation?.operationSheetName === operationSheetNames.BAT_EN_106 && isNonEmptyObject(findOperation(operations, operationSheetNames.BAT_EN_109));
}

function findOperation(operations, operationSheetName) {
    return operations.find((operation) => operation.operationSheetName === operationSheetName);
}

function isOperationInstallationStartDateInputDisabled(operation, operations) {
    return isOperationBatEn109WithBatEn106Included(operation, operations);
}

function isOperationInstallationEndDateInputDisabled(operation, operations) {
    return isOperationBatEn109WithBatEn106Included(operation, operations);
}

export default {
    validateOperationForm,
    extractPropertiesOfOperationArray,
    isLabelIncludedInOperationArray,
    getOtherDetailToSpecifyValue,
    getInstallationDetailToSpecifyValue,
    getExclusionDetailToSpecifyValue,
    translateInstallationType,
    getHeatingTypeLabel,
    isInstallationTypeValid,
    getCompliancy,
    canCheckOperationCompliancy,
    getCompliancyMessage,
    canCheckTechnicianCompliancy,
    getInstallationFinishesValue,
    doesOperationRequireInstallationFinishes,
    removeOperationVersion,
    isOperationBatEn109WithBatEn106Included,
    findOperation,
    isOperationInstallationStartDateInputDisabled,
    isOperationInstallationEndDateInputDisabled,
    isOperationBatEn106WithBatEn109Included,
};
