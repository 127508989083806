import EmailValidator from 'email-validator';
import Iban from 'iban';

import { isValidValue } from '../';

function validateValueAsRequired(value, property, label, formErrors, errorMessages) {
    if (!['boolean', 'number'].includes(typeof value) && !value) {
        formErrors[property] = errorMessages.required || `${label} obligatoire`;
        if (isAddressProperty(property)) formErrors.address = 'Adresse obligatoire';
        return false;
    }

    return true;
}

function isAddressProperty(property) {
    return ['streetNumber', 'streetName', 'city', 'postalCode', 'country'].includes(property);
}

function validateValueAsEmailAddress(value, property, label, formErrors) {
    if (!EmailValidator.validate(value)) {
        formErrors[property] = `${label} non valide`;
        return false;
    }

    return true;
}

function validateValueAsPhoneNumber(value, property, label, formErrors) {
    if (!value.match(/^[0]?\d{9}/)) {
        formErrors[property] = `${label} non valide`;
        return false;
    }

    return true;
}

function validateValueAsIban(value, property, label, formErrors) {
    if (!Iban.isValid(value)) {
        formErrors[property] = `${label} non valide`;
        return false;
    }

    return true;
}

function validateValueAsPattern(value, pattern, property, label, formErrors) {
    if (!pattern.test(value)) {
        formErrors[property] = `${label} non valide`;
        return false;
    }

    return true;
}

function validateValueAsRequiredIf(value, requiredIfFunction, additionalData, property, label, formErrors) {
    if (typeof requiredIfFunction !== 'function') return true;
    if (requiredIfFunction(additionalData) && !isValidValue(value)) {
        formErrors[property] = `${label} obligatoire`;
        return false;
    }

    return true;
}

function validateValueAsMin(value, min, property, label, formErrors) {
    if (typeof value !== 'number' || typeof min !== 'number') return true;
    if (value < min) {
        formErrors[property] = `${label} doit être >= ${min}`;
        return false;
    }

    return true;
}

function validateValueMinItems(value, minItems, property, label, formErrors, errorMessages) {
    if (!Array.isArray(value)) return true;
    if (value.length < minItems) {
        formErrors[property] = errorMessages.minItems || `${label} doit contenir au moins ${minItems} éléments`;

        return false;
    }

    return true;
}

export default {
    validateValueAsRequired,
    validateValueAsEmailAddress,
    validateValueAsPhoneNumber,
    validateValueAsIban,
    validateValueAsPattern,
    validateValueAsRequiredIf,
    validateValueAsMin,
    validateValueMinItems,
};
