import { endpointV1, authHeaders, getAuthHeadersWithJSONContentType } from '../../api';

const signUp = async (account, organizationUUID) => {
    const params = {};
    if (organizationUUID) params.uuidOrganization = organizationUUID;

    return endpointV1.post('/accounts', account, {
        headers: { 'Content-Type': 'application/json' },
        params,
    });
};

const signIn = async (credentials) => {
    return endpointV1.post('/accounts/authenticate', credentials, {
        headers: { 'Content-Type': 'application/json' },
    });
};

const getAccount = async (accountId) =>
    endpointV1.get(`/accounts/${accountId}`, {
        headers: authHeaders(),
    });

const sendValidationLink = async (email) =>
    endpointV1.post(
        '/accounts/sendAcountValidationLink',
        { email },
        {
            headers: { 'Content-Type': 'application/json' },
        }
    );

const confirmValidationToken = async (token) =>
    endpointV1.post(
        '/accounts/confirmValidationToken',
        { token },
        {
            headers: { 'Content-Type': 'application/json' },
        }
    );

async function sendInvitationLinks(emails, accountUUID) {
    return endpointV1.post(
        '/accounts/invitation-links',
        { emails },
        {
            headers: getAuthHeadersWithJSONContentType(),
            params: {
                uuidAccount: accountUUID,
            },
        }
    );
}

async function updateAccount(accountUUID, formData) {
    return endpointV1.put(`/accounts/${accountUUID}`, formData, {
        headers: authHeaders(),
    });
}

async function updateAccountPassword(accountId, passwordUpdates) {
    return endpointV1.put(`/accounts/updatePassword/${accountId}`, passwordUpdates, {
        headers: getAuthHeadersWithJSONContentType(),
    });
}

async function sendResetPasswordLink(email) {
    return endpointV1.post(
        '/accounts/password-reset-link',
        { email },
        {
            headers: getAuthHeadersWithJSONContentType(),
        }
    );
}

async function setNewPassword(accountUUID, password, token) {
    return endpointV1.put(
        `/accounts/${accountUUID}/new-password`,
        { password },
        {
            headers: getAuthHeadersWithJSONContentType(token),
        }
    );
}

export default {
    signUp,
    signIn,
    getAccount,
    sendValidationLink,
    confirmValidationToken,
    sendInvitationLinks,
    updateAccount,
    updateAccountPassword,
    sendResetPasswordLink,
    setNewPassword,
};
