import {
    LABEL_TYPE_FONCTIONNEMENT,
    LABEL_GREEN_FLAME_LABEL_SEVEN,
    LABEL_NOMINAL_YIELD,
    LABEL_PARTICLE_EMISSION,
    LABEL_CARBON_MONOXIDE_EMISSIONS,
    LABEL_NITROGEN_OXIDE_EMISSIONS,
    LABEL_GASEOUS_ORGANIC_COMPOUNDS_EMISSIONS,
    LABEL_SEASONAL_ENERGY_EFFICIENCY_ETAS,
} from '../../product-constants';

export default [
    {
        property: LABEL_TYPE_FONCTIONNEMENT,
        label: LABEL_TYPE_FONCTIONNEMENT,
        type: 'string',
        required: true,
    },
    {
        property: LABEL_GREEN_FLAME_LABEL_SEVEN,
        label: LABEL_GREEN_FLAME_LABEL_SEVEN,
        type: 'checkbox',
        required: true,
    },

    {
        property: LABEL_SEASONAL_ENERGY_EFFICIENCY_ETAS,
        label: LABEL_SEASONAL_ENERGY_EFFICIENCY_ETAS,
        type: 'string',
    },
    {
        property: LABEL_GASEOUS_ORGANIC_COMPOUNDS_EMISSIONS,
        label: LABEL_GASEOUS_ORGANIC_COMPOUNDS_EMISSIONS,
        type: 'string',
    },
    {
        property: LABEL_NOMINAL_YIELD,
        label: LABEL_NOMINAL_YIELD,
        type: 'string',
    },
    {
        property: LABEL_PARTICLE_EMISSION,
        label: LABEL_PARTICLE_EMISSION,
        type: 'string',
    },
    {
        property: LABEL_CARBON_MONOXIDE_EMISSIONS,
        label: LABEL_CARBON_MONOXIDE_EMISSIONS,
        type: 'string',
    },
    {
        property: LABEL_NITROGEN_OXIDE_EMISSIONS,
        label: LABEL_NITROGEN_OXIDE_EMISSIONS,
        type: 'string',
    },
];
