export default [
    {
        property: 'oldPassword',
        label: 'Ancien mot de passe',
        type: 'string',
        required: true,
    },
    {
        property: 'newPassword',
        label: 'Nouveau mot de passe',
        type: 'string',
        required: true,
    },
    {
        property: 'confirmNewPassword',
        label: 'Confirmation du mot de passe',
        type: 'string',
        required: true,
    },
];
