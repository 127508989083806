function handleMouseMove(evt) {
    if (process.env.REACT_APP_NODE_ENV !== 'development') return;

    document.onmousemove = function (evt) {
        const x = evt.pageX;
        const y = evt.pageY;
        evt.target.title = `Position: {${x}, ${y}}`;
    };
}

export default { handleMouseMove };
