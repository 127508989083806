export const GET_APPOINTMENTS_REQUEST = 'GET_APPOINTMENTS_REQUEST';
export const GET_APPOINTMENTS_SUCCESS = 'GET_APPOINTMENTS_SUCCESS';
export const GET_APPOINTMENTS_FAILURE = 'GET_APPOINTMENTS_FAILURE';

export const GET_APPOINTMENTS_BY_DATE_REQUEST = 'GET_APPOINTMENTS_BY_DATE_REQUEST';
export const GET_APPOINTMENTS_BY_DATE_SUCCESS = 'GET_APPOINTMENTS_BY_DATE_SUCCESS';
export const GET_APPOINTMENTS_BY_DATE_FAILURE = 'GET_APPOINTMENTS_BY_DATE_FAILURE';

export const CREATE_APPOINTMENT_REQUEST = 'CREATE_APPOINTMENT_REQUEST';
export const CREATE_APPOINTMENT_SUCCESS = 'CREATE_APPOINTMENT_SUCCESS';
export const CREATE_APPOINTMENT_FAILURE = 'CREATE_APPOINTMENT_FAILURE';

export const UPDATE_APPOINTMENT_REQUEST = 'UPDATE_APPOINTMENT_REQUEST';
export const UPDATE_APPOINTMENT_SUCCESS = 'UPDATE_APPOINTMENT_SUCCESS';
export const UPDATE_APPOINTMENT_FAILURE = 'UPDATE_APPOINTMENT_FAILURE';

export const DELETE_APPOINTMENT_REQUEST = 'DELETE_APPOINTMENT_REQUEST';
export const DELETE_APPOINTMENT_SUCCESS = 'DELETE_APPOINTMENT_SUCCESS';
export const DELETE_APPOINTMENT_FAILURE = 'DELETE_APPOINTMENT_FAILURE';

export const DELETE_APPOINTMENT_FILE_REQUEST = 'DELETE_APPOINTMENT_FILE_REQUEST';
export const DELETE_APPOINTMENT_FILE_SUCCESS = 'DELETE_APPOINTMENT_FILE_SUCCESS';
export const DELETE_APPOINTMENT_FILE_FAILURE = 'DELETE_APPOINTMENT_FILE_FAILURE';

export const DELETE_OTHER_PERSON_IN_APPOINTMENT_REQUEST = 'DELETE_OTHER_PERSON_IN_APPOINTMENT_REQUEST';
export const DELETE_OTHER_PERSON_IN_APPOINTMENT_SUCCESS = 'DELETE_OTHER_PERSON_IN_APPOINTMENT_SUCCESS';
export const DELETE_OTHER_PERSON_IN_APPOINTMENT_FAILURE = 'DELETE_OTHER_PERSON_IN_APPOINTMENT_FAILURE';

export const DOWNLOAD_APPOINTMENT_FILE_REQUEST = 'DOWNLOAD_APPOINTMENT_FILE_REQUEST';
export const DOWNLOAD_APPOINTMENT_FILE_SUCCESS = 'DOWNLOAD_APPOINTMENT_FILE_SUCCESS';
export const DOWNLOAD_APPOINTMENT_FILE_FAILURE = 'DOWNLOAD_APPOINTMENT_FILE_FAILURE';

export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE';

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';

export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE';

export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE';

export const SEARCH_CUSTOMERS_FOR_APPOINTMENT_REQUEST = 'SEARCH_CUSTOMERS_FOR_APPOINTMENT_REQUEST';
export const SEARCH_CUSTOMERS_FOR_APPOINTMENT_SUCCESS = 'SEARCH_CUSTOMERS_FOR_APPOINTMENT_SUCCESS';
export const SEARCH_CUSTOMERS_FOR_APPOINTMENT_FAILURE = 'SEARCH_CUSTOMERS_FOR_APPOINTMENT_FAILURE';

export const SEARCH_TECHNICIANS_FOR_APPOINTMENT_REQUEST = 'SEARCH_TECHNICIANS_FOR_APPOINTMENT_REQUEST';
export const SEARCH_TECHNICIANS_FOR_APPOINTMENT_SUCCESS = 'SEARCH_TECHNICIANS_FOR_APPOINTMENT_SUCCESS';
export const SEARCH_TECHNICIANS_FOR_APPOINTMENT_FAILURE = 'SEARCH_TECHNICIANS_FOR_APPOINTMENT_FAILURE';

export const SEARCH_TITLES_REQUEST = 'SEARCH_TITLES_REQUEST';
export const SEARCH_TITLES_SUCCESS = 'SEARCH_TITLES_SUCCESS';
export const SEARCH_TITLES_FAILURE = 'SEARCH_TITLES_FAILURE';

export const GET_CUSTOMER_FOR_APPOINTMENT_REQUEST = 'GET_CUSTOMER_FOR_APPOINTMENT_REQUEST';
export const GET_CUSTOMER_FOR_APPOINTMENT_SUCCESS = 'GET_CUSTOMER_FOR_APPOINTMENT_SUCCESS';
export const GET_CUSTOMER_FOR_APPOINTMENT_FAILURE = 'GET_CUSTOMER_FOR_APPOINTMENT_FAILURE';

export const GET_TECHNICIAN_FOR_APPOINTMENT_REQUEST = 'GET_TECHNICIAN_FOR_APPOINTMENT_REQUEST';
export const GET_TECHNICIAN_FOR_APPOINTMENT_SUCCESS = 'GET_TECHNICIAN_FOR_APPOINTMENT_SUCCESS';
export const GET_TECHNICIAN_FOR_APPOINTMENT_FAILURE = 'GET_TECHNICIAN_FOR_APPOINTMENT_FAILURE';

export const UPDATE_APPOINTMENT_STATUS_FAILURE = 'UPDATE_APPOINTMENT_STATUS_FAILURE';

export const SET_APPOINTMENTS_FILTER = 'SET_APPOINTMENTS_FILTER';
export const CLEANUP_CUSTOMERS = 'CLEANUP_CUSTOMERS';
export const CLEANUP_TECHNICIANS = 'CLEANUP_TECHNICIANS';
export const CLEANUP_TITLES = 'CLEANUP_TITLES';
export const SET_CALENDAR_PERIOD = 'SET_CALENDAR_PERIOD';
export const RESET_FILTER = 'RESET_FILTER';
export const SET_APPOINTMENT_MODAL = 'SET_APPOINTMENT_MODAL';
export const SET_EDITABLE_CATEGORY = 'SET_EDITABLE_CATEGORY';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const CLEANUP = 'PLANNING_CLEANUP';
