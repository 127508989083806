import { START_REQUESTING, STOP_REQUESTING, RESET_FORM } from '../../types/ui/form';
import dialogsActions from './dialogs';
import { parseError } from '../../../api';

const startRequesting = ({ fromPublicRoute = false } = {}) => ({
    type: START_REQUESTING,
    payload: { fromPublicRoute },
});

const stopRequesting = ({ errorMessage, successMessage } = {}) => ({
    type: STOP_REQUESTING,
    payload: { errorMessage, successMessage },
});

const resetForm = () => ({
    type: RESET_FORM,
});

export const executeAnyTypeOfRequest = (action, onCreateMessage) => {
    return (dispatch, getState) => {
        dispatch(startRequesting());
        return action()
            .then(({ data }) => {
                dispatch(stopRequesting());
                dispatch(dialogsActions.showSuccessDialog({ message: onCreateMessage }));
                return data;
            })
            .catch((error) => {
                const errorMessage = parseError(error);
                dispatch(stopRequesting());
                dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            });
    };
};

export default {
    startRequesting,
    stopRequesting,
    resetForm,
};
