import {
    CREATE_INVOICE_REQUEST,
    CREATE_INVOICE_SUCCESS,
    CREATE_INVOICE_FAILURE,
    GET_INVOICE_REQUEST,
    GET_INVOICE_SUCCESS,
    GET_INVOICE_FAILURE,
    SAVE_INVOICE_REQUEST,
    SAVE_INVOICE_SUCCESS,
    SAVE_INVOICE_FAILURE,
    GET_INVOICES_REQUEST,
    GET_INVOICES_SUCCESS,
    GET_INVOICES_FAILURE,
    DELETE_INVOICE_SUCCESS,
    DELETE_INVOICE_FAILURE,
    DELETE_INVOICE_REQUEST,
    CANCEL_INVOICE_SUCCESS,
    CANCEL_INVOICE_FAILURE,
    CANCEL_INVOICE_REQUEST,
    UPDATE_INVOICE_SUCCESS,
    UPDATE_INVOICE_FAILURE,
    UPDATE_INVOICE_REQUEST,
    UPDATE_INVOICE_OPERATION_INSTALLATION_DATE,
    UPDATE_OPERATION_INSTALLATION_END_DATE,
    UPDATE_OPERATION_PRODUCT_QUANTITY,
    UPDATE_OPERATION_OTHER_PRODUCT_QUANTITY,
    UPDATE_INVOICE_ISSUE_DATE_REQUEST,
    UPDATE_INVOICE_OTHER_PRODUCT,
    UPDATE_INVOICE_MAIN_PRODUCT,
    ADD_INVOICE_OTHER_PRODUCT,
    DELETE_INVOICE_OTHER_PRODUCT,
    SEND_INVOICE_BY_EMAIL_REQUEST,
    SEND_INVOICE_BY_EMAIL_SUCCESS,
    SEND_INVOICE_BY_EMAIL_FAILURE,
    ADD_MAIN_PRODUCT_VARIATION_INVOICE,
    UPDATE_MAIN_PRODUCT_VARIATION_INVOICE,
    DELETE_MAIN_PRODUCT_VARIATION_INVOICE,
    UPDATE_INVOICE_OPERATION,
    GET_INVOICES_ANALYTICS_SUCCESS,
    GET_INVOICES_ANALYTICS_REQUEST,
    GET_INVOICES_ANALYTICS_FAILURE,
    UPDATE_INVOICE_WASTE_MANAGEMENT,
    UPDATE_INVOICE_OPERATIONS,
} from '../types/invoice.types';
import invoiceService from '../services/invoice.service';
import { parseError } from '../../api';
import formActions from './ui/form';
import dialogsActions from './ui/dialogs';
import history from '../../utils/history';
import { productTypes } from '../../utils/enums';
import { v1 as uuidV1 } from 'uuid';
import { calculateOperationSavingCertificatePremiumForOneDollarNetPrice } from '../../utils/operation-helper';

const createInvoice = (datas) => (dispatch) => {
    dispatch({ type: CREATE_INVOICE_REQUEST });
    dispatch(formActions.startRequesting());
    invoiceService
        .createInvoice(datas)
        .then(({ data }) => {
            dispatch({ type: CREATE_INVOICE_SUCCESS, payload: data });
            dispatch(formActions.stopRequesting());
            history.push(`/factures/${data.uuid}/edition`);
        })
        .catch((error) => {
            const errorMessage = parseError(error);
            dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            dispatch({ type: CREATE_INVOICE_FAILURE, error: errorMessage });
            dispatch(formActions.stopRequesting());
        });
};

const getInvoice = (uuidInvoice) => (dispatch) => {
    dispatch({ type: GET_INVOICE_REQUEST });
    dispatch(formActions.startRequesting());
    invoiceService
        .getInvoice(uuidInvoice)
        .then(({ data }) => {
            dispatch({ type: GET_INVOICE_SUCCESS, payload: data });
            dispatch(formActions.stopRequesting());
        })
        .catch((error) => {
            const errorMessage = parseError(error);
            dispatch(dialogsActions.showFailureDialog({ message: errorMessage, redirect: '/factures' }));
            dispatch({ type: GET_INVOICE_FAILURE, error: errorMessage });
            dispatch(formActions.stopRequesting());
        });
};

const saveInvoice = ({ uuidInvoice, updates, loading = false }) => (dispatch) => {
    dispatch({ type: SAVE_INVOICE_REQUEST });
    if (loading) dispatch(formActions.startRequesting());
    invoiceService
        .saveInvoice(uuidInvoice, updates)
        .then(({ data: invoice }) => {
            dispatch({ type: SAVE_INVOICE_SUCCESS, payload: invoice });
            if (loading) dispatch(formActions.stopRequesting());
            if (updates.finalizeInvoice) history.push(`/factures/${invoice.uuid}/apercu`);
        })
        .catch((error) => {
            const errorMessage = parseError(error);
            dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            dispatch({ type: SAVE_INVOICE_FAILURE });
            if (loading) dispatch(formActions.stopRequesting());
        });
};

const getInvoices = (query = {}) => (dispatch) => {
    dispatch({ type: GET_INVOICES_REQUEST });
    if (!query.searchString) dispatch(formActions.startRequesting());
    invoiceService
        .getInvoices(query)
        .then(({ data }) => {
            dispatch({ type: GET_INVOICES_SUCCESS, payload: data });
            dispatch(formActions.stopRequesting());
        })
        .catch((error) => {
            const errorMessage = parseError(error);
            dispatch({ type: GET_INVOICES_FAILURE, error: errorMessage });
            dispatch(formActions.stopRequesting());
        });
};

const deleteInvoice = (uuidInvoice) => (dispatch) => {
    dispatch({ type: DELETE_INVOICE_REQUEST });
    dispatch(formActions.startRequesting());
    invoiceService
        .deleteInvoice(uuidInvoice)
        .then(({ data }) => {
            dispatch({ type: DELETE_INVOICE_SUCCESS, payload: data });
            dispatch(formActions.stopRequesting());
            history.push('/factures');
        })
        .catch((error) => {
            const errorMessage = parseError(error);
            dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            dispatch({ type: DELETE_INVOICE_FAILURE });
            dispatch(formActions.stopRequesting());
        });
};

const cancelInvoice = (uuidInvoice) => (dispatch) => {
    dispatch({ type: CANCEL_INVOICE_REQUEST });
    dispatch(formActions.startRequesting());
    invoiceService
        .cancelInvoice(uuidInvoice)
        .then(({ data }) => {
            dispatch({ type: CANCEL_INVOICE_SUCCESS, payload: data });
            dispatch(formActions.stopRequesting());
            history.push(`/factures/${data.uuid}/edition`);
        })
        .catch((error) => {
            const errorMessage = parseError(error);
            dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            dispatch({ type: CANCEL_INVOICE_FAILURE });
            dispatch(formActions.stopRequesting());
        });
};

const updateInvoice = (uuidInvoice, updates) => (dispatch) => {
    dispatch({
        type: UPDATE_INVOICE_REQUEST,
    });
    invoiceService
        .updateInvoice(uuidInvoice, updates)
        .then(({ data }) => {
            dispatch({ type: UPDATE_INVOICE_SUCCESS, payload: data });
            dispatch(
                dialogsActions.showSuccessDialog({
                    message: 'Votre modification a bien été enregistrée',
                })
            );
            dispatch(getInvoices());
        })
        .catch((error) => {
            const errorMessage = parseError(error);
            dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            dispatch({ type: UPDATE_INVOICE_FAILURE });
            dispatch(formActions.stopRequesting());
        });
};

const sendInvoiceByEmail = ({ invoiceUUID, sendingData }) => (dispatch) => {
    dispatch({ type: SEND_INVOICE_BY_EMAIL_REQUEST });
    dispatch(formActions.startRequesting());

    invoiceService
        .sendInvoiceByEmail(invoiceUUID, sendingData)
        .then(({ data }) => {
            dispatch({ type: SEND_INVOICE_BY_EMAIL_SUCCESS, payload: { invoice: data } });
            dispatch(formActions.stopRequesting());
            dispatch(dialogsActions.showSuccessDialog({ message: 'Votre facture a bien été envoyée' }));
            dispatch(getInvoices());
        })
        .catch((error) => {
            const errorMessage = parseError(error);
            dispatch(formActions.stopRequesting());
            dispatch(dialogsActions.showFailureDialog({ message: errorMessage }));
            dispatch({ type: SEND_INVOICE_BY_EMAIL_FAILURE, payload: { errorMessage } });
        });
};

const updateOperationInstallationDate = ({ uuidOperation, installationDate, additionalRelatedOperations = [] }) => {
    return {
        type: UPDATE_INVOICE_OPERATION_INSTALLATION_DATE,
        payload: { uuidOperation, installationDate, additionalRelatedOperations },
    };
};

const updateOperationInstallationEndDate = ({ uuidOperation, installationEndDate, additionalRelatedOperations = [] }) => {
    return {
        type: UPDATE_OPERATION_INSTALLATION_END_DATE,
        payload: { uuidOperation, installationEndDate, additionalRelatedOperations },
    };
};

function updateWasteManagement({ wasteManagement }) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_INVOICE_WASTE_MANAGEMENT,
            payload: { wasteManagement },
        });
    };
}

const updateOperationProductQuantity = ({ uuidOperation, uuidProduct, quantity, additionalOperations = [] }) => {
    return {
        type: UPDATE_OPERATION_PRODUCT_QUANTITY,
        payload: { uuidOperation, uuidProduct, quantity, additionalOperations },
    };
};

function setTotalNetToOneEuro() {
    return (dispatch, getState) => {
        const { invoice } = getState().invoicesState;
        dispatch({ type: UPDATE_INVOICE_OPERATIONS, payload: { operations: calculateOperationSavingCertificatePremiumForOneDollarNetPrice(invoice) } });
    };
}

const updateOperationOtherProductQuantity = ({ uuidOperation, uuidProduct, quantity }) => {
    return {
        type: UPDATE_OPERATION_OTHER_PRODUCT_QUANTITY,
        payload: { uuidOperation, uuidProduct, quantity },
    };
};

const updateInvoiceIssueDate = ({ invoiceIssueDate }) => {
    return {
        type: UPDATE_INVOICE_ISSUE_DATE_REQUEST,
        payload: { invoiceIssueDate },
    };
};

const updateOtherProduct = ({ operationUUID, productUUID, updates }) => {
    const completedUpdates = updates;
    if (updates.product) {
        completedUpdates.price = updates.product.price;
        completedUpdates.vatRate = updates.product.vatRate;
        completedUpdates.unit = updates.product.unit;
    }
    return {
        type: UPDATE_INVOICE_OTHER_PRODUCT,
        payload: { operationUUID, productUUID, updates: completedUpdates },
    };
};

const resetOtherProduct = ({ operationUUID, productUUID }) => {
    return updateOtherProduct({ operationUUID, productUUID, updates: { product: { type: productTypes.OTHER_PRODUCT } } });
};

const updateMainProduct = ({ operationUUID, updates }) => (dispatch, getState) => {
    const completedUpdates = updates;
    if (updates.product && updates.product.detailsPerOperation) {
        const { operations } = getState().invoicesState.invoice;
        const { operationSheetName } = operations.find(({ uuid }) => uuid === operationUUID);
        completedUpdates.price = updates.product.detailsPerOperation[operationSheetName].defaultPrice;
    }

    dispatch({
        type: UPDATE_INVOICE_MAIN_PRODUCT,
        payload: { operationUUID, updates: completedUpdates },
    });
};

const updateLastOtherProduct = ({ operationUUID, updates }) => (dispatch, getState) => {
    const { operations } = getState().invoicesState.invoice;
    const { products } = operations.find(({ uuid }) => uuid === operationUUID);
    const otherProducts = products.filter(({ product }) => product.type === productTypes.OTHER_PRODUCT);
    const lastOtherProduct = otherProducts[otherProducts.length - 1];

    dispatch(updateOtherProduct({ operationUUID, productUUID: lastOtherProduct.uuid, updates }));
};

const initProduct = (type) => {
    const productInitData = {
        uuid: uuidV1(),
        blockValidity: { valid: false, errorMessages: ['Veuillez sélectionner un produit'] },
        product: { type },
    };
    if (type === productTypes.OTHER_PRODUCT) productInitData.unit = 'u';

    return productInitData;
};

const addOtherProduct = ({ operationUUID }) => {
    return {
        type: ADD_INVOICE_OTHER_PRODUCT,
        payload: { operationUUID, product: initProduct(productTypes.OTHER_PRODUCT) },
    };
};

const deleteOtherProduct = ({ operationUUID, productUUID }) => {
    return {
        type: DELETE_INVOICE_OTHER_PRODUCT,
        payload: { operationUUID, productUUID },
    };
};

function addMainProductVariation({ operationUUID }) {
    return { type: ADD_MAIN_PRODUCT_VARIATION_INVOICE, payload: { operationUUID, variation: { uuid: uuidV1() } } };
}

function updateMainProductVariation({ operationUUID, variationUUID, updates = {} } = {}) {
    return { type: UPDATE_MAIN_PRODUCT_VARIATION_INVOICE, payload: { operationUUID, variationUUID, updates } };
}

function deleteMainProductVariation({ operationUUID, variationUUID } = {}) {
    return { type: DELETE_MAIN_PRODUCT_VARIATION_INVOICE, payload: { operationUUID, variationUUID } };
}

function updateInvoiceOperation({ operationUUID, updates } = {}) {
    return { type: UPDATE_INVOICE_OPERATION, payload: { operationUUID, updates } };
}

const getInvoicesAnalytics = () => (dispatch) => {
    dispatch({ type: GET_INVOICES_ANALYTICS_REQUEST });
    invoiceService
        .getInvoicesAnalytics()
        .then(({ data }) => {
            dispatch({ type: GET_INVOICES_ANALYTICS_SUCCESS, payload: data });
        })
        .catch(() => {
            dispatch({ type: GET_INVOICES_ANALYTICS_FAILURE });
        });
};

export default {
    createInvoice,
    getInvoice,
    saveInvoice,
    updateOperationInstallationDate,
    updateOperationInstallationEndDate,
    updateOperationProductQuantity,
    updateInvoiceIssueDate,
    getInvoices,
    deleteInvoice,
    cancelInvoice,
    updateOperationOtherProductQuantity,
    resetOtherProduct,
    updateMainProduct,
    updateLastOtherProduct,
    addOtherProduct,
    updateOtherProduct,
    deleteOtherProduct,
    updateInvoice,
    updateWasteManagement,
    sendInvoiceByEmail,
    addMainProductVariation,
    updateMainProductVariation,
    deleteMainProductVariation,
    updateInvoiceOperation,
    getInvoicesAnalytics,
    setTotalNetToOneEuro,
};
