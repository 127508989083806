import { LABEL_INPUT_EMAIL, LABEL_INPUT_MOBILE, LABEL_INPUT_PHONE } from '../../form_labels';

export default function ({ required = true } = {}) {
    return [
        {
            property: 'email',
            label: LABEL_INPUT_EMAIL,
            type: 'string',
            dataType: 'emailAddress',
            required,
        },
        {
            property: 'phone',
            label: LABEL_INPUT_PHONE,
            type: 'string',
            dataType: 'phoneNumber',
        },
        {
            property: 'mobile',
            label: LABEL_INPUT_MOBILE,
            type: 'string',
            dataType: 'phoneNumber',
        },
    ];
}
