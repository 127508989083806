import _ from 'lodash';
import {
    customerTypes,
    renovationAddressBuildingSectorsTranslations,
    renovationAddressBuildingSectors,
    renovationAddressHousingTypesTranslations,
    renovationAddressActivityAreasTranslations,
    housingSituations,
    buildingAgeTranslations,
    housingSituationsTranslation,
} from './enums';

import { getDepartmentByPostalCode } from '../data/departments';

function isCustomerPP(customerType) {
    return customerType === customerTypes.CUSTOMER_PP;
}

function isCustomerPM(customerType) {
    return customerType === customerTypes.CUSTOMER_PM;
}

function isResidentialArea(customerType, buildingSector) {
    return isCustomerPP(customerType) || buildingSector === renovationAddressBuildingSectors.RESIDENTIAL;
}

function isTertiaryArea(buildingSector) {
    return buildingSector === renovationAddressBuildingSectors.TERTIARY;
}

function translateBuildingSector(buildingSector) {
    return renovationAddressBuildingSectorsTranslations[buildingSector];
}

function translateHousingType(housingType) {
    return renovationAddressHousingTypesTranslations[housingType];
}

function translateActivityArea(activityArea) {
    return renovationAddressActivityAreasTranslations[activityArea];
}

function isResidentialAddress(renovationAddresss = {}) {
    return renovationAddresss.buildingSector === renovationAddressBuildingSectors.RESIDENTIAL;
}

function getHouseholdSituationLabel(renovationAddress) {
    const { householdIncomeBonification } = renovationAddress;
    if (!householdIncomeBonification.valid) return housingSituationsTranslation.CLASSIC;

    const { housings } = householdIncomeBonification;
    const situationLabels = {
        [housingSituations.PRECARITY]: housingSituationsTranslation.PRECARITY,
        [housingSituations.GREAT_PRECARITY]: housingSituationsTranslation.GREAT_PRECARITY,
        [housingSituations.CLASSIC]: housingSituationsTranslation.CLASSIC,
    };

    return situationLabels[housings[0].situation];
}

function translateBuildingAge(buildingAge) {
    return buildingAgeTranslations[buildingAge];
}
function isPostalCodeInDomTom(postalCode) {
    return _.get(getDepartmentByPostalCode(postalCode), 'bonificationAsZNI', false);
}

export default {
    isCustomerPP,
    isCustomerPM,
    isResidentialArea,
    isTertiaryArea,
    translateBuildingSector,
    translateHousingType,
    translateActivityArea,
    isResidentialAddress,
    getHouseholdSituationLabel,
    translateBuildingAge,
    isPostalCodeInDomTom,
};
