export function getTestsEnvVariables() {
    const config = {
        development: {
            TEST_BACKEND_API_V1_ENDPOINT: 'http://localhost:5000',
            TEST_BACKEND_API_V2_ENDPOINT: 'http://localhost:5000/api/v2',
        },
    };

    return config[process.env.REACT_APP_NODE_ENV] || {};
}
