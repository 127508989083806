export const AUTOCOMPLETE_ORGANIZATION_REQUEST =
    'AUTOCOMPLETE_ORGANIZATION_REQUEST';
export const AUTOCOMPLETE_ORGANIZATION_SUCCESS =
    'AUTOCOMPLETE_ORGANIZATION_SUCCESS';
export const AUTOCOMPLETE_ORGANIZATION_FAILURE =
    'AUTOCOMPLETE_ORGANIZATION_FAILURE';

export const GET_ORGANIZATION_INFORMATION_REQUEST =
    'GET_ORGANIZATION_INFORMATION_REQUEST';
export const GET_ORGANIZATION_INFORMATION_SUCCESS =
    'GET_ORGANIZATION_INFORMATION_SUCCESS';
export const GET_ORGANIZATION_INFORMATION_FAILURE =
    'GET_ORGANIZATION_INFORMATION_FAILURE';

export const CLEAR_ORGANIZATION_FINDER_DATA = 'CLEAR_ORGANIZATION_FINDER_DATA';
