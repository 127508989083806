import { HYBRID_SOLAR_CAPTORS, LABEL_NATURE_FLUID_CAPTORS } from '../../product-constants';

export default [
    {
        property: HYBRID_SOLAR_CAPTORS,
        label: HYBRID_SOLAR_CAPTORS,
        type: 'radio',
        required: true,
    },
    {
        property: LABEL_NATURE_FLUID_CAPTORS,
        label: LABEL_NATURE_FLUID_CAPTORS,
        type: 'select',
        required: true,
    },
];
