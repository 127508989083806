export const LABEL_INPUT_PASSWORD = 'Mot de passe';
export const LABEL_INPUT_CONFIRM_PASSWORD = 'Confirmez le mot de passe';

export const LABEL_LINK_SIGN_IN = 'Se connecter';
export const LABEL_LINK_SIGN_UP = "S'inscrire";
export const LABEL_LINK_CONTACT_US = 'Nous contacter';
export const LABEL_LINK_LOST_PASSWORD = 'Mot de passe oublié ?';

export const LABEL_BUTTON_SIGN_UP = "S'inscrire";
export const LABEL_BUTTON_SIGN_IN = 'Se connecter';

export const LABEL_LINK_RESEND_ACCOUNT_VALIDATION_EMAIL = 'Renvoyez-le';
