export default [
    {
        property: 'companyName',
        label: "Raison sociale de l'organisme de contrôle",
        type: 'string',
        required: true,
    },
    {
        property: 'siren',
        label: "Numéro SIREN de l'organisme de contrôle",
        type: 'string',
        required: true,
    },
    {
        property: 'accreditationNumber',
        label: "Numéro d’accréditation (COFRAC) ou équivalent de l'organisme de contrôle",
        type: 'string',
        required: true,
    },
    {
        property: 'accreditationExpirationDate',
        label: "Date de fin de validité de l’accréditation de l'organisme de contrôle",
        type: 'string',
    },
    {
        property: 'reference',
        label: "Référence du rapport établi par l'organisme de contrôle",
        type: 'string',
        required: true,
    },
];
