export const CREATE_UPLOAD_DOCUMENT_REQUEST = 'CREATE_UPLOAD_DOCUMENT_REQUEST';
export const CREATE_UPLOAD_DOCUMENT_SUCCESS = 'CREATE_UPLOAD_DOCUMENT_SUCCESS';
export const CREATE_UPLOAD_DOCUMENT_FAILURE = 'CREATE_UPLOAD_DOCUMENT_FAILURE';

export const GET_UPLOAD_DOCUMENTS_REQUEST = 'GET_UPLOAD_DOCUMENTS_REQUEST';
export const GET_UPLOAD_DOCUMENTS_SUCCESS = 'GET_UPLOAD_DOCUMENTS_SUCCESS';
export const GET_UPLOAD_DOCUMENTS_FAILURE = 'GET_UPLOAD_DOCUMENTS_FAILURE';

export const GET_UPLOAD_DOCUMENT_REQUEST = 'GET_UPLOAD_DOCUMENT_REQUEST';
export const GET_UPLOAD_DOCUMENT_SUCCESS = 'GET_UPLOAD_DOCUMENT_SUCCESS';
export const GET_UPLOAD_DOCUMENT_FAILURE = 'GET_UPLOAD_DOCUMENT_FAILURE';

export const DELETE_UPLOAD_DOCUMENT_REQUEST = 'DELETE_UPLOAD_DOCUMENT_REQUEST';
export const DELETE_UPLOAD_DOCUMENT_SUCCESS = 'DELETE_UPLOAD_DOCUMENT_SUCCESS';
export const DELETE_UPLOAD_DOCUMENT_FAILURE = 'DELETE_UPLOAD_DOCUMENT_FAILURE';
