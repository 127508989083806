export default [
    {
        property: 'activity',
        label: "Secteur d'activité",
        type: 'string',
        required: true,
    },
    {
        property: 'phone',
        label: 'Téléphone',
        type: 'string',
        dataType: 'phoneNumber',
    },
];
