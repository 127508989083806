import { endpointV2, authHeaders, getAuthHeadersWithJSONContentType } from '../../api';
import storage from '../../utils/storage';

function getBaseUrl() {
    return `/organizations/${storage.getOrganizationUUID()}/customers-pm`;
}

async function createCustomerPM(customerPMData) {
    return endpointV2.post(getBaseUrl(), customerPMData, {
        headers: getAuthHeadersWithJSONContentType(),
    });
}

async function getCustomerPM(customerPMUUID) {
    return endpointV2.get(`${getBaseUrl()}/${customerPMUUID}`, {
        headers: authHeaders(),
    });
}

async function getCustomersPM() {
    return endpointV2.get(getBaseUrl(), {
        headers: authHeaders(),
    });
}

async function patchCustomerPM(customerPMUUID, path, operation, value, accountUUID) {
    return endpointV2.patch(
        `${getBaseUrl()}/${customerPMUUID}`,
        {
            op: operation,
            path,
            value,
        },
        {
            headers: getAuthHeadersWithJSONContentType(),
            params: {
                uuidAccount: accountUUID,
            },
        }
    );
}

async function deleteCustomerPM(customerPMUUID, accountUUID) {
    return endpointV2.delete(`${getBaseUrl()}/${customerPMUUID}`, {
        headers: authHeaders(),
        params: {
            uuidAccount: accountUUID,
        },
    });
}

export default {
    createCustomerPM,
    getCustomerPM,
    getCustomersPM,
    patchCustomerPM,
    deleteCustomerPM,
};
