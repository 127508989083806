import {
    AUTOCOMPLETE_ORGANIZATION_REQUEST,
    AUTOCOMPLETE_ORGANIZATION_SUCCESS,
    AUTOCOMPLETE_ORGANIZATION_FAILURE,
    GET_ORGANIZATION_INFORMATION_SUCCESS,
    GET_ORGANIZATION_INFORMATION_FAILURE,
    CLEAR_ORGANIZATION_FINDER_DATA
} from '../types/organization-finder';

const initState = () => ({
    remoteOrganizationInformation: null
});

export default (state = initState(), action) => {
    switch (action.type) {
        case AUTOCOMPLETE_ORGANIZATION_REQUEST:
            return {
                ...state,
                [action.payload.instanceKey]: {
                    loading: true,
                    data: Object.keys(state).includes(
                        action.payload.instanceKey
                    )
                        ? state[action.payload.instanceKey].data
                        : []
                }
            };
        case AUTOCOMPLETE_ORGANIZATION_SUCCESS:
            return {
                ...state,
                [action.payload.instanceKey]: {
                    loading: false,
                    data: action.payload.data
                }
            };
        case AUTOCOMPLETE_ORGANIZATION_FAILURE:
            return {
                ...state,
                [action.payload.instanceKey]: {
                    loading: false,
                    data: state[action.payload.instanceKey].data
                }
            };
        case GET_ORGANIZATION_INFORMATION_SUCCESS:
            return {
                ...state,
                remoteOrganizationInformation: action.payload
            };
        case GET_ORGANIZATION_INFORMATION_FAILURE:
            return {
                ...state,
                remoteOrganizationInformation: null
            };
        case CLEAR_ORGANIZATION_FINDER_DATA: {
            const clonedState = { ...state };
            if (Object.keys(clonedState).includes(action.payload.instanceKey))
                delete clonedState[action.payload.instanceKey];

            return {
                ...clonedState,
                remoteOrganizationInformation: null
            };
        }
        default:
            return state;
    }
};
