export default [
    {
        property: 'gender',
        label: 'Civilité',
        type: 'string',
    },
    {
        property: 'lastName',
        label: 'Nom',
        type: 'string',
        required: true,
    },
    {
        property: 'firstName',
        label: 'Prénom',
        type: 'string',
        required: true,
    },
    {
        property: 'phone',
        label: 'Téléphone fixe',
        type: 'string',
        dataType: 'phoneNumber',
    },
    {
        property: 'mobile',
        label: 'Téléphone mobile',
        type: 'string',
        dataType: 'phoneNumber',
    },
    {
        property: 'email',
        label: 'Adresse email',
        type: 'string',
        dataType: 'emailAddress',
    },
    {
        property: 'role',
        label: 'Rôle',
        type: 'string',
        required: true,
    },
    {
        property: 'isMainContact',
        label: 'Contact signataire',
        type: 'boolean',
    },
];
