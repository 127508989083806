import {
    GET_FISCAL_INFORMATION_SUCCESS,
    CLEAR_FISCAL_INFORMATION_FINDER
} from '../types/fiscal-information-finder.types';

const initState = () => ({});

export default (state = initState(), action) => {
    switch (action.type) {
        case GET_FISCAL_INFORMATION_SUCCESS:
            return {
                [action.payload.instanceKey]: action.payload.data
            };
        case CLEAR_FISCAL_INFORMATION_FINDER: {
            const clonedState = { ...state };
            if (Object.keys(clonedState).includes(action.payload.instanceKey))
                delete clonedState[action.payload.instanceKey];

            return clonedState;
        }
        default:
            return state;
    }
};
