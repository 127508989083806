import { isEmailDisposable } from '../email-verification-helper';
import { UNAUTHORIZED_EMAIL } from '../messages';
import setNewPasswordFormFieldsDescriptor from './set-new-password-form';
import EmailValidator from 'email-validator';
import { isValidValue } from '../index';
import { customErrorMessages } from '../error-messages';

export default [
    {
        property: 'lastName',
        label: 'Nom',
        type: 'string',
        required: true,
    },
    {
        property: 'firstName',
        label: 'Prénom',
        type: 'string',
        required: true,
    },
    {
        property: 'email',
        label: 'Adresse email',
        type: 'string',
        dataType: 'emailAddress',
        required: true,
        validate: async ({ email }, formErrors) => {
            const isDisposableEmail = await isEmailDisposable(email);

            if (!isValidValue(email) || !EmailValidator.validate(email)) {
                formErrors.email = customErrorMessages.missingEmail;
                return false;
            }

            if (isDisposableEmail) formErrors.email = UNAUTHORIZED_EMAIL;
            return !isDisposableEmail;
        },
    },
    ...setNewPasswordFormFieldsDescriptor,
    {
        property: 'termsOfUseAccepted',
        label: 'Acceptation des conditions',
        type: 'boolean',
        required: true,
    },
];
